import { fDateYMD } from '../../../utils/formatTime';

export default {
  GET_ASSETS: ({ page = 1, rowsPerPage = 10 }) => `assets/?page=${page}&page_size=${rowsPerPage}`,
  GET_AUDIT_ASSETS: ({ page = 1, rowsPerPage = 1000, locationId }) =>
    `assets/?location_id=${locationId}&page=${page}&page_size=${rowsPerPage}`,
  GET_ASSET_TRANSFERS: ({ page = 1, rowsPerPage = 10 }) => `assets/transfers/?page=${page}&page_size=${rowsPerPage}`,
  GET_ASSET_TRANSFER_ITEMS: ({ id, page = 1, rowsPerPage = 10 }) =>
    `assets/transfers/${id}/items/?page=${page}&page_size=${rowsPerPage}`,
  APPROVE_ASSET_TRANSFER: (id) => `assets/transfers/${id}/approve/`,
  DECLINE_ASSET_TRANSFER: (id) => `assets/transfers/${id}/decline/`,
  ACCEPT_ASSET_TRANSFER: (id) => `assets/transfers/${id}/accept/`,
  REJECT_ASSET_TRANSFER: (id) => `assets/transfers/${id}/reject/`,
  FILTER_ASSETS: ({
    page = 1,
    rowsPerPage = 10,
    locationId,
    categoryId,
    assetStatus,
    startDate,
    endDate,
    barcodeNumber,
  }) =>
    `assets/?page=${page}&page_size=${rowsPerPage}` +
    `${locationId !== '' ? `&location_id=${locationId}` : ''}` +
    `${categoryId !== '' ? `&category_id=${categoryId}` : ''}` +
    `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}` +
    `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  GET_ASSET: (id) => `assets/${id}/`,
  TRANSFER_ASSET: 'assets/transfers/',
  CREATE_ASSET: 'assets/',
  UPLOAD_ASSET: 'assets/asset-import/',
  UPDATE_ASSET: (id) => `assets/${id}/update/`,
  DELETE_ASSET: (id) => `assets/${id}/delete/`,
};
