import { toast } from 'react-toastify';
import {
  AUTH_API_REQUEST,
  AUTH_API_SUCCESS,
  AUTH_API_FAILED,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from './authActionTypes';
import { storeProfile, storeToken } from '../../../shared/auth/authService';
import authConstants from './authConstants';
import call from '../../../core/services/http';

const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};

export const loginUser = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: AUTH_API_REQUEST,
      isLoading: true,
      isLoggedIn: false,
    });
    const response = await call('post', authConstants.LOGIN, payload);
    if (response.data.status) {
      dispatch({
        type: AUTH_API_SUCCESS,
        isLoading: false,
        isLoggedIn: true,
      });
      storeToken(response.data.access_token);
      storeProfile(response.data.data);
      // toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: AUTH_API_FAILED,
        isLoading: false,
        isLoggedIn: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: AUTH_API_FAILED,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: AUTH_API_FAILED,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const changePassword = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', authConstants.CHANGE_PASSWORD, payload);
    if (response.data.status) {
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
      isLoading: true,
      hasSentOTP: false,
    });
    const response = await call('post', authConstants.RESET_PASSWORD, payload);
    if (response.data.status) {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        isLoading: false,
        hasSentOTP: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        hasSentOTP: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        hasSentOTP: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        hasSentOTP: false,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const verifyResetOTP = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
      isLoading: true,
      isVerified: false,
    });
    const response = await call('post', authConstants.VERIFY_PASSWORD_OTP, payload);
    if (response.data.status) {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        isLoading: false,
        isVerified: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        isVerified: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        isVerified: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        isVerified: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const setNewPassword = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', authConstants.SET_NEW_PASSWORD, payload);
    if (response.data.status) {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        isSuccess: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: RESET_PASSWORD_FAILED,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
