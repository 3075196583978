export const GET_CATEGORIES_REQUEST = 'CATEGORIES/GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'CATEGORIES/GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'CATEGORIES/GET_CATEGORIES_ERROR';
export const GET_AUDIT_CATEGORIES_REQUEST = 'CATEGORIES/GET_AUDIT_CATEGORIES_REQUEST';
export const GET_AUDIT_CATEGORIES_SUCCESS = 'CATEGORIES/GET_AUDIT_CATEGORIES_SUCCESS';
export const GET_AUDIT_CATEGORIES_ERROR = 'CATEGORIES/GET_AUDIT_CATEGORIES_ERROR';
export const CREATE_CATEGORY_REQUEST = 'CATEGORIES/CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CATEGORIES/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CATEGORIES/CREATE_CATEGORY_ERROR';
export const UPDATE_CATEGORY_REQUEST = 'CATEGORIES/UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'CATEGORIES/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'CATEGORIES/UPDATE_CATEGORY_ERROR';
export const DELETE_CATEGORY_REQUEST = 'CATEGORIES/DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'CATEGORIES/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'CATEGORIES/DELETE_CATEGORY_ERROR';
