import { fDateYMD } from '../../../utils/formatTime';

export default {
  GENERATE_ASSET_REPORTS: 'reports/assets-report/',
  ASSET_REPORTS: ({
    locationId,
    // categoryId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/assets/download/?location_id=${locationId}` +
    // `${categoryId !== '' ? `&category_id=${categoryId}` : ''}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  EXPORT_CATEGORY_REPORT: ({
    // locationId,
    categoryId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/assets/download/?category_id=${categoryId}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  MAINTENANCE_CATEGORY_REPORT: ({
    // locationId,
    categoryId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/maintenance/download/?category_id=${categoryId}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  MAINTENANCE_ASSET_REPORT: ({
    // locationId,
    // categoryId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/maintenance/assets/?` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  EXPORT_MANAGER_REPORT: ({
    // locationId,
    staffId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/assets/download/?staff_id=${staffId}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  GENERATE_BARCODE_NUMBER_REPORTS: ({
    locationId,
    // categoryId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/barcode-number-csv/?location_id=${locationId}` +
    // `${categoryId !== '' ? `&category_id=${categoryId}` : ''}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  // MAINTENANCE_BRANCH_REPORT: ({
  //   locationId,
  //   // categoryId,
  //   // status,
  //   startDate,
  //   endDate,
  //   // serviceProviderId,
  //   // staffId
  // }) =>
  // `reports/maintenance/download/?location_id=${locationId}` +
  // `${categoryId !== '' ? `&category_id=${categoryId}` : ''}` +
  // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
  // `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
  // `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,

  MAINTENANCE_BRANCH_REPORT: ({
    // locationId,
    // categoryId,
    // status,
    startDate,
    endDate,
    // serviceProviderId,
    // staffId
  }) =>
    `reports/maintenance/branch/?` +
    // `${categoryId !== '' ? `&category_id=${categoryId}` : ''}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  MAINTENANCE_CATEGORY_EXPENSES_REPORT: ({
    // locationId,
    // categoryId,
    // status,
    startDate,
    endDate,
    // serviceProviderId,
    // staffId
  }) =>
    `reports/maintenance/expenses/category/?` +
    // `${categoryId !== '' ? `&category_id=${categoryId}` : ''}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,  
  MAINTENANCE_SUPPLIER_EXPENSES_REPORT: ({
    // locationId,
    // categoryId,
    // status,
    startDate,
    endDate,
    // serviceProviderId,
    // staffId
  }) =>
    `reports/maintenance/expenses/supplier/?` +
    // `${categoryId !== '' ? `&category_id=${categoryId}` : ''}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  MAINTENANCE_MANAGER_REPORT: ({
    // locationId,
    staffId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/maintenance/download/?staff_id=${staffId}` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  MAINTENANCE_SUPPLIER_REPORT: ({
    // locationId,
    // staffId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/maintenance/supplier/?` +
    // `${staff_id !== '' ? `&staff_id=${staffId}` : ''}` +
    `${startDate !== '' ? `start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  MAINTENANCE_OVERAL_REPORT: ({
    // locationId,
    // staffId,
    // assetStatus,
    startDate,
    endDate,
    // barcodeNumber,
  }) =>
    `reports/maintenance/overal/?` +
    // `${assetStatus !== '' ? `&asset_status=${assetStatus}` : ''}` +
    `${startDate !== '' ? `&start_date=${fDateYMD(startDate)}` : ''}` +
    `${endDate !== '' ? `&end_date=${fDateYMD(endDate)}` : ''}`,
  // `${barcodeNumber !== '' ? `&barcode_number=${barcodeNumber}` : ''}`,
  GENERATE_MASTER_ASSET_REPORTS: 'reports/master-report/',
  GENERATE_BARCODE_REPORTS: 'reports/barcode-report/',
  GENERATE_CUSTODIAN_REPORTS: 'reports/custodian-report/',
  GENERATE_CLASS_REPORTS: 'reports/class-report/',
  GENERATE_GAP_ANALYSIS_REPORTS: 'reports/gap-analysis-report/',
  GENERATE_DISPOSAL_REPORTS: 'reports/disposal-report/',
  GENERATE_MAINTENANCE_REPORTS: 'reports/maintenance-report/',
};
