import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Stack, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

import { serviceProviderAcceptance } from '../store/maintenanceActions';

const ASSET_CONDITION = [
  {
    id: 1,
    label: 'Good',
    value: 'GOOD',
  },
  {
    id: 2,
    label: 'Broken',
    value: 'BROKEN',
  },
];

SPAcceptance.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  maintenanceId: PropTypes.string,
};

export default function SPAcceptance({ open, handleClose, maintenanceId }) {
  const dispatch = useDispatch();

  const SPAcceptanceSchema = Yup.object().shape({
    assetCondition: Yup.string().required('Asset condition is required'),
    remarks: Yup.string(),
    duration: Yup.number().positive().required('Duration is required'),
  });

  const defaultValues = {
    assetCondition: '',
    remarks: '',
    duration: 1,
  };

  const methods = useForm({
    resolver: yupResolver(SPAcceptanceSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  const onSubmit = async (data) => {
    const values = {
      asset_condition: data.assetCondition,
      remarks: data.remarks,
      duration: data.duration,
    };
    const payload = {
      id: maintenanceId,
      payload: values,
    };
    dispatch(serviceProviderAcceptance(payload));
    handleClose();
  };
  return (
    <Dialog
      maxWidth="xs"
      open={open}
      fullWidth
      keepMounted
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Acceptance Form</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <RHFSelect
              fullWidth
              name="assetCondition"
              label="Asset Condition"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {ASSET_CONDITION?.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFTextField type="number" name="duration" label="Duration(days)" />
            <RHFTextField name="remarks" label="Remarks" placeholder="Optional" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
