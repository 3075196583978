import {
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  GET_USER_DASHBOARD_REQUEST,
  GET_USER_DASHBOARD_SUCCESS,
  GET_USER_DASHBOARD_ERROR,
  GET_BRANCH_DASHBOARD_REQUEST,
  GET_BRANCH_DASHBOARD_SUCCESS,
  GET_BRANCH_DASHBOARD_ERROR,
} from './dashboardActionTypes';

const initialState = {
  status: false,
  message: '',
  isLoading: false,
  asset_count: 0,
  total_asset_value: 0,
  assets_net_value: 0,
  total_depreciation: 0,
  userDashboardData: {},
  branchDashboardData: {},
  recent_loans: [],
  cost_per_class: [],
  graph_data: {},
};

export default function dashboardReducer(state = initialState, action) {
  const { type, payload, isLoading, message } = action;

  switch (type) {
    case GET_DASHBOARD_REQUEST:
      return {
        isLoading,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        isLoading,
        asset_count: payload.data.asset_count,
        total_asset_value: payload.data.total_asset_value,
        assets_net_value: payload.data.assets_net_value,
        total_depreciation: payload.data.total_depreciation,
        recent_loans: payload.data.recent_loans,
        cost_per_class: payload.data.cost_per_class,
        graph_data: payload.data.graph_data,
      };
    case GET_DASHBOARD_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_USER_DASHBOARD_REQUEST:
      return {
        isLoading,
      };
    case GET_USER_DASHBOARD_SUCCESS:
      return {
        isLoading,
        userDashboardData: payload.data,
      };
    case GET_USER_DASHBOARD_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_BRANCH_DASHBOARD_REQUEST:
      return {
        isLoading,
      };
    case GET_BRANCH_DASHBOARD_SUCCESS:
      return {
        isLoading,
        branchDashboardData: payload.data,
      };
    case GET_BRANCH_DASHBOARD_ERROR:
      return {
        isLoading,
        message,
      };
    default: {
      return state;
    }
  }
}
