import { useState, useEffect } from 'react';
import MonitorIcon from '@mui/icons-material/Monitor';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableBody,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  CardHeader,
  CardContent,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import Scrollbar from '../../../components/scrollbar';
// sections
import { AssetTransfersTableRow, TableEmptyRows, TableNoData, UserTableHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { fNumber } from '../../../utils/formatNumber';
import { getAssetTransfers } from '../store/assetActions';
import { AssetFilter } from '.';
import { getComparator } from '../../../hooks/useTable';
import { applyFilter, emptyRows } from '../../../sections/@dashboard/user/utils';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AssetTransfers() {
  const dispatch = useDispatch();
  const { isLoading, assetTransfers, pagination } = useSelector((state) => state.assetReducer);
  //

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFilter, setOpenFilter] = useState(false);

  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [filterName, setFilterName] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = assetTransfers?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const dataFiltered = applyFilter({
    inputData: assetTransfers,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const handleCloseFilter = () => {
    setOpenFilter(!openFilter);
  };

  useEffect(() => {
    const payload = {
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getAssetTransfers(payload));
  }, [dispatch, page, rowsPerPage]);

  const notFound = !dataFiltered?.length && !!filterName;

  return (
    <>
      <Page title="Asset Transfers">
        {openFilter && <AssetFilter open={openFilter} handleClose={handleCloseFilter} />}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Paper elevation={3} sx={{ py: 2, textAlign: 'center' }}>
                <Box sx={{ mb: 0.5 }}>
                  <MonitorIcon />
                </Box>
                <Typography variant="h6">{fNumber(pagination?.count)}</Typography>
                <Typography variant="body2">Transfers</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      // onClick={() => setOpenFilter(true)}
                      startIcon={<FilterListOutlinedIcon />}
                    >
                      Filter Transfers
                    </Button>
                  </Stack>
                </Box>
                <CardHeader title={'Asset Transfers'} subheader={''} />
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center' }}>
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#2065d1"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible
                          />
                        </Grid>
                      ) : (
                        <Table size="small">
                          <UserTableHead
                            order={order}
                            orderBy={orderBy}
                            rowCount={assetTransfers?.length}
                            numSelected={selected.length}
                            onRequestSort={handleSort}
                            onSelectAllClick={handleSelectAllClick}
                            headLabel={[
                              { id: 'date', label: 'Date', alignRight: false },
                              { id: 'fromBranch', label: 'From Branch', alignRight: false },
                              { id: 'toBranch', label: 'To Branch', alignRight: false },
                              { id: 'fromCustodian', label: 'From', alignRight: false },
                              { id: 'toCustodian', label: 'To', alignRight: false },
                              { id: 'totalAssets', label: '#Assets', alignRight: false },
                              { id: 'adminApproved', label: 'Admin Approved', alignRight: false },
                              { id: 'custodianApproved', label: 'Custodian Accepted', alignRight: false },
                              { id: 'status', label: 'Status', alignRight: false },
                              { id: '' },
                            ]}
                          />
                          <TableBody>
                            {dataFiltered?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                              <AssetTransfersTableRow
                                key={row.id}
                                record={row}
                                selected={selected.indexOf(row.id) !== -1}
                                handleSelectClick={(event) => handleSelectClick(event, row.id)}
                              />
                            ))}
                            <TableEmptyRows
                              height={77}
                              emptyRows={emptyRows(page, rowsPerPage, assetTransfers?.length)}
                            />
                            {notFound && <TableNoData query={filterName} />}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={pagination?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
