import { useState, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  Menu,
  Box,
  CardHeader,
  CardContent,
  Paper,
} from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page, ConfirmationDialog } from '../../../components';
import { fNumber } from '../../../utils/formatNumber';
import { getBranches } from '../store/branchActions';
import { CustomerEditForm, CustomerForm } from '../components';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'location_code', label: 'Code', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function Branches() {
  //
  const dispatch = useDispatch();
  const { isLoading, branches, pagination } = useSelector((state) => state.branchReducer);
  //
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [showCustomerEditModal, setShowCustomerEditModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBack = () => {
    setShowCustomerForm(!showCustomerForm);
    dispatch(getBranches({}));
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
    dispatch(getBranches({}));
  };

  const showCustomerEditModalHandler = () => {
    setShowCustomerEditModal(!showCustomerEditModal);
    dispatch(getBranches({}));
  };

  const openSelectedRow = (customer, type) => {
    // eslint-disable-next-line no-unused-expressions
    type === 'edit' ? setShowCustomerEditModal(true) : setOpenDialog(true);
    setSelectedRow(customer);
  };

  useEffect(() => {
    const payload = {
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getBranches(payload));
  }, [dispatch, page, rowsPerPage]);

  return (
    <Page title="Branches">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {showCustomerForm ? (
            <Grid item xs={12} md={12} lg={12}>
              <CustomerForm handleBack={handleBack} />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <Paper elevation={3} sx={{ py: 2, textAlign: 'center' }}>
                  <Box sx={{ mb: 0.5 }}>
                    <PlaceIcon />
                  </Box>
                  <Typography variant="h6">{fNumber(pagination?.count)}</Typography>
                  <Typography variant="body2">Branches</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                      <Button
                        size="small"
                        variant="contained"
                        // onClick={}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        New Branch
                      </Button>
                    </Stack>
                  </Box>
                  <CardHeader title={'Branches'} subheader={'Quickmart branches'} />
                  <CardContent>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                        {isLoading ? (
                          <Grid container sx={{ justifyContent: 'center' }}>
                            <ThreeDots
                              height="80"
                              width="80"
                              radius="9"
                              color="#2065d1"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible
                            />
                          </Grid>
                        ) : (
                          <Table size="small">
                            <UserListHead headLabel={TABLE_HEAD} rowCount={branches?.length} />
                            <TableBody>
                              {console.log(branches)}
                              {branches?.length > 0 ? (
                                branches.map((branch) => (
                                  <TableRow hover key={branch?.id} tabIndex={-1} role="checkbox">
                                    <TableCell align="left">{branch?.location_code}</TableCell>
                                    <TableCell component="th" scope="row" padding="checkbox" sx={{ pl: 2 }}>
                                      <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography variant="subtitle2" noWrap>
                                          {branch?.name}
                                        </Typography>
                                      </Stack>
                                    </TableCell>

                                    {/* <TableCell align="left">{branch?.depreciation_rate}</TableCell>
                                      <TableCell align="left">{fDate(branch?.created_at)}</TableCell> */}
                                    <TableCell align="left">
                                      <Label color="success">Active</Label>
                                    </TableCell>

                                    {/* <TableCell align="left">
                                        <Label color={(branch?.status === 'INACTIVE' && 'error') || 'success'}>
                                          {sentenceCase(branch?.status)}
                                        </Label>
                                      </TableCell> */}
                                    <TableCell>
                                      <PopupState
                                        variant="popover"
                                        popupId="branch-popup-menu"
                                        sx={{
                                          p: 1,
                                          width: 140,
                                          '& .MuiMenuItem-root': {
                                            px: 1,
                                            typography: 'body2',
                                            borderRadius: 1,
                                          },
                                        }}
                                      >
                                        {(popupState) => (
                                          <>
                                            <IconButton
                                              size="large"
                                              color="inherit"
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={open ? 'long-menu' : undefined}
                                              aria-expanded={open ? 'true' : undefined}
                                              aria-haspopup="true"
                                              onClick={handleOpenMenu}
                                              {...bindTrigger(popupState)}
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                            <Menu {...bindMenu(popupState)}>
                                              <MenuItem color="success" onClick={() => openSelectedRow(branch, 'edit')}>
                                                <EditIcon sx={{ mr: 2 }} />
                                                Edit
                                              </MenuItem>
                                            </Menu>
                                          </>
                                        )}
                                      </PopupState>
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <>No branches found</>
                              )}
                            </TableBody>
                          </Table>
                        )}
                      </TableContainer>
                    </Scrollbar>

                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      component="div"
                      count={pagination?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    {showCustomerEditModal && (
                      <CustomerEditForm
                        customer={selectedRow}
                        showCustomerEditModal={showCustomerEditModal}
                        showCustomerEditModalHandler={showCustomerEditModalHandler}
                      />
                    )}
                    {openDialog && (
                      <ConfirmationDialog openDialog={openDialog} handleDialog={handleDialog} customer={selectedRow} />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
