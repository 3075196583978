// @mui
import { styled } from '@mui/material/styles';
import { Container, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
// components
import Logo from '../../../components/logo';
// sections
import { Page } from '../../../components';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { resetPassword, setNewPassword, verifyResetOTP } from '../store/authActions';
import Iconify from '../../../components/iconify';
import { userLogout } from '../../../shared/auth/authService';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AccountRecovery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isSuccess, isVerified, hasSentOTP } = useSelector((state) => state.authReducer);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [otp, setOTP] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');

  const accountRecoverySchema = Yup.object().shape({
    mobileNumber: Yup.string().required('Mobile Number is required'),
  });
  const defaultValues = {
    mobileNumber: '',
  };

  const methods = useForm({
    resolver: yupResolver(accountRecoverySchema),
    defaultValues,
  });

  const {
    reset,
    // watch,
    handleSubmit,
    // getValues,
    formState: { isSubmitting },
  } = methods;

  const verifyOTP = async (otp) => {
    if (mobileNumber && otp && otp.length === 6) {
      const payload = {
        mobile_number: mobileNumber,
        otp: parseInt(otp, 10),
      };
      console.log('PAYLOAD:', payload);
      try {
        dispatch(verifyResetOTP(payload));
        reset();
        setOTP('');
      } catch (error) {
        console.error(error);
        setOTP('');
      }
    }
  };
  const handleNewPassword = async (password) => {
    if (mobileNumber && password) {
      const payload = {
        mobile_number: mobileNumber,
        new_password: password,
      };
      try {
        dispatch(setNewPassword(payload));
        reset();
        setPassword('');
        setMobileNumber('');
      } catch (error) {
        console.error(error);
        setPassword('');
        setMobileNumber('');
      }
    }
  };
  const onSubmit = async (data) => {
    setMobileNumber(data.mobileNumber);
    try {
      const payload = {
        mobile_number: data.mobileNumber,
      };
      dispatch(resetPassword(payload));
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      setMobileNumber('');
      setOTP('');
      userLogout();
    }
  }, [dispatch, isSuccess, reset, navigate]);

  const PasswordResetForm = () => (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ my: 3 }}>
        <RHFTextField name="mobileNumber" label="Mobile Number" autoComplete="off" />
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} to="/login" variant="subtitle2" underline="hover">
            Login Instead?
          </Link>
        </Stack>
        <LoadingButton
          loading={isLoading}
          disabled={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          Reset Password
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
  const OtpForm = () => (
    <Stack spacing={3} sx={{ my: 3 }}>
      <TextField
        name="otp"
        autoFocus
        value={otp}
        onChange={(e) => setOTP(e.target.value)}
        fullWidth
        placeholder="Enter OTP"
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} to="/login" variant="subtitle2" underline="hover">
          Login Instead?
        </Link>
      </Stack>
      <LoadingButton
        loading={isLoading}
        disabled={isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => verifyOTP(otp)}
      >
        Verify
      </LoadingButton>
    </Stack>
  );
  const NewPasswordForm = () => (
    <Stack spacing={3} sx={{ my: 3 }}>
      <TextField
        name="newPasword"
        label="New Password"
        autoFocus
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        placeholder="New Password"
        autoComplete="off"
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} to="/login" variant="subtitle2" underline="hover">
          Login Instead?
        </Link>
      </Stack>
      <LoadingButton
        loading={isLoading}
        disabled={isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => handleNewPassword(password)}
      >
        Set New Password
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      <Page title="Account Recovery">
        <StyledRoot>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />
          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h5" alignItems="center" gutterBottom>
                Account Recovery
              </Typography>

              {(hasSentOTP && <OtpForm />) || (isVerified && <NewPasswordForm />) || <PasswordResetForm />}
            </StyledContent>
          </Container>
        </StyledRoot>
      </Page>
    </>
  );
}
