export default {
  GET_DISPOSALS: ({ page = 1, rowsPerPage = 10 }) => `assets/disposals/?page=${page}&page_size=${rowsPerPage}`,
  GET_ASSET_MAINTENANCE_RECORDS: ({ id, page = 1, rowsPerPage = 10 }) =>
    `maintenance/?asset_id=${id}&page=${page}&page_size=${rowsPerPage}`,
  GET_HANDOVER_RECORD: (id) => `handovers/${id}`,
  TRANSFER_ASSET: (id) => `assets/${id}/transfer/`,
  CREATE_DISPOSAL_RECORD: 'assets/disposals/',
  CREATE_REPAIR_RECORD: 'maintenance/repairs/',
  GET_REPAIR_DETAIL: (id) => `maintenance/repairs/?maintenance_id=${id}`,
  APPROVE_DISPOSAL: (id) => `assets/disposals/${id}/approve/`,
  DECLINE_DISPOSAL: (id) => `assets/disposals/${id}/decline/`,
  APPROVE_MAINTENANCE: (id) => `maintenance/${id}/approve-maintenance`,
  DECLINE_MAINTENANCE: (id) => `maintenance/${id}/decline-maintenance`,
  RETURN_REQUEST: (id) => `maintenance/${id}/return-request/`,
  ACCEPT_RETURN_REQUEST: (id) => `maintenance/${id}/return-request/accept/`,
  DECLINE_RETURN_REQUEST: (id) => `maintenance/${id}/return-request/decline/`,
  SP_REPAIR_ACCEPTANCE: (id) => `maintenance/${id}/service-provider/accept/`,
  SP_REPAIR_DECLINE: (id) => `maintenance/${id}/service-provider/decline/`,
  DELETE_ASSET: (id) => `assets/${id}/delete/`,
};
