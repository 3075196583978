import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteCustomer } from '../packages/categories/store/categoryActions';

ConfirmationDialog.propTypes = {
  openDialog: PropTypes.bool,
  handleDialog: PropTypes.func,
  customer: PropTypes.object,
};

export default function ConfirmationDialog({ openDialog, handleDialog, customer }) {
  const dispatch = useDispatch();

  const { isLoading, isSuccess } = useSelector((state) => state.customerReducer);

  const handleDelete = async () => {
    dispatch(deleteCustomer(customer.id));
  };

  useEffect(() => {
    if (isSuccess) {
      handleDialog();
    }
  }, [isSuccess, handleDialog]);

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure you want to delete this customer?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will delete all customer records including loans.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton color="secondary" type="submit" variant="contained" onClick={handleDialog}>
            Cancel
          </LoadingButton>
          <LoadingButton color="error" loading={isLoading} onClick={handleDelete} type="submit" variant="contained">
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
