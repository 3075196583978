export const GET_SUB_CATEGORIES_REQUEST = 'SUB_CATEGORIES/GET_SUB_CATEGORIES_REQUEST';
export const GET_SUB_CATEGORIES_SUCCESS = 'SUB_CATEGORIES/GET_SUB_CATEGORIES_SUCCESS';
export const GET_SUB_CATEGORIES_ERROR = 'SUB_CATEGORIES/GET_SUB_CATEGORIES_ERROR';
export const GET_AUDIT_SUB_CATEGORIES_REQUEST = 'SUB_CATEGORIES/GET_AUDIT_SUB_CATEGORIES_REQUEST';
export const GET_AUDIT_SUB_CATEGORIES_SUCCESS = 'SUB_CATEGORIES/GET_AUDIT_SUB_CATEGORIES_SUCCESS';
export const GET_AUDIT_SUB_CATEGORIES_ERROR = 'SUB_CATEGORIES/GET_AUDIT_SUB_CATEGORIES_ERROR';
export const CREATE_CATEGORY_REQUEST = 'SUB_CATEGORIES/CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'SUB_CATEGORIES/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'SUB_CATEGORIES/CREATE_CATEGORY_ERROR';
export const UPDATE_CATEGORY_REQUEST = 'SUB_CATEGORIES/UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'SUB_CATEGORIES/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'SUB_CATEGORIES/UPDATE_CATEGORY_ERROR';
export const DELETE_CATEGORY_REQUEST = 'SUB_CATEGORIES/DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'SUB_CATEGORIES/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'SUB_CATEGORIES/DELETE_CATEGORY_ERROR';
