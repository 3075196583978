// import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Box, Grid, Card, Typography, Stack, CardHeader, Paper, CardContent, Button } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DangerousIcon from '@mui/icons-material/Dangerous';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Label from '../../../components/label/Label';
import { fDate } from '../../../utils/formatTime';
// import { AccountBillingInvoiceHistory } from '.';

// ----------------------------------------------------------------------

// AccountBilling.propTypes = {
//   cards: PropTypes.array,
//   invoices: PropTypes.array,
// };

export default function AccountBilling() {
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const [valid, setValid] = useState(true);
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <Card sx={{ p: 3 }}>
            <CardHeader title={'License'} subheader={'License status'} />
            <CardContent>
              <Grid container spacing={2}>
                <Grid xs={12} md={4} lg={4}>
                  <Paper variant="outlined" sx={{ p: 2, textAlign: 'center' }}>
                    <Stack spacing={2}>
                      <span>
                        <Stack direction="row" spacing={1} sx={{ float: 'right' }}>
                          {valid ? (
                            <Label color="primary">
                              <StarOutlineIcon fontSize="small" />
                              <Typography variant="body2">Valid</Typography>
                            </Label>
                          ) : (
                            <Label color="error">
                              <DangerousIcon fontSize="small" />
                              <Typography variant="body2">Expired</Typography>
                            </Label>
                          )}
                        </Stack>
                      </span>
                      <Box>
                        <WorkspacePremiumIcon />
                      </Box>
                      <Box>
                        <Typography variant="4" fontWeight="bold">
                          Valid until:
                        </Typography>
                      </Box>
                      <Stack>
                        <Typography variant="body1">{fDate(formattedDate)}</Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              {!valid && (
                <Box
                  sx={{
                    mt: { xs: 2, sm: 0 },
                    position: { sm: 'absolute' },
                    top: { sm: 24 },
                    right: { sm: 24 },
                  }}
                >
                  <Button size="small" variant="contained">
                    Enter New License
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      {/* <Grid item xs={12} md={4}>
        <AccountBillingInvoiceHistory invoices={invoices} />
      </Grid> */}
    </Grid>
  );
}
