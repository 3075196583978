import { toast } from 'react-toastify';

import {
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_ERROR,
  GET_AUDIT_SUB_CATEGORIES_REQUEST,
  GET_AUDIT_SUB_CATEGORIES_SUCCESS,
  GET_AUDIT_SUB_CATEGORIES_ERROR,
  // CREATE_CATEGORY_REQUEST,
  // CREATE_CATEGORY_SUCCESS,
  // CREATE_CATEGORY_ERROR,
  // UPDATE_CATEGORY_REQUEST,
  // UPDATE_CATEGORY_SUCCESS,
  // UPDATE_CATEGORY_ERROR,
  // DELETE_CATEGORY_REQUEST,
  // DELETE_CATEGORY_SUCCESS,
  // DELETE_CATEGORY_ERROR,
} from './subCategoryActionTypes';
import call from '../../../core/services/http';
import subCategoryConstants from './subCategoryConstants';

// const toastrSuccess = (msg) => {
//   toast.success(msg);
// };
const toastrError = (msg) => {
  toast.error(msg);
};

export const getSubCategories = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUB_CATEGORIES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', subCategoryConstants.GET_CATEGORIES(payload));
    if (response.data.status) {
      dispatch({
        type: GET_SUB_CATEGORIES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_SUB_CATEGORIES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (e) {
    toastrError('Sorry, something went wrong');
  }
};

export const getAuditSubCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_AUDIT_SUB_CATEGORIES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', subCategoryConstants.GET_AUDIT_SUB_CATEGORIES);
    if (response.data.status) {
      dispatch({
        type: GET_AUDIT_SUB_CATEGORIES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_AUDIT_SUB_CATEGORIES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (e) {
    toastrError('Sorry, something went wrong');
  }
};

export const getCategorySubCategories = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AUDIT_SUB_CATEGORIES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', subCategoryConstants.GET_CATEGORY_SUB_CATEGORIES(id));
    if (response.data.status) {
      dispatch({
        type: GET_AUDIT_SUB_CATEGORIES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_AUDIT_SUB_CATEGORIES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (e) {
    toastrError('Sorry, something went wrong');
  }
};

// export const createCustomer = (payload) => async (dispatch) => {
//   try {
//     dispatch({
//       type: CREATE_CATEGORY_REQUEST,
//       isLoading: true,
//       isSuccess: false,
//     });
//     const response = await call(categoryConstants.CREATE_CUSTOMER, payload, 'POST');
//     if (response.data.status) {
//       dispatch({
//         type: CREATE_CATEGORY_SUCCESS,
//         isLoading: false,
//         isSuccess: true,
//       });
//       toastrSuccess(response.data.message);
//     } else {
//       toastrError(response.data.message);
//       dispatch({
//         type: CREATE_CATEGORY_ERROR,
//         isLoading: false,
//         isSuccess: false,
//         message: response.data.message,
//       });
//     }
//   } catch (e) {
//     toastrError('Sorry, something went wrong');
//   }
// };

// export const updateCustomer =
//   ({ id, payload }) =>
//   async (dispatch) => {
//     try {
//       dispatch({
//         type: UPDATE_CATEGORY_REQUEST,
//         isLoading: true,
//         isSuccess: false,
//       });
//       const response = await call(categoryConstants.UPDATE_CUSTOMER(id), payload, 'PATCH');
//       if (response.data.status) {
//         dispatch({
//           type: UPDATE_CATEGORY_SUCCESS,
//           isLoading: false,
//           isSuccess: true,
//           message: response.data.message,
//         });
//         toastrSuccess(response.data.message);
//       } else {
//         toastrError(response.data.message);
//         dispatch({
//           type: UPDATE_CATEGORY_ERROR,
//           isLoading: false,
//           isSuccess: false,
//           message: response.data.message,
//         });
//       }
//     } catch (e) {
//       toastrError('Sorry, something went wrong');
//     }
//   };

// export const deleteCustomer = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: DELETE_CATEGORY_REQUEST,
//       isLoading: true,
//       isSuccess: false,
//     });
//     const response = await call(categoryConstants.DELETE_CUSTOMER(id), {}, 'DELETE');
//     if (response.data.status) {
//       dispatch({
//         type: DELETE_CATEGORY_SUCCESS,
//         isLoading: false,
//         isSuccess: true,
//       });
//       toastrSuccess(response.data.message);
//     } else {
//       toastrError(response.data.message);
//       dispatch({
//         type: DELETE_CATEGORY_ERROR,
//         isLoading: false,
//         isSuccess: false,
//         message: response.data.message,
//       });
//     }
//   } catch (e) {
//     toastrError('Sorry, something went wrong');
//   }
// };
