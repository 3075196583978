import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
// @mui
import { Container, CardContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getRepairDetail } from '../store/maintenanceActions';

RepairAttachment.propTypes = {
  recordId: PropTypes.string,
};

export default function RepairAttachment({ recordId }) {
  const { maintenanceRepairs } = useSelector((state) => state.maintenanceReducer);
  const dispatch = useDispatch();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    dispatch(getRepairDetail(recordId));
  }, [dispatch, recordId]);

  useEffect(() => {
    if (maintenanceRepairs) {
      const formattedArray = maintenanceRepairs
        .filter((item) => item.part_photo !== null)
        .map((item) => ({
          src: item.part_photo,
          width: 2,
          height: 1,
        }));

      setPhotos(formattedArray);
    }
  }, [maintenanceRepairs]);

  return (
    <Container maxWidth="xl">
      {/* {open && <NewAttachment open={open} handleClose={handleClose} jobId={jobId} />} */}
      <CardContent>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos?.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </CardContent>
    </Container>
  );
}
