import AccountRecovery from '../view/AccountRecovery';
import Login from '../view/Login';

export const AuthRoutes = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'account-recovery',
    element: <AccountRecovery />,
  },
];
