import {
  GET_HANDOVERS_REQUEST,
  GET_HANDOVERS_SUCCESS,
  GET_HANDOVERS_ERROR,
  GET_HANDOVER_REQUEST,
  GET_HANDOVER_SUCCESS,
  GET_HANDOVER_ERROR,
  CREATE_HANDOVER_REQUEST,
  CREATE_HANDOVER_SUCCESS,
  CREATE_HANDOVER_ERROR,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERROR,
} from './handoverActionTypes';

const initialState = {
  isLoading: false,
  isUploading: false,
  isSuccess: false,
  payload: {},
  message: '',
  handovers: [],
  handover: {},
  maintenanceRecord: {},
  maintenanceRepairs: [],
  maintenanceStatistics: {},
  asset: {},
  depreciationData: {},
  pagination: {},
  itemsCount: 0,
};

export default function handoverReducer(state = initialState, action) {
  const { type, payload, isLoading, isUploading, isSuccess, message } = action;

  switch (type) {
    case GET_HANDOVERS_REQUEST:
      return {
        isLoading,
      };
    case GET_HANDOVERS_SUCCESS:
      return {
        isLoading,
        handovers: payload.data,
        pagination: payload,
      };
    case GET_HANDOVERS_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_HANDOVER_REQUEST:
      return {
        isLoading,
      };
    case GET_HANDOVER_SUCCESS:
      return {
        isLoading,
        handover: payload.data,
      };
    case GET_HANDOVER_ERROR:
      return {
        isLoading,
        message,
      };
    case CREATE_HANDOVER_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_HANDOVER_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_HANDOVER_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };

    case UPDATE_STATUS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_STATUS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_STATUS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
