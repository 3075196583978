import * as yup from 'yup';

export const reminderSchema = yup.object({
  customer_id: yup.number().required('Customer is required'),
  subject: yup.string().required('Subject is required'),
  message: yup.string().required('Message is required'),
});

export const ChangePassWordSchema = yup.object().shape({
  oldPassword: yup.string().required('Old Password is required'),
  newPassword: yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
  confirmNewPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
