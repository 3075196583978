import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import maintenanceConfig from '../../../layouts/dashboard/nav/maintenanceConfig';
import Maintenance from '../view/Maintenance';
import { MaintenanceDetail } from '../components';

// const storedUser = JSON.parse(localStorage.getItem('profile'));

export const maintenanceRoutes = [
  {
    path: '/maintenance',
    element: <DashboardLayout navConfig={maintenanceConfig} />,
    children: [
      { element: <Navigate to="/maintenance/app" />, index: true },
      {
        path: 'app',
        element: <Maintenance />,
      },
      { path: 'app/:id', element: <MaintenanceDetail /> },
    ],
  },
];
