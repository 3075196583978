export const GET_BRANCHES_REQUEST = 'BRANCHES/GET_BRANCHES_REQUEST';
export const GET_BRANCHES_SUCCESS = 'BRANCHES/GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_ERROR = 'BRANCHES/GET_BRANCHES_ERROR';
export const GET_AUDIT_BRANCHES_REQUEST = 'BRANCHES/GET_AUDIT_BRANCHES_REQUEST';
export const GET_AUDIT_BRANCHES_SUCCESS = 'BRANCHES/GET_AUDIT_BRANCHES_SUCCESS';
export const GET_AUDIT_BRANCHES_ERROR = 'BRANCHES/GET_AUDIT_BRANCHES_ERROR';
export const CREATE_CATEGORYBRANCH_REQUEST = 'BRANCHES/CREATE_BRANCH_REQUEST';
export const CREATE_BRANCH_SUCCESS = 'BRANCHES/CREATE_BRANCH_SUCCESS';
export const CREATE_BRANCH_ERROR = 'BRANCHES/CREATE_BRANCH_ERROR';
export const UPDATE_BRANCH_REQUEST = 'BRANCHES/UPDATE_BRANCH_REQUEST';
export const UPDATE_BRANCH_SUCCESS = 'BRANCHES/UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_ERROR = 'BRANCHES/UPDATE_BRANCH_ERROR';
export const DELETE_BRANCH_REQUEST = 'BRANCHES/DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_SUCCESS = 'BRANCHES/DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_ERROR = 'BRANCHES/DELETE_BRANCH_ERROR';
