import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';

import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import Label from '../../../components/label';
import { fCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function UserTableRow({ selected, asset, handleSelectClick }) {
  const navigate = useNavigate();
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleSelectClick} />
        </TableCell>

        <TableCell align="left">{asset?.name}</TableCell>
        <TableCell align="left">{asset?.asset_class}</TableCell>
        <TableCell align="left">{asset?.barcode_number}</TableCell>
        <TableCell align="left">{asset?.serial_no}</TableCell>
        <TableCell align="left">{fCurrency(asset?.cost)}</TableCell>
        <TableCell align="left">{asset?.branch?.name}</TableCell>
        <TableCell align="left">{asset?.assigned_to?.user?.display_name}</TableCell>

        <TableCell align="left">
          <Label color={(asset?.status === 'PENDING' && 'error') || 'success'}>{sentenceCase(asset?.status)}</Label>
        </TableCell>
        <TableCell>
          <Button size="small" variant="contained" onClick={() => navigate(`/dashboard/assets/${asset?.id}`)}>
            View
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

UserTableRow.propTypes = {
  asset: PropTypes.object,
  handleSelectClick: PropTypes.func,
  selected: PropTypes.any,
};
