export default {
  GET_USERS: ({ page = 1, rowsPerPage = 10 }) => `users/?page=${page}&page_size=${rowsPerPage}`,
  GET_STAFF: ({ page = 1, rowsPerPage = 10 }) => `users/staff/?page=${page}&page_size=${rowsPerPage}`,
  GET_SERVICE_PROVIDERS: ({ page = 1, rowsPerPage = 100 }) =>
    `users/?page=${page}&page_size=${rowsPerPage}&role=SERVICE_PROVIDER`,
  GET_BRANCH_MANAGERS: ({ page = 1, rowsPerPage = 100 }) => `users/?page=${page}&page_size=${rowsPerPage}&role=MANAGER`,
  CREATE_USER: 'users/staff/',
  UPDATE_STAFF: (id) => `users/staff/${id}/`,
  DELETE_STAFF: (id) => `users/staff/${id}/`,
  FILTER_USERS: ({ page = 1, rowsPerPage = 10, locationId, role, name }) =>
    `users/?page=${page}&page_size=${rowsPerPage}` +
    `${locationId !== '' ? `&location_id=${locationId}` : ''}` +
    `${role !== '' ? `&role=${role}` : ''}` +
    `${name !== '' ? `&name=${name}` : ''}`,
};
