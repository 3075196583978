import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { maintenanceReturnRequest } from '../store/maintenanceActions';

ConfirmCompleteDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  maintenanceId: PropTypes.string,
};

export default function ConfirmCompleteDialog({ open, handleClose, maintenanceId }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.maintenanceReducer);

  const handleReturn = async () => {
    dispatch(maintenanceReturnRequest(maintenanceId));
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure maintenance is complete and ready for return?'}
        </DialogTitle>
        <DialogActions>
          <LoadingButton color="secondary" type="submit" variant="contained" onClick={handleClose}>
            Cancel
          </LoadingButton>
          <LoadingButton color="error" loading={isLoading} onClick={handleReturn} type="submit" variant="contained">
            Proceed
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
