import { useState } from 'react';
// @mui
import {
  Card,
  Container,
  Typography,
  Grid,
  Box,
  // CardHeader,
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Page } from '../../../components';
import {
  BarcodeReportForm,
  BranchReportForm,
  CategoryReportForm,
  MaintenanceAssetReport,
  MaintenanceBranchReport,
  MaintenanceCategoryExpensesReport,
  MaintenanceSupplierExpensesReport,
  // ManitenanceManagerReport,
  ManitenanceOverviewReport,
  ManitenanceSupplierReport,
  StaffReportForm,
} from '../components';
import { generateAssetMasterReport } from '../components/MasterAssetReport';
import { generateGapAnalysisReport } from '../components/GapAnalysisReport';

// ----------------------------------------------------------------------

export default function Reports() {
  const [openAsset, setOpenAsset] = useState(false);
  const [openAudit, setOpenAudit] = useState(false);
  const [openTagging, setOpenTagging] = useState(false);
  const [openMaintenance, setOpenMaintenance] = useState(false);
  const [openGapAnalysis, setOpenGapAnalysis] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');

  const handleMenuClick = (option) => {
    setSelectedMenu(option);
  };

  let formComponent;
  if (selectedMenu === 'branch') {
    formComponent = <BranchReportForm />;
  } else if (selectedMenu === 'category') {
    formComponent = <CategoryReportForm />;
  } else if (selectedMenu === 'manager') {
    formComponent = <StaffReportForm />;
  } else if (selectedMenu === 'barcodeNumbers') {
    formComponent = <BarcodeReportForm />;
  } else if (selectedMenu === 'repairOverview') {
    formComponent = <ManitenanceOverviewReport />;
  } else if (selectedMenu === 'repairPerSupplier') {
    formComponent = <ManitenanceSupplierReport />;
  } else if (selectedMenu === 'repairPerBranch') {
    formComponent = <MaintenanceBranchReport />;
  } else if (selectedMenu === 'repairAssetsReport') {
    formComponent = <MaintenanceAssetReport />;
  } else if (selectedMenu === 'repairCategoryExpenses') {
    formComponent = <MaintenanceCategoryExpensesReport />;
  } else if (selectedMenu === 'repairSupplierExpenses') {
    formComponent = <MaintenanceSupplierExpensesReport />;
  }

  const handleAssetClick = () => {
    setOpenAsset(!openAsset);
  };
  const handleAuditClick = () => {
    setOpenAudit(!openAudit);
  };  
  
  const handleGapAnalysisClick = () => {
    setOpenGapAnalysis(!openGapAnalysis);
  };
  const handleTaggingClick = () => {
    setOpenTagging(!openTagging);
  };
  const handleMaintenanceClick = () => {
    setOpenMaintenance(!openMaintenance);
  };

  return (
    <>
      <Page title="Assets">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4}>
                    <Card>
                      <Box sx={{ p: 2, textAlign: 'center' }}>
                        <List>
                          <ListItemButton onClick={handleAssetClick}>
                            <ListItemText>
                              <Typography variant="subtitle1">Asset Reports</Typography>
                            </ListItemText>
                            {openAsset ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Divider />
                          <Collapse in={openAsset} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('branch')}>
                                <ListItemText primary="Asset Per Branch" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('category')}>
                                <ListItemText primary="Asset Per Category" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('manager')}>
                                <ListItemText primary="Asset Per Manager" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => generateAssetMasterReport()}>
                                <ListItemText primary="Asset Master Report" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Divider />
                          <Collapse in={openAudit} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('auditBranch')}>
                                <ListItemText primary="Audit Per Branch" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('unauditedAssets')}>
                                <ListItemText primary="Assets Not Audited" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Divider />
                          <ListItemButton onClick={handleTaggingClick}>
                            <ListItemText>
                              <Typography variant="subtitle1">Asset Tagging Reports</Typography>
                            </ListItemText>
                            {openTagging ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={openTagging} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('barcodeNumbers')}>
                                <ListItemText primary="Barcode Numbers" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Divider />
                          <ListItemButton onClick={handleMaintenanceClick}>
                            <ListItemText>
                              <Typography variant="subtitle1">Maintenance Reports</Typography>
                            </ListItemText>
                            {openMaintenance ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={openMaintenance} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('repairOverview')}>
                                <ListItemText primary="Overview Report" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('repairPerSupplier')}>
                                <ListItemText primary="Repairs Per Supplier" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('repairPerBranch')}>
                                <ListItemText primary="Repairs Per Branch" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('repairAssetsReport')}>
                                <ListItemText primary="Assets Report" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('repairCategoryExpenses')}>
                                <ListItemText primary="Repair Expenses Per  Asset Class" />
                              </ListItemButton>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => handleMenuClick('repairSupplierExpenses')}>
                                <ListItemText primary="Repair Expenses Per Supplier" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                          <Divider />
                          <ListItemButton onClick={handleGapAnalysisClick}>
                            <ListItemText>
                              <Typography variant="subtitle1">Gap Analysis Report</Typography>
                            </ListItemText>
                            {openGapAnalysis ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={openGapAnalysis} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 4 }} onClick={() => generateGapAnalysisReport()}>
                                <ListItemText primary="Assets Gap Analysis Report" />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </List>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={8} lg={8}>
                    <Card>
                      <CardContent>{formComponent}</CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
