import { Navigate } from 'react-router-dom';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import Dashboard from '../view/Dashboard';
// import Customers from '../../customers/view/Customers';
import Categories from '../../categories/view/Categories';
import Staff from '../../users/view/Users';
import Branches from '../../branches/view/Branches';
import UserAccount from '../../auth/view/UserAccount';
import SimpleLayout from '../../../layouts/simple/SimpleLayout';
import Page404 from '../../../pages/Page404';
import AuthGuard from '../../../shared/auth/authGuards';
import Audit from '../../audit/view/Audit';
import ReconcileAudit from '../../audit/view/ReconcileAudit';
import Reports from '../../reports/view/Reports';
import FixedAssets from '../../fixedassets/view/FixedAssets';
import { AssetDetail, AssetTransfers, CheckoutAssets, NewAssetForm } from '../../fixedassets/components';
import { NewUserForm } from '../../users/components';
import OnsiteMaintenance from '../../maintenance/view/OnsiteMaintenance';
import OffsiteMaintenance from '../../maintenance/view/Maintenance';
import { MaintenanceDetail } from '../../maintenance/components';
import UserDashboard from '../view/UserDashboard';
import BranchDashboard from '../view/BranchDashboard';
import ServiceProviderMaintenance from '../../maintenance/view/ServiceProviderMaintenance';
import Handovers from '../../handovers/view/Handovers';
import Disposals from '../../disposals/view/Disposals';
import Invoices from '../../payments/view/Invoices';

const storedUser = JSON.parse(localStorage.getItem('profile'));
const allowedRoles = ['MANAGER', 'BRANCH_MANAGER'];

const isManager = storedUser?.role && allowedRoles.includes(storedUser.role);

export const dashboardRoutes = (isManager && [
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: 'app', element: <BranchDashboard /> },
      { path: 'assets', element: <FixedAssets /> },
      { path: 'transfers', element: <AssetTransfers /> },
      { path: 'transfers/:id', element: <CheckoutAssets /> },
      { path: 'assets/:id', element: <AssetDetail /> },
      { path: 'categories', element: <Categories /> },
      { path: 'maintenance', element: <ServiceProviderMaintenance /> },
      { path: 'maintenance/:id', element: <MaintenanceDetail /> },
      { path: 'handovers', element: <Handovers /> },
      { path: 'disposals', element: <Disposals /> },
      { path: 'settings', element: <UserAccount /> },
    ],
  },
  {
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to="/dashboard/app" />, index: true },
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" replace />,
  },
]) ||
  (storedUser?.role === 'SERVICE_PROVIDER' && [
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <UserDashboard /> },
        { path: 'maintenance', element: <ServiceProviderMaintenance /> },
        { path: 'maintenance/:id', element: <MaintenanceDetail /> },
        { path: 'settings', element: <UserAccount /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/dashboard" replace />,
    },
  ]) || [
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <Dashboard /> },
        { path: 'assets', element: <FixedAssets /> },
        { path: 'transfers', element: <AssetTransfers /> },
        { path: 'transfers/:id', element: <CheckoutAssets /> },
        { path: 'assets/new', element: <NewAssetForm /> },
        { path: 'assets/:id', element: <AssetDetail /> },
        { path: 'branches', element: <Branches /> },
        { path: 'categories', element: <Categories /> },
        { path: 'users', element: <Staff /> },
        { path: 'users/new', element: <NewUserForm /> },
        { path: 'audit', element: <Audit /> },
        { path: 'maintenance-onsite', element: <OnsiteMaintenance /> },
        { path: 'maintenance-offsite', element: <OffsiteMaintenance /> },
        { path: 'invoices', element: <Invoices /> },
        { path: 'maintenance-info/:id', element: <MaintenanceDetail /> },
        { path: 'reconcileaudit', element: <ReconcileAudit /> },
        { path: 'handovers', element: <Handovers /> },
        { path: 'disposals', element: <Disposals /> },
        { path: 'reports', element: <Reports /> },
        { path: 'settings', element: <UserAccount /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/dashboard" replace />,
    },
  ];
