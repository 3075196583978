import { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

import { createRepairRecord } from '../store/maintenanceActions';

const ITEM_TYPE = [
  {
    id: 1,
    label: 'Part',
    value: 'PART',
  },
  {
    id: 2,
    label: 'Service',
    value: 'SERVICE',
  },
];

NewRepair.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  maintenanceId: PropTypes.string,
};

export default function NewRepair({ open, handleClose, maintenanceId }) {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [selectedType, setSelectedType] = useState('PART');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setValue('partPhoto', file);
    setImage(file);
  };

  const handleSelectedType = (event) => {
    const type = event.target.value;
    setSelectedType(type);
    setValue('itemType', type);
  };

  const SPAcceptanceSchema = Yup.object().shape({
    maintenanceId: Yup.string().required('Maintenance id is required'),
    itemType: Yup.string().required('Type id is required'),
    partName: Yup.string().required('Used part name is required'),
    serialNumber: Yup.string(),
    warranty: Yup.string(),
    description: Yup.string(),
    quantity: Yup.string(),
    cost: Yup.number().positive().required(' Estimated cost is required'),
    // expenses: Yup.number().positive(),
    includesExpenses: Yup.boolean(),
  });

  const defaultValues = {
    maintenanceId,
    itemType: selectedType || '',
    quantity: '',
    partName: '',
    serialNumber: '',
    warranty: '',
    cost: '',
    expenses: '',
    includesExpenses: false,
    description: '',
    partPhoto: '',
  };

  const methods = useForm({
    resolver: yupResolver(SPAcceptanceSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  // console.log('DATA:', values);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('maintenance_id', maintenanceId);
    formData.append('item_type', data.itemType);
    formData.append('quantity', data.quantity);
    formData.append('part_name', data.partName);
    formData.append('part_serial_number', data.serialNumber);
    formData.append('warranty', data.warranty);
    formData.append('estimated_cost', data.cost);
    formData.append('includes_expenses', data.includesExpenses);
    formData.append('expenses', data.expenses);
    formData.append('description', data.description);
    formData.append('part_photo', data.partPhoto);

    dispatch(createRepairRecord(formData));
    handleClose();
  };
  const ChannelCheckbox = ({ name, label }) => {
    const { control } = useFormContext();

    return (
      <Controller
        render={({ field }) => (
          <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={label} />
        )}
        control={control}
        name={name}
        defaultValue={false}
      />
    );
  };
  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Repair Item</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <RHFSelect
              fullWidth
              name="itemType"
              label="Item Type"
              value={selectedType}
              onChange={(e) => handleSelectedType(e)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {ITEM_TYPE?.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFTextField name="partName" label="Part/Service Name" />
            {selectedType === 'PART' && (
              <>
                <RHFTextField name="serialNumber" label="Serial Number" />
                <RHFTextField name="warranty" label="Warranty(Year)" placeholder="Optional" />
                <RHFTextField type="number" name="quantity" label="Quantity" />
              </>
            )}
            <RHFTextField type="number" name="cost" label="Estimated Cost" />
            {/* <RHFTextField type="number" name="expenses" label="Expense Cost" /> */}
            <RHFTextField
              type="file"
              name="partPhoto"
              value={null}
              onChange={handleFileUpload}
              label={image ? image.name : 'Attachment'}
            />
            <RHFTextField name="description" label="Description" placeholder="Optional" />
            <Stack direction="row" spacing={1}>
              <ChannelCheckbox name="includesExpenses" label="Estimated Cost  includes Expenses? " />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
