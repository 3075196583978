import { SEND_REMINDERS_REQUEST, SEND_REMINDERS_SUCCESS, SEND_REMINDERS_FAILURE } from './reminderActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  message: '',
};

export default function reminderReducer(state = initialState, action) {
  const { type, isLoading, isSuccess, message } = action;

  switch (type) {
    case SEND_REMINDERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case SEND_REMINDERS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case SEND_REMINDERS_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
