import axios from 'axios';
import { BASE_URL } from '../../../environment/environment';
import { getToken } from '../../../../shared/auth/authService';

/**
 * Axios basic configuration
 */
const config = {
  baseURL: BASE_URL,
};

/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need
 * to consume APIs from more than single server,
 */
const client = axios.create(config);

/**
 * Auth interceptors
 * @description Add auth tokens to every outgoing requests.
 * @param {*} config
 */
const authInterceptor = (config) => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  // config.headers.common.Accept = 'applications/json';
  //  disable for Django
  // config.headers['Access-Control-Allow-Origin'] = '*'
  return config;
};

/**
 * Logger interceptors
 * @description Log app requests.
 * @param {*} config
 */
const loggerInterceptor = (config) =>
  /** Add logging here */
  config;

/** Adding the request interceptors */
client.interceptors.request.use(authInterceptor);
client.interceptors.request.use(loggerInterceptor);

/** Adding the response interceptors */
client.interceptors.response.use(
  (response) => Promise.resolve(response),

  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { status, data: responseData } = error.response;
      console.log('Axios Error Status:', status);
      console.log('Axios Error Data:', responseData);

      switch (status) {
        case 400:
          console.error('Bad Request:', responseData);
          break;
        case 401:
          console.error('Unauthorized:', responseData);
          window.location.href = '/login';
          break;
        case 403:
          console.error('Forbidden:', responseData);
          break;
        case 404:
          console.error('Not Found:', responseData);
          break;
        // Add more cases for other status codes as needed
        default:
          console.error('An error occurred:', responseData);
          Promise.resolve(error.response);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('Axios Error:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Axios Error:', error.message);
    }

    throw error;
  }
);

export default client;
