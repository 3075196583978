import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { Stack, TextField, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

import { createMaintenanceRecord } from '../store/maintenanceActions';
import { fDateYMD } from '../../../utils/formatTime';
import { getServiceProviders } from '../../users/store/userActions';

const REPAIR_TYPES = [
  {
    id: 1,
    label: 'Preventive',
    value: 'PREVENTIVE',
  },
  {
    id: 2,
    label: 'Emergency',
    value: 'EMERGENCY',
  },
];
const REPAIR_VENUE = [
  {
    id: 1,
    label: 'Onsite',
    value: 'ONSITE',
  },
  {
    id: 2,
    label: 'Offsite',
    value: 'OFFSITE',
  },
];

NewMaintenance.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  assetId: PropTypes.string,
};

export default function NewMaintenance({ open, handleClose, assetId }) {
  const dispatch = useDispatch();
  const { serviceProviders } = useSelector((state) => state.userReducer);
  const { isSuccess } = useSelector((state) => state.maintenanceReducer);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedRepairVenue, setSelectedRepairVenue] = useState(null);
  const [providerId, setProviderId] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const storedUser = JSON.parse(localStorage.getItem('profile'));
  const navigate = useNavigate();

  const NewMaintenanceRecordSchema = Yup.object().shape({
    repairtType: Yup.string().required('Repair Type is required'),
    serviceProviderId: Yup.number(),
    repairDate: Yup.string().required('Repair date is required'),
    description: Yup.string(),
    repairVenue: Yup.string(),
    image: Yup.string(),
    video: Yup.string(),
  });

  const defaultValues = {
    assetId: assetId || '',
    repairtType: '',
    serviceProviderId: providerId || '',
    repairDate: fDateYMD(new Date()) || '',
    description: '',
    repairVenue: selectedRepairVenue || '',
    image: '',
    video: '',
  };

  const handleDateChange = (newValue) => {
    setValue('repairDate', fDateYMD(newValue));
    setSelectedDate(newValue);
  };
  const handleRepairVenueChange = (event) => {
    const venue = event.target.value;
    console.log('VENUE:', venue);
    setValue('repairVenue', venue);
    setSelectedRepairVenue(venue);
  };
  const handleProviderChange = (event) => {
    const providerId = event.target.value;
    setProviderId(providerId);
    if (providerId !== '') {
      if (providerId === 'SELF') {
        setValue('serviceProviderId', storedUser?.staff_id);
      } else {
        setValue('serviceProviderId', providerId);
      }
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setValue('image', file);
    setImage(file);
  };
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    setValue('video', file);
    setVideo(file);
  };

  const methods = useForm({
    resolver: yupResolver(NewMaintenanceRecordSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  console.log('VALUES:', values);

  useEffect(() => {
    dispatch(getServiceProviders({}));
  }, [dispatch]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('asset_id', assetId);
    formData.append('service_provider_id', data.serviceProviderId);
    formData.append('repair_type', data.repairtType);
    formData.append('repair_date', fDateYMD(selectedDate));
    formData.append('repair_venue', data.repairVenue);
    formData.append('description', selectedRepairVenue);
    formData.append('description', data.description);
    formData.append('image', image);
    formData.append('video', video);
    try {
      dispatch(createMaintenanceRecord(formData));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      reset();
      handleClose();
      navigate('/dashboard/maintenance');
    }
  }, [reset, isSuccess, navigate, handleClose]);
  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>New Maintenance Record</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <RHFSelect
              fullWidth
              name="repairtType"
              label="Repair Type"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {REPAIR_TYPES?.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect
              fullWidth
              name="serviceProviderId"
              label="Select Service Provider"
              value={providerId}
              onChange={(e) => handleProviderChange(e)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {/* <MenuItem value="" disabled>
                Select Provider
              </MenuItem> */}
              <MenuItem value="SELF">Self</MenuItem>
              {serviceProviders?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.user.display_name}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect
              fullWidth
              name="repairVenue"
              label="Repair Venue"
              value={selectedRepairVenue}
              onChange={(e) => handleRepairVenueChange(e)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {(providerId !== 'SELF' &&
                REPAIR_VENUE?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))) || <MenuItem value={'ONSITE'}>Onsite</MenuItem>}
            </RHFSelect>

            <DatePicker
              label="Repair Date"
              value={selectedDate}
              minDate={new Date()}
              format="yyyy-MM-dd"
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <RHFTextField name="description" label="Description" placeholder="Optional" />
            <RHFTextField
              type="file"
              name="image"
              value={null}
              onChange={handleFileUpload}
              label={image ? image.name : 'Image'}
            />
            <RHFTextField
              type="file"
              name="video"
              value={null}
              onChange={handleVideoUpload}
              label={video ? video.name : 'Video'}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
