import {
  GET_DISPOSALS_REQUEST,
  GET_DISPOSALS_SUCCESS,
  GET_DISPOSALS_ERROR,
  GET_HANDOVER_REQUEST,
  GET_HANDOVER_SUCCESS,
  GET_HANDOVER_ERROR,
  CREATE_DISPOSAL_REQUEST,
  CREATE_DISPOSAL_SUCCESS,
  CREATE_DISPOSAL_ERROR,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERROR,
  UPLOAD_ASSET_REQUEST,
  UPLOAD_ASSET_SUCCESS,
  UPLOAD_ASSET_ERROR,
  UPDATE_MAINTENANCE_RECORD_REQUEST,
  UPDATE_MAINTENANCE_RECORD_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_ERROR,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
} from './disposalActionTypes';

const initialState = {
  isLoading: false,
  isUploading: false,
  isSuccess: false,
  payload: {},
  message: '',
  disposals: [],
  handover: {},
  maintenanceRecord: {},
  maintenanceRepairs: [],
  maintenanceStatistics: {},
  asset: {},
  depreciationData: {},
  pagination: {},
  itemsCount: 0,
};

export default function handoverReducer(state = initialState, action) {
  const { type, payload, isLoading, isUploading, isSuccess, message } = action;

  switch (type) {
    case GET_DISPOSALS_REQUEST:
      return {
        isLoading,
      };
    case GET_DISPOSALS_SUCCESS:
      return {
        isLoading,
        disposals: payload.data,
        pagination: payload,
      };
    case GET_DISPOSALS_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_HANDOVER_REQUEST:
      return {
        isLoading,
      };
    case GET_HANDOVER_SUCCESS:
      return {
        isLoading,
        handover: payload.data,
      };
    case GET_HANDOVER_ERROR:
      return {
        isLoading,
        message,
      };
    case CREATE_DISPOSAL_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_DISPOSAL_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_DISPOSAL_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPLOAD_ASSET_REQUEST:
      return {
        isLoading,
        isUploading,
        isSuccess,
      };
    case UPLOAD_ASSET_SUCCESS:
      return {
        isLoading,
        isUploading,
        isSuccess,
        message,
      };
    case UPLOAD_ASSET_ERROR:
      return {
        isLoading,
        isUploading,
        message,
      };
    case UPDATE_MAINTENANCE_RECORD_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_MAINTENANCE_RECORD_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_MAINTENANCE_RECORD_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_STATUS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_STATUS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_STATUS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_ASSET_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case DELETE_ASSET_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_ASSET_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
