export const GET_DISPOSALS_REQUEST = 'DISPOSALS/GET_DISPOSALS_REQUEST';
export const GET_DISPOSALS_SUCCESS = 'DISPOSALS/GET_DISPOSALS_SUCCESS';
export const GET_DISPOSALS_ERROR = 'DISPOSALS/GET_DISPOSALS_ERROR';
export const GET_HANDOVER_REQUEST = 'HANDOVERS/GET_HANDOVER_REQUEST';
export const GET_HANDOVER_SUCCESS = 'HANDOVERS/GET_HANDOVER_SUCCESS';
export const GET_HANDOVER_ERROR = 'HANDOVERS/GET_HANDOVER_ERROR';
export const CREATE_DISPOSAL_REQUEST = 'DISPOSALS/CREATE_DISPOSAL_REQUEST';
export const CREATE_DISPOSAL_SUCCESS = 'DISPOSALS/CREATE_DISPOSAL_SUCCESS';
export const CREATE_DISPOSAL_ERROR = 'DISPOSALS/CREATE_DISPOSAL_ERROR';
export const UPLOAD_ASSET_REQUEST = 'MAINTENANCE/UPLOAD_ASSET_REQUEST';
export const UPLOAD_ASSET_SUCCESS = 'MAINTENANCE/UPLOAD_ASSET_SUCCESS';
export const UPLOAD_ASSET_ERROR = 'MAINTENANCE/UPLOAD_ASSET_ERROR';
export const UPDATE_STATUS_REQUEST = 'DISPOSALS/UPDATE_STATUS_REQUEST';
export const UPDATE_STATUS_SUCCESS = 'DISPOSALS/UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_ERROR = 'DISPOSALS/UPDATE_STATUS_ERROR';
export const UPDATE_MAINTENANCE_RECORD_REQUEST = 'MAINTENANCE/UPDATE_MAINTENANCE_RECORD_REQUEST';
export const UPDATE_MAINTENANCE_RECORD_SUCCESS = 'MAINTENANCE/UPDATE_MAINTENANCE_RECORD_SUCCESS';
export const UPDATE_MAINTENANCE_RECORD_ERROR = 'MAINTENANCE/UPDATE_MAINTENANCE_RECORD_ERROR';
export const DELETE_ASSET_REQUEST = 'MAINTENANCE/DELETE_ASSET_REQUEST';
export const DELETE_ASSET_SUCCESS = 'MAINTENANCE/DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_ERROR = 'MAINTENANCE/DELETE_ASSET_ERROR';
