import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
  CardHeader,
  CardContent,
  Paper,
  TextField,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import { ThreeDots } from 'react-loader-spinner';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { fNumber } from '../../../utils/formatNumber';
import { getMaintenanceRecords, searchMaintenanceRecords } from '../store/maintenanceActions';
import { fDateTime, fDateYMD } from '../../../utils/formatTime';
import { getProfile } from '../../../shared/auth/authService';
import { AppWidgetSummary } from '../../../sections/@dashboard/app';
import { Accept, Decline } from '../components';
import call from '../../../core/services/http';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Barcode Number', alignRight: false },
  { id: 'name', label: 'Asset Name', alignRight: false },
  { id: 'location', label: 'Branch', alignRight: false },
  { id: 'manager', label: 'Manager', alignRight: false },
  { id: 'service_provider', label: 'Service Provider', alignRight: false },
  { id: 'repair_type', label: 'Repair Type', alignRight: false },
  { id: 'repairVenue', label: 'Repair Venue', alignRight: false },
  { id: 'date_created', label: 'Created', alignRight: false },
  { id: 'condition', label: 'Repair Status', alignRight: false },
  { id: 'condition', label: 'Return Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------
const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};
export default function Maintenance() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, maintenanceRecords, maintenanceStatistics, pagination } = useSelector(
    (state) => state.maintenanceReducer
  );
  //

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const [reportUrl, setReportUrl] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getMaintenanceRecords(payload));
    }, 2000); // 2000 mil
  };

  const handleCloseAccept = () => {
    setOpenAccept(!openAccept);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getMaintenanceRecords(payload));
    }, 2000); // 2000 mil
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = async (searchTerm) => {
    if (searchTerm || (startDate && endDate)) {
      const payload = {
        page: page + 1,
        rowsPerPage,
        searchTerm,
        startDate: fDateYMD(startDate),
        endDate: fDateYMD(endDate),
      };
      setReportUrl(
        `reports/maintenance/download/?page=${page}&page_size=${rowsPerPage}${
          (searchTerm !== '' && `&search_term=${payload?.searchTerm}`) || ''
        }${(payload?.startDate !== '' && `&start_date=${payload?.startDate}`) || ''}${
          (payload?.endDate !== '' && `&end_date=${payload?.endDate}`) || ''
        }`
      );
      dispatch(searchMaintenanceRecords(payload));
    }
  };

  const handleSearchTermChange = async (e) => {
    const newSearchTerm = e.target.value;
    if (searchTerm && !newSearchTerm) {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };

      setReportUrl('');
      dispatch(getMaintenanceRecords(payload));
    }

    setSearchTerm(newSearchTerm);
  };

  const exportReport = async () => {
    try {
      setIsExporting(true);
      const response = await call(
        'get',
        reportUrl || `reports/maintenance/download/?page=${page}&page_size=${rowsPerPage}`
      );
      if (response.status) {
        setIsExporting(false);

        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const name = `${new Date()}maintenances_report.csv`;
        link.download = name;
        link.click();

        // Clean up the temporary objects
        window.URL.revokeObjectURL(link);
        link.remove();
      } else {
        toastrError(response.data.message);
        setIsExporting(false);
      }
    } catch (error) {
      console.log('====================================');
      console.log(error.message);
      console.log('====================================');
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred while generating report';
        setIsExporting(false);
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred while generating report';
        setIsExporting(false);
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        setIsExporting(false);
        toastrError('An error occurred while generating report');
      }
    }

    setIsExporting(false);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);
  };

  useEffect(() => {
    const payload = {
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getMaintenanceRecords(payload));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    const profile = getProfile();
    if (profile) {
      setUser(profile);
    }
  }, []);

  return (
    <>
      <Page title="Maintenance">
        {openAccept && <Accept open={openAccept} handleClose={handleCloseAccept} maintenanceId={selectedRow?.id} />}
        {open && <Decline open={open} handleClose={handleClose} maintenanceId={selectedRow?.id} />}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total"
                  total={fNumber(maintenanceStatistics?.total_records)}
                  color="success"
                  icon={'material-symbols:person-book'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
                <AppWidgetSummary
                  title="In Progress"
                  total={fNumber(maintenanceStatistics?.in_progress_records)}
                  color="info"
                  icon={'uil:money-insert'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
                <AppWidgetSummary
                  title="Overdue"
                  total={fNumber(maintenanceStatistics?.overdue_records)}
                  color="warning"
                  icon={'ph:drop-half-bottom-fill'}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
                <AppWidgetSummary
                  title="Returned"
                  total={fNumber(maintenanceStatistics?.returned_records)}
                  color="error"
                  icon={'ic:outline-watch-later'}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                    <LoadingButton
                      loading={isExporting}
                      onClick={() => exportReport()}
                      variant="contained"
                      size="small"
                      color="success"
                      startIcon={<Iconify icon="eva:arrow-down-fill" />}
                    >
                      {(reportUrl && 'Download') || 'Export'}
                    </LoadingButton>
                  </Stack>
                </Box>
                <Stack spacing={2}>
                  <Stack sx={{ px: 2, mt: 3 }} spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                    <TextField
                      sx={{ minWidth: 330 }}
                      autoFocus
                      value={searchTerm}
                      onChange={handleSearchTermChange}
                      placeholder="Search anything..."
                    />
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      format="yyyy-MM-dd"
                      onChange={handleStartDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      format="yyyy-MM-dd"
                      onChange={handleEndDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />

                    <LoadingButton
                      variant="contained"
                      startIcon={<SearchIcon />}
                      sx={{ minWidth: 'auto' }}
                      onClick={() => handleSearch(searchTerm)}
                    >
                      Search
                    </LoadingButton>
                  </Stack>
                  <CardHeader title={'Maintenance'} subheader={'Repairs'} />
                </Stack>
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center' }}>
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#2065d1"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible
                          />
                        </Grid>
                      ) : (
                        <Table size="small">
                          <UserListHead headLabel={TABLE_HEAD} rowCount={maintenanceRecords?.length} />
                          <TableBody>
                            {maintenanceRecords?.length > 0 ? (
                              maintenanceRecords.map((record) => (
                                <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                  <TableCell align="left">{record?.asset?.barcode_number}</TableCell>
                                  <TableCell align="left">{record.asset?.name}</TableCell>
                                  <TableCell align="left">{record?.branch?.name}</TableCell>
                                  <TableCell align="left">{record?.staff?.user?.display_name}</TableCell>
                                  <TableCell align="left">{record?.service_provider?.display_name}</TableCell>
                                  <TableCell align="left">
                                    {record?.repair_type && sentenceCase(record?.repair_type)}
                                  </TableCell>
                                  <TableCell align="left">
                                    {record?.repair_venue && sentenceCase(record?.repair_venue)}
                                  </TableCell>
                                  <TableCell align="left">{fDateTime(record?.created_at)}</TableCell>

                                  <TableCell align="left">
                                    <Label
                                      color={
                                        (record?.status === 'PENDING' && 'warning') ||
                                        (record?.status === 'IN_PROGRESS' && 'warning') ||
                                        (record?.status === 'REJECTED' && 'error') ||
                                        'success'
                                      }
                                    >
                                      {sentenceCase(record?.status)}
                                    </Label>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Label
                                      color={
                                        (record?.asset_return_approval_status === 'PENDING' && 'warning') ||
                                        (record?.asset_return_approval_status === 'REJECTED' && 'error') ||
                                        'success'
                                      }
                                    >
                                      {sentenceCase(record?.asset_return_approval_status)}
                                    </Label>
                                  </TableCell>
                                  <TableCell>
                                    <Stack spacing={2} direction="row">
                                      {user?.role === 'ADMIN' && record?.admin_approval_status === 'PENDING' && (
                                        <>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                              setSelectedRow(record);
                                              setOpenAccept(true);
                                            }}
                                          >
                                            Approve
                                          </Button>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={() => {
                                              setSelectedRow(record);
                                              setOpen(true);
                                            }}
                                          >
                                            Reject
                                          </Button>
                                        </>
                                      )}

                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => navigate(`/maintenance/app/${record?.id}`)}
                                      >
                                        View
                                      </Button>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <>Maintenance records will appear here</>
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={pagination?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
