import { useState, useEffect, useRef } from 'react';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  CardHeader,
  CardContent,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Divider,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { AuditTableHead, TableNoData } from '../../../sections/@dashboard/user';

import { Page, TableSkeleton } from '../../../components';
import { fCurrency, fNumber } from '../../../utils/formatNumber';
import { getAuditBranches } from '../../branches/store/branchActions';
import { applyFilter } from '../../../sections/@dashboard/user/utils';
import { getComparator } from '../../../hooks/useTable';
import { getAuditAssets } from '../../fixedassets/store/assetActions';
import { createAuditRecord } from '../store/auditActions';

export default function Audit() {
  const dispatch = useDispatch();
  const ref = useRef();
  const { auditBranches } = useSelector((state) => state.branchReducer);
  const { auditFixedAssets, pagination } = useSelector((state) => state.assetReducer);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [barcodeNumber, setBarcodeNumber] = useState('');
  const [description, setDescription] = useState('');
  const [auditBranch, setAuditBranch] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [filterName, setFilterName] = useState('');
  const [scannedAssets, setScannedAssets] = useState([]);

  // const toastrSuccess = (msg) => {
  //   toast.success(msg);
  // };

  const toastrError = (msg) => {
    toast.error(msg);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };
  const dataFiltered = applyFilter({
    inputData: auditFixedAssets,
    comparator: getComparator(order, orderBy),
    filterName,
  });
  const denseHeight = 72;
  const isNotFound = !dataFiltered?.length && !!filterName;

  const fetchAssets = () => {
    dispatch(getAuditAssets({ page: page + 1, rowsPerPage: 1000, locationId: auditBranch }));
  };

  // Handle scanning an asset
  const handleScanAsset = () => {
    // Find the asset with the scanned barcode number
    const scannedAsset = auditFixedAssets.find((asset) => asset.barcode_number === barcodeNumber);

    if (scannedAsset) {
      // Mark the asset as scanned by adding it to the scannedAssets state
      setScannedAssets((prevScannedAssets) => [...prevScannedAssets, scannedAsset]);
      setSelected(scannedAsset);
      // toastrSuccess('Asset found');
    }

    // Clear the barcode input
    setBarcodeNumber('');
    setSelected([]);
  };
  const handleCompleteAudit = () => {
    if (scannedAssets.length > 0) {
      const scannedAssetIds = scannedAssets.map((asset) => asset.id);
      if (auditBranch) {
        const payload = {
          branch_id: auditBranch,
          description,
          total_assets: pagination?.count,
          asset_ids: scannedAssetIds,
        };
        dispatch(createAuditRecord(payload));
      } else {
        toastrError('Branch is required');
      }
    } else {
      toastrError('No asset is scanned yet');
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      //  Get input value
      setBarcodeNumber(event.target.value);
      handleScanAsset();
    }
  };

  useEffect(() => {
    dispatch(getAuditBranches({}));
  }, [dispatch]);

  return (
    <>
      <Page title="Asset Audit">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ py: 3 }} elevation="1">
                  <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <Stack width={1} textAlign="center">
                      <Typography variant="subtitle">TOTAL ASSETS</Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {fNumber(pagination?.count)}
                      </Typography>
                    </Stack>

                    <Stack width={1} textAlign="center">
                      <Typography variant="subtitle">SCANNED ASSETS</Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {fNumber(scannedAssets.length)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader title={'Select Branch'} subheader={''} />
                  <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                    <Box
                      sx={{
                        display: 'grid',
                        columnGap: 2,
                        rowGap: 3,
                        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="branches-label">Branch</InputLabel>
                        <Select
                          name="branch_id"
                          id="branches-label"
                          value={auditBranch}
                          label="Branch"
                          onChange={(event) => setAuditBranch(event.target.value)}
                        >
                          {auditBranches?.map((branch) => (
                            <MenuItem key={branch.id} value={branch.id}>
                              {branch.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        multiline
                        name="description"
                        label="Description"
                        placeholder="Optional"
                        onChange={(event) => setDescription(event.target.value)}
                      />
                      <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Button size="medium" variant="contained" onClick={fetchAssets}>
                          Fetch Assets
                        </Button>
                      </Stack>
                    </Box>
                  </Paper>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader title={'Scan Here'} subheader={''} />
                  <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                    <Box sx={{ mb: 0.5 }}>
                      <QrCodeScannerIcon />
                    </Box>
                    <TextField
                      fullWidth
                      size="large"
                      name="barcodeNumber"
                      color="primary"
                      placeholder="Scan here..."
                      ref={ref}
                      value={barcodeNumber}
                      onChange={(event) => setBarcodeNumber(event.target.value.trim())}
                      onKeyDown={handleKeyDown}
                      focused
                      autoFocus
                    />
                  </Paper>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                      <Button size="small" variant="contained" onClick={handleCompleteAudit}>
                        Complete Audit
                      </Button>
                    </Stack>
                  </Box>
                  <CardHeader title={'Asset Audit'} subheader={'Locate,Count & Verify'} />
                  <CardContent>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                        <Table size="small">
                          <AuditTableHead
                            order={order}
                            orderBy={orderBy}
                            rowCount={auditFixedAssets?.length}
                            numSelected={selected?.length}
                            onRequestSort={handleSort}
                            headLabel={[
                              { id: 'name', label: 'Description', alignRight: false },
                              { id: 'asset_class', label: 'Category', alignRight: false },
                              { id: 'barcode_number', label: 'Barcode#', alignRight: false },
                              { id: 'serial_no', label: 'Serial#', alignRight: false },
                              { id: 'cost', label: 'Cost', alignRight: false },
                              { id: 'location', label: 'Current Location', alignRight: false },
                              { id: 'assigned_to', label: 'AssignedTo', alignRight: false },
                              // { id: '' },
                            ]}
                          />
                          <TableBody>
                            {(dataFiltered?.length > 0 &&
                              dataFiltered?.map(
                                (row, index) =>
                                  (row && (
                                    <TableRow
                                      hover
                                      key={row?.id}
                                      tabIndex={-1}
                                      role="checkbox"
                                      selected={selected?.indexOf(row.id) !== -1}
                                    >
                                      <TableCell padding="checkbox">
                                        <Checkbox disableRipple checked={scannedAssets.includes(row)} />
                                      </TableCell>

                                      <TableCell align="left">{row?.name}</TableCell>
                                      <TableCell align="left">{row?.asset_class}</TableCell>
                                      <TableCell align="left">{row?.barcode_number}</TableCell>
                                      <TableCell align="left">{row?.serial_no}</TableCell>
                                      <TableCell align="left">{fCurrency(row?.cost)}</TableCell>
                                      <TableCell align="left">{row?.location?.name}</TableCell>
                                      <TableCell align="left">{row?.assigned_to?.display_name}</TableCell>
                                    </TableRow>
                                  )) || <TableSkeleton key={index} sx={{ height: denseHeight }} />
                              )) || <TableNoData isNotFound={isNotFound} />}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>

                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      component="div"
                      count={pagination?.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
