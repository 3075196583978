import PropTypes from 'prop-types';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { acceptReturnRequest } from '../store/maintenanceActions';

AcceptReturn.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  maintenanceId: PropTypes.string,
};

export default function AcceptReturn({ open, handleClose, maintenanceId }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.maintenanceReducer);

  const handleReturn = async () => {
    dispatch(acceptReturnRequest(maintenanceId));
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Confirmed maintenance is complete and asset is returned?'}</DialogTitle>
      <DialogActions>
        <Button size="small" variant="contained" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton type="submit" size="small" variant="contained" loading={isLoading} onClick={handleReturn}>
          Accept
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
