export const GET_USERS_REQUEST="USERS/GET_USERS_REQUEST";
export const GET_USERS_SUCCESS="USERS/GET_USERS_SUCCESS";
export const GET_USERS_FAILURE="USERS/GET_USERS_FAILURE";
export const GET_AUDIT_USERS_REQUEST="USERS/GET_AUDIT_USERS_REQUEST";
export const GET_AUDIT_USERS_SUCCESS="USERS/GET_AUDIT_USERS_SUCCESS";
export const GET_AUDIT_USERS_FAILURE="USERS/GET_AUDIT_USERS_FAILURE";
export const GET_SERVICE_PROVIDERS_REQUEST="USERS/GET_SERVICE_PROVIDERS_REQUEST";
export const GET_SERVICE_PROVIDERS_SUCCESS="USERS/GET_SERVICE_PROVIDERS_SUCCESS";
export const GET_SERVICE_PROVIDERS_FAILURE="USERS/GET_SERVICE_PROVIDERS_FAILURE";
export const CREATE_USER_REQUEST="USERS/CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS="USERS/CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE="USERS/CREATE_USER_FAILURE";
export const FILTER_USERS_REQUEST="USERS/FILTER_USERS_REQUEST";
export const FILTER_USERS_SUCCESS="USERS/FILTER_USERS_SUCCESS";
export const FILTER_USERS_FAILURE="USERS/FILTER_USERS_FAILURE";
export const UPDATE_USERS_REQUEST="USERS/UPDATE_USERS_REQUEST";
export const UPDATE_USERS_SUCCESS="USERS/UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_FAILURE="USERS/UPDATE_USERS_FAILURE";
export const DELETE_USERS_REQUEST="USERS/DELETE_USERS_REQUEST";
export const DELETE_USERS_SUCCESS="USERS/DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAILURE="USERS/DELETE_USERS_FAILURE";