import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Stack, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

import { invoiceApproval } from '../store/paymentActions';

Decline.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  invoiceId: PropTypes.string,
};

export default function Decline({ open, handleClose, invoiceId }) {
  const dispatch = useDispatch();

  const SPDeclineSchema = Yup.object().shape({
    rejectionReason: Yup.string().required('Rejection reason is required'),
  });

  const defaultValues = {
    rejectionReason: '',
  };

  const methods = useForm({
    resolver: yupResolver(SPDeclineSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const values = {
      approval_status: 'REJECTED',
      description: data.rejectionReason,
    };
    const payload = {
      id: invoiceId,
      payload: values,
    };
    dispatch(invoiceApproval(payload));
    handleClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      fullWidth
      keepMounted
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Rejection Form</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <RHFTextField multiline row="3" name="rejectionReason" label="Rejection Reason" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
