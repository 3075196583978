import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
// @mui
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
  CardHeader,
  CardContent,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { fDateTime } from '../../../utils/formatTime';
import { getAssetAuditRecords } from '../store/auditActions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'auditor', label: 'Auditor', alignRight: false },
  { id: 'auditBranch', label: 'Audit Branch', alignRight: false },
  { id: 'expectedBranch', label: 'Expected Branch', alignRight: false },
  { id: 'reconciled', label: 'Reconciled', alignRight: false },
];
// ----------------------------------------------------------------------
AssetAudit.propTypes = {
  assetId: PropTypes.string,
};

export default function AssetAudit({ assetId }) {
  const dispatch = useDispatch();
  const { isLoading, scannedAssets } = useSelector((state) => state.auditReducer);
  //

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const payload = {
      id: assetId,
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getAssetAuditRecords(payload));
  }, [dispatch, assetId, page, rowsPerPage]);

  return (
    <>
      <Page title="Audit History">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <CardHeader title={'Audit History'} subheader={''} />
              <CardContent>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                    {isLoading ? (
                      <Grid container sx={{ justifyContent: 'center' }}>
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#2065d1"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible
                        />
                      </Grid>
                    ) : (
                      <Table size="small">
                        <UserListHead headLabel={TABLE_HEAD} rowCount={scannedAssets?.length} />
                        <TableBody>
                          {scannedAssets?.length > 0 ? (
                            scannedAssets.map((record) => (
                              <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                <TableCell align="left">{fDateTime(record?.date_created)}</TableCell>
                                <TableCell align="left">{record?.auditor?.user?.display_name}</TableCell>
                                <TableCell align="left">{record?.audit_branch?.name}</TableCell>
                                <TableCell align="left">{record.expected_branch?.name}</TableCell>
                                <TableCell align="left">
                                  <Label color={(record?.reconciled && 'success') || 'success'}>
                                    {(record?.reconciled && 'Yes') || 'No'}
                                  </Label>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <>Asset audit history will appear here</>
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={scannedAssets?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
