import { toast } from 'react-toastify';

import {
  GET_DISPOSALS_REQUEST,
  GET_DISPOSALS_SUCCESS,
  GET_DISPOSALS_ERROR,
  GET_HANDOVER_REQUEST,
  GET_HANDOVER_SUCCESS,
  GET_HANDOVER_ERROR,
  CREATE_DISPOSAL_REQUEST,
  CREATE_DISPOSAL_SUCCESS,
  CREATE_DISPOSAL_ERROR,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERROR,
  UPDATE_MAINTENANCE_RECORD_REQUEST,
  UPDATE_MAINTENANCE_RECORD_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_ERROR,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
} from './disposalActionTypes';
import call from '../../../core/services/http';
import disposalConstants from './disposalConstants';

const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};

export const getDisposals = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DISPOSALS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', disposalConstants.GET_DISPOSALS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_DISPOSALS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_DISPOSALS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_DISPOSALS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_DISPOSALS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const getHandoverRecord = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_HANDOVER_REQUEST,
      isLoading: true,
    });
    const response = await call('get', disposalConstants.GET_HANDOVER_RECORD(id));
    if (response.data.status) {
      dispatch({
        type: GET_HANDOVER_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_HANDOVER_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_HANDOVER_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_HANDOVER_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const approveDisposal = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STATUS_REQUEST,
      isLoading: true,
    });
    const response = await call('post', disposalConstants.APPROVE_DISPOSAL(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_STATUS_SUCCESS,
        isLoading: false,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_STATUS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const declineDisposal = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STATUS_REQUEST,
      isLoading: true,
    });
    const response = await call('post', disposalConstants.DECLINE_DISPOSAL(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_STATUS_SUCCESS,
        isLoading: false,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_STATUS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const createDisposalRecord = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_DISPOSAL_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', disposalConstants.CREATE_DISPOSAL_RECORD, payload);
    if (response.data.status) {
      dispatch({
        type: CREATE_DISPOSAL_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: CREATE_DISPOSAL_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_DISPOSAL_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_DISPOSAL_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const updateMaintenanceStatus =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', disposalConstants.UPDATE_STATUS(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };
export const approveMaintenanceRecord =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', disposalConstants.APPROVE_MAINTENANCE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };

export const maintenanceReturnRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MAINTENANCE_RECORD_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', disposalConstants.RETURN_REQUEST(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
        isLoading: false,
        isSuccess: true,
        message: response.data.message,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const acceptReturnRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MAINTENANCE_RECORD_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', disposalConstants.ACCEPT_RETURN_REQUEST(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
        isLoading: false,
        isSuccess: true,
        message: response.data.message,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const declineReturnRequest =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', disposalConstants.DECLINE_RETURN_REQUEST(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };

export const serviceProviderAcceptance =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', disposalConstants.SP_REPAIR_ACCEPTANCE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };
export const serviceProviderDecline =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', disposalConstants.SP_REPAIR_DECLINE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };
export const ManagerDecline =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', disposalConstants.DECLINE_MAINTENANCE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };

export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ASSET_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('delete', disposalConstants.DELETE_ASSET(id));
    if (response.data.status) {
      dispatch({
        type: DELETE_ASSET_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: DELETE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: DELETE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: DELETE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
