/* eslint-disable*/
import PropTypes from 'prop-types';
import { useState } from 'react';
import { format } from 'date-fns';
import { sentenceCase } from 'change-case';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Table,
  Avatar,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  IconButton,
  TableContainer,
  Paper,
} from '@mui/material';
// utils

import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';
import Label from '../../../components/label/Label';
import { fDateTime } from '../../../utils/formatTime';
// components

// ----------------------------------------------------------------------

RecentMaintenance.propTypes = {
  recentRecords: PropTypes.array,
};

export default function RecentMaintenance({ recentRecords }) {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  return (
    <>
      <Card>
        <CardHeader title="Recents" sx={{ mb: 3 }} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }} component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Asset</TableCell>
                  <TableCell>Repair Type</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {recentRecords?.map(({ id, asset: { name }, repair_type, status, created_at }, index) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Typography variant="subtitle2">{name}</Typography>
                    </TableCell>

                    <TableCell>{repair_type && sentenceCase(repair_type)}</TableCell>
                    {/* <TableCell>{fCurrency(outstanding_balance)}</TableCell> */}
                    <TableCell>
                      <Label
                        color={(status === 'PENDING' && 'warning') || (status === 'REJECTED' && 'error') || 'success'}
                      >
                        {sentenceCase(status)}
                      </Label>
                    </TableCell>
                    <TableCell>{fDateTime(created_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider />
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

AvatarIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

function AvatarIcon({ icon }) {
  return (
    <Avatar
      sx={{
        width: 48,
        height: 48,
        color: 'text.secondary',
        bgcolor: 'background.neutral',
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Avatar>
  );
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function MoreMenuButton() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };
}
