// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
// sections
import { Image, Page } from '../../../components';
import { APP_NAME } from '../../../core/environment/environment';
import { LoginForm } from '../components';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Page title="Login">
        <StyledRoot>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />

          {mdUp && (
            <StyledSection>
              <Typography variant="h4" sx={{ px: 5, mt: 10, mb: 5 }}>
                Asset Management System
              </Typography>
              <Image
                visibleByDefault
                disabledEffect
                alt="login"
                src="https://quickmart.co.ke/wp-content/uploads/2022/02/Pop-up-Logo.png"
              />
            </StyledSection>
          )}
          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h5" gutterBottom>
                Sign in to {APP_NAME}
              </Typography>

              {/* <Typography variant="body2" sx={{ mb: 5 }}>
                Don’t have an account? {''}
                <Link to="/register" underline="hover" variant="subtitle2">
                  Get started
                </Link>
              </Typography> */}
              <LoginForm />
            </StyledContent>
          </Container>
        </StyledRoot>
      </Page>
    </>
  );
}
