import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Box, Stack, TextField } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {  useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { FormProvider } from '../../../components/hook-form';
import { fDateYMD } from '../../../utils/formatTime';
import reportConstants from '../store/reportConstants';
import call from '../../../core/services/http';

const toastrError = (msg) => {
  toast.error(msg);
};

export default function MaintenanceCategoryExpensesReport() {
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const handleStartDateChange = (newValue) => {
    setValue('startDate', fDateYMD(newValue));
    setSelectedStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setValue('endDate', fDateYMD(newValue));
    setSelectedEndDate(newValue);
  };

  const generateMaintenanceCategoryExpensesReport = async (payload) => {
    try {
      setIsLoading(true);
      const response = await call('get', reportConstants.MAINTENANCE_CATEGORY_EXPENSES_REPORT(payload));
      if (response.status) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        // link.href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // eslint-disable-next-line no-useless-concat
        link.download = 'MaintenanceExpensesBranchReport' + '.csv';
        link.click();

        // Clean up the temporary objects
        window.URL.revokeObjectURL(link);
        link.remove();
        setIsLoading(false);
      } else {
        toastrError(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred while generating report';
        setIsLoading(false);
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred while generating report';
        setIsLoading(false);
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        setIsLoading(false);
        toastrError('An error occurred while generating report');
      }
    }
  };
  const ReportSchema = yup.object({
    // staffId: yup.number().positive().integer().required('Staff is required'),
  });

  const defaultValues = {
    startDate: fDateYMD(selectedStartDate) || '',
    endDate: fDateYMD(selectedEndDate) || '',
  };

  const methods = useForm({
    resolver: yupResolver(ReportSchema),
    defaultValues,
  });

  const {
    // reset,
    setValue,
    handleSubmit,
  } = methods;

  const onSubmit = async (data) => {
    const payload = {
      locationId: data.locationId,
      startDate: data.startDate,
      endDate: data.endDate,
    };
    generateMaintenanceCategoryExpensesReport(payload);
  };

  return (
    <Box
      sx={{
        padding: 3,
        marginTop: 3,
        borderRadius: 1,
      }}
    >
      <Stack spacing={3}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <DatePicker
              label="Start Date"
              value={selectedStartDate}
              format="yyyy-MM-dd"
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={selectedEndDate}
              format="yyyy-MM-dd"
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>

          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
            <LoadingButton
              type="submit"
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              disabled={isLoading}
              loading={isLoading}
            >
              Export
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>
    </Box>
  );
}
