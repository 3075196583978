import { toast } from 'react-toastify';

import {
  GET_MAINTENANCE_RECORDS_REQUEST,
  GET_MAINTENANCE_RECORDS_SUCCESS,
  GET_MAINTENANCE_RECORDS_ERROR,
  GET_MAINTENANCE_RECORD_REQUEST,
  GET_MAINTENANCE_RECORD_SUCCESS,
  GET_MAINTENANCE_RECORD_ERROR,
  CREATE_MAINTENANCE_RECORD_REQUEST,
  CREATE_MAINTENANCE_RECORD_SUCCESS,
  CREATE_MAINTENANCE_RECORD_ERROR,
  GET_REPAIR_DETAILS_REQUEST,
  GET_REPAIR_DETAILS_SUCCESS,
  GET_REPAIR_DETAILS_ERROR,
  CREATE_REPAIR_RECORD_REQUEST,
  CREATE_REPAIR_RECORD_SUCCESS,
  CREATE_REPAIR_RECORD_ERROR,
  UPDATE_MAINTENANCE_RECORD_REQUEST,
  UPDATE_MAINTENANCE_RECORD_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_ERROR,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
} from './maintenanceActionTypes';
import call from '../../../core/services/http';
import maintenanceConstants from './maintenanceConstants';

const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};

export const getOnsiteMaintenanceRecords = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAINTENANCE_RECORDS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', maintenanceConstants.GET_ONSITE_MAINTENANCE_RECORDS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_MAINTENANCE_RECORDS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getMaintenanceRecords = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAINTENANCE_RECORDS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', maintenanceConstants.GET_MAINTENANCE_RECORDS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_MAINTENANCE_RECORDS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const searchMaintenanceRecords = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAINTENANCE_RECORDS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', maintenanceConstants.SEARCH_MAINTENANCE_RECORDS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_MAINTENANCE_RECORDS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getOffsiteMaintenanceRecords = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAINTENANCE_RECORDS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', maintenanceConstants.GET_OFFSITE_MAINTENANCE_RECORDS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_MAINTENANCE_RECORDS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getAssetMaintenanceRecords = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAINTENANCE_RECORDS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', maintenanceConstants.GET_ASSET_MAINTENANCE_RECORDS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_MAINTENANCE_RECORDS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORDS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getMaintenanceRecord = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MAINTENANCE_RECORD_REQUEST,
      isLoading: true,
    });
    const response = await call('get', maintenanceConstants.GET_MAINTENANCE_RECORD(id));
    if (response.data.status) {
      dispatch({
        type: GET_MAINTENANCE_RECORD_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const createMaintenanceRecord = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_MAINTENANCE_RECORD_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', maintenanceConstants.CREATE_MAINTENANCE_RECORD, payload);
    if (response.data.status) {
      dispatch({
        type: CREATE_MAINTENANCE_RECORD_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: CREATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getRepairDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPAIR_DETAILS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', maintenanceConstants.GET_REPAIR_DETAIL(id));
    if (response.data.status) {
      dispatch({
        type: GET_REPAIR_DETAILS_SUCCESS,
        isLoading: false,

        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_REPAIR_DETAILS_ERROR,
        isLoading: false,

        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_REPAIR_DETAILS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_REPAIR_DETAILS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const createRepairRecord = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_REPAIR_RECORD_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', maintenanceConstants.CREATE_REPAIR_RECORD, payload);
    if (response.data.status) {
      dispatch({
        type: CREATE_REPAIR_RECORD_SUCCESS,
        isLoading: false,
        isSuccess: true,
        message: response.data.message,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: CREATE_REPAIR_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_REPAIR_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_REPAIR_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const updateMaintenanceStatus =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', maintenanceConstants.UPDATE_STATUS(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };
export const updateRepairRecord =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('put', maintenanceConstants.UPDATE_REPAIR_RECORD(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };
export const approveMaintenanceRecord =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', maintenanceConstants.APPROVE_MAINTENANCE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };

export const maintenanceReturnRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MAINTENANCE_RECORD_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', maintenanceConstants.RETURN_REQUEST(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
        isLoading: false,
        isSuccess: true,
        message: response.data.message,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const acceptReturnRequest = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MAINTENANCE_RECORD_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', maintenanceConstants.ACCEPT_RETURN_REQUEST(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
        isLoading: false,
        isSuccess: true,
        message: response.data.message,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const declineReturnRequest =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', maintenanceConstants.DECLINE_RETURN_REQUEST(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };

export const serviceProviderAcceptance =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', maintenanceConstants.SP_REPAIR_ACCEPTANCE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };
export const serviceProviderDecline =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', maintenanceConstants.SP_REPAIR_DECLINE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };
export const ManagerDecline =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_MAINTENANCE_RECORD_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('post', maintenanceConstants.DECLINE_MAINTENANCE(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_MAINTENANCE_RECORD_ERROR,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };

export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ASSET_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('delete', maintenanceConstants.DELETE_ASSET(id));
    if (response.data.status) {
      dispatch({
        type: DELETE_ASSET_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: DELETE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: DELETE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: DELETE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
