export const GET_HANDOVERS_REQUEST = 'HANDOVERS/GET_HANDOVERS_REQUEST';
export const GET_HANDOVERS_SUCCESS = 'HANDOVERS/GET_HANDOVERS_SUCCESS';
export const GET_HANDOVERS_ERROR = 'HANDOVERS/GET_HANDOVERS_ERROR';
export const GET_HANDOVER_REQUEST = 'HANDOVERS/GET_HANDOVER_REQUEST';
export const GET_HANDOVER_SUCCESS = 'HANDOVERS/GET_HANDOVER_SUCCESS';
export const GET_HANDOVER_ERROR = 'HANDOVERS/GET_HANDOVER_ERROR';
export const UPDATE_STATUS_REQUEST = 'HANDOVERS/UPDATE_STATUS_REQUEST';
export const UPDATE_STATUS_SUCCESS = 'HANDOVERS/UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_ERROR = 'HANDOVERS/UPDATE_STATUS_ERROR';
export const CREATE_HANDOVER_REQUEST = 'HANDOVERS/CREATE_HANDOVER_REQUEST';
export const CREATE_HANDOVER_SUCCESS = 'HANDOVERS/CREATE_HANDOVER_SUCCESS';
export const CREATE_HANDOVER_ERROR = 'HANDOVERS/CREATE_HANDOVER_ERROR';
