import { Container, Card, CardHeader, CardContent, Stack, TextField, CardActions, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import {  useSelector } from 'react-redux';
import { customerSchema } from '../schemas/customerSchemas';
// import { createCustomer } from '../store/branchActions';

CustomerForm.propTypes = {
  handleBack: PropTypes.func,
};

export default function CustomerForm({ handleBack }) {
  // const dispatch = useDispatch();
  const { isLoading, isSuccess } = useSelector((state) => state.customerReducer);

  const handleCancel = () => {
    handleBack();
  };

  // const onSubmit = async (values) => {
  //   // dispatch(createCustomer(values));
  // };
  useEffect(() => {
    if (isSuccess) {
      handleBack();
    }
  }, [isSuccess, handleBack]);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      mobile_number: '',
      email: '',
    },
    validationSchema: customerSchema,
    // onSubmit,
    isSubmitting: isLoading,
  });

  return (
    <Container maxWidth="xl">
      <Card sx={{ minWidth: 275 }}>
        <form onSubmit={handleSubmit}>
          <CardHeader title="Create Customer" />
          <CardContent>
            <Stack spacing={2}>
              <TextField
                name="first_name"
                type="text"
                label="First Name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.first_name && Boolean(errors.first_name)}
              />
              {touched.first_name && Boolean(errors.first_name) && (
                <Typography color="error" variant="body2">
                  {errors.first_name}
                </Typography>
              )}
              <TextField
                name="last_name"
                type="text"
                label="Last Name"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.last_name && Boolean(errors.last_name)}
              />
              {touched.last_name && Boolean(errors.last_name) && (
                <Typography color="error" variant="body2">
                  {errors.last_name}
                </Typography>
              )}
              <TextField
                name="mobile_number"
                type="text"
                label="Mobile Number"
                value={values.mobile_number}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.mobile_number && Boolean(errors.mobile_number)}
              />
              {touched.mobile_number && Boolean(errors.mobile_number) && (
                <Typography color="error" variant="body2">
                  {errors.mobile_number}
                </Typography>
              )}
              <TextField
                name="email"
                type="text"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
              />
              {touched.email && Boolean(errors.email) && (
                <Typography color="error" variant="body2">
                  {errors.email}
                </Typography>
              )}
            </Stack>
          </CardContent>
          <CardActions>
            <LoadingButton color="error" type="submit" variant="contained" onClick={handleCancel}>
              Cancel
            </LoadingButton>
            <LoadingButton loading={isLoading} disabled={isSubmitting} type="submit" variant="contained">
              Submit
            </LoadingButton>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
}
