import PropTypes from 'prop-types';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { approveDisposal } from '../store/disposalActions';

Accept.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  disposalId: PropTypes.string,
};

export default function Accept({ open, handleClose, disposalId }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.disposalReducer);

  const handleApproveMaintenance = async () => {
    dispatch(approveDisposal(disposalId));
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'You are about to approve disposal request.'}</DialogTitle>
      <DialogActions>
        <Button size="small" variant="contained" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          size="small"
          variant="contained"
          loading={isLoading}
          onClick={handleApproveMaintenance}
        >
          Proceed
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
