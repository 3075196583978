import { sentenceCase } from 'change-case';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
  CardHeader,
  CardContent,
  Paper,
  Stack,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { fDateTime } from '../../../utils/formatTime';
import { getProfile } from '../../../shared/auth/authService';
import { getAssetMaintenanceRecords } from '../../maintenance/store/maintenanceActions';
import { Accept, Decline } from '../../maintenance/components';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Barcode Number', alignRight: false },
  { id: 'name', label: 'Asset Name', alignRight: false },
  { id: 'location', label: 'Branch', alignRight: false },
  { id: 'manager', label: 'Manager', alignRight: false },
  { id: 'service_provider', label: 'Service Provider', alignRight: false },
  { id: 'repair_type', label: 'Repair Type', alignRight: false },
  { id: 'date_created', label: 'Created', alignRight: false },
  { id: 'condition', label: 'Repair Status', alignRight: false },
  { id: 'condition', label: 'Return Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------
AssetMaintenance.propTypes = {
  assetId: PropTypes.string,
};

export default function AssetMaintenance({ assetId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, maintenanceRecords, pagination } = useSelector((state) => state.maintenanceReducer);
  //

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [user, setUser] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      const payload = {
        id: assetId,
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getAssetMaintenanceRecords(payload));
    }, 2000); // 2000 mil
  };

  const handleCloseAccept = () => {
    setOpenAccept(!openAccept);
    setTimeout(() => {
      const payload = {
        id: assetId,
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getAssetMaintenanceRecords(payload));
    }, 2000); // 2000 mil
  };

  useEffect(() => {
    const payload = {
      id: assetId,
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getAssetMaintenanceRecords(payload));
  }, [dispatch, assetId, page, rowsPerPage]);

  useEffect(() => {
    const profile = getProfile();
    if (profile) {
      setUser(profile);
    }
  }, []);

  return (
    <>
      <Page title="Asset Repairs">
        {openAccept && <Accept open={openAccept} handleClose={handleCloseAccept} maintenanceId={selectedRow?.id} />}
        {open && <Decline open={open} handleClose={handleClose} maintenanceId={selectedRow?.id} />}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <CardHeader title={'Asset Repairs'} subheader={'Maintenance records'} />
              <CardContent>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                    {isLoading ? (
                      <Grid container sx={{ justifyContent: 'center' }}>
                        <ThreeDots
                          height="80"
                          width="80"
                          radius="9"
                          color="#2065d1"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible
                        />
                      </Grid>
                    ) : (
                      <Table size="small">
                        <UserListHead headLabel={TABLE_HEAD} rowCount={maintenanceRecords?.length} />
                        <TableBody>
                          {maintenanceRecords?.length > 0 ? (
                            maintenanceRecords.map((record) => (
                              <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                <TableCell align="left">{record?.asset?.barcode_number}</TableCell>
                                <TableCell align="left">{record.asset?.name}</TableCell>
                                <TableCell align="left">{record?.asset?.location?.name}</TableCell>
                                <TableCell align="left">{record?.staff?.user?.display_name}</TableCell>
                                <TableCell align="left">{record?.service_provider?.display_name}</TableCell>
                                <TableCell align="left">
                                  {record?.repair_type && sentenceCase(record?.repair_type)}
                                </TableCell>
                                <TableCell align="left">{fDateTime(record?.created_at)}</TableCell>

                                <TableCell align="left">
                                  <Label
                                    color={
                                      (record?.status === 'PENDING' && 'warning') ||
                                      (record?.status === 'IN_PROGRESS' && 'warning') ||
                                      (record?.status === 'REJECTED' && 'error') ||
                                      'success'
                                    }
                                  >
                                    {sentenceCase(record?.status)}
                                  </Label>
                                </TableCell>
                                <TableCell align="left">
                                  <Label
                                    color={
                                      (record?.asset_return_approval_status === 'PENDING' && 'warning') ||
                                      (record?.asset_return_approval_status === 'REJECTED' && 'error') ||
                                      'success'
                                    }
                                  >
                                    {sentenceCase(record?.asset_return_approval_status)}
                                  </Label>
                                </TableCell>
                                <TableCell>
                                  <Stack spacing={2} direction="row">
                                    {user?.role === 'ADMIN' && record?.admin_approval_status === 'PENDING' && (
                                      <>
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          onClick={() => {
                                            setSelectedRow(record);
                                            setOpenAccept(true);
                                          }}
                                        >
                                          Approve
                                        </Button>
                                        <Button
                                          size="small"
                                          variant="outlined"
                                          color="error"
                                          onClick={() => {
                                            setSelectedRow(record);
                                            setOpen(true);
                                          }}
                                        >
                                          Reject
                                        </Button>
                                      </>
                                    )}

                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => navigate(`/dashboard/maintenance/${record?.id}`)}
                                    >
                                      View
                                    </Button>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <>Asset repairs will appear here</>
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={pagination?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
