import { toast } from 'react-toastify';

import {
  GET_AUDIT_ASSETS_REQUEST,
  GET_AUDIT_ASSETS_SUCCESS,
  GET_AUDIT_ASSETS_ERROR,
  GET_ASSET_AUDITS_REQUEST,
  GET_ASSET_AUDITS_SUCCESS,
  GET_ASSET_AUDITS_ERROR,
  CREATE_AUDIT_REQUEST,
  CREATE_AUDIT_SUCCESS,
  CREATE_AUDIT_ERROR,
  RECONCILE_AUDIT_REQUEST,
  RECONCILE_AUDIT_SUCCESS,
  RECONCILE_AUDIT_ERROR,
} from './auditActionTypes';
import auditConstants from './auditConstants';
import call from '../../../core/services/http';

const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};

export const getScannedAsset = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AUDIT_ASSETS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', auditConstants.GET_AUDIT_ASSETS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_AUDIT_ASSETS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
      // toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_AUDIT_ASSETS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_ASSETS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_ASSETS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const createAuditRecord = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_AUDIT_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', auditConstants.CREATE_AUDIT_RECORD, payload);
    if (response.data.status) {
      dispatch({
        type: CREATE_AUDIT_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: CREATE_AUDIT_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_AUDIT_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_AUDIT_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const reconcileAuditRecord = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: RECONCILE_AUDIT_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', auditConstants.RECONCILE_AUDIT_RECORD, payload);
    if (response.data.status) {
      dispatch({
        type: RECONCILE_AUDIT_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: RECONCILE_AUDIT_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: RECONCILE_AUDIT_ERROR,
        isLoading: false,
        isSuccess: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: RECONCILE_AUDIT_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getAssetAuditRecords = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSET_AUDITS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', auditConstants.GET_ASSET_AUDIT_RECORDS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_ASSET_AUDITS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_ASSET_AUDITS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSET_AUDITS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSET_AUDITS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
