export default {
  GET_AUDIT_ASSETS: ({ page = 1, rowsPerPage = 100, startDate, endDate, branchId }) =>
    `assets/audits/?location_id=${branchId}&start_date=${startDate}&end_date=${endDate}&reconciled=0&page=${page}&page_size=${rowsPerPage}`,
  GET_ASSET_AUDIT_RECORDS: ({ id, page = 1, rowsPerPage = 10 }) =>
    `assets/audits/?asset_id=${id}&page=${page}&page_size=${rowsPerPage}`,
  CREATE_AUDIT_RECORD: 'assets/audit/',
  RECONCILE_AUDIT_RECORD: 'assets/audits/reconciliation/',
  UPDATE_ASSET: (id) => `assets/${id}/update/`,
  DELETE_ASSET: (id) => `assets/${id}/delete/`,
};
