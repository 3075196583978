import { useCallback, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack } from '@mui/material';
import useIsMountedRef from 'use-is-mounted-ref';
import { useDispatch, useSelector } from 'react-redux';
// sections
import { AppWidgetSummary, AssetChartData } from '../../../sections/@dashboard/app';
import { Page } from '../../../components';
import { getBranchDashboardData } from '../store/dashboardActions';
import { RecentLoans } from '../components';
import AppCurrentVisits from '../components/AppCurrentVisits';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { branchDashboardData } = useSelector((state) => state.dashboardReducer);
  const recentLoans = useSelector((state) => state.dashboardReducer.recent_loans);

  const isMountedRef = useIsMountedRef();

  const getStatistics = useCallback(async () => {
    if (isMountedRef.current) {
      dispatch(getBranchDashboardData());
    }
  }, [isMountedRef, dispatch]);

  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Assets"
                total={branchDashboardData?.asset_count}
                color="success"
                icon={'material-symbols:person-book'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="Pending Acceptance IN"
                total={branchDashboardData?.pending_acceptance_in}
                color="info"
                icon={'uil:money-insert'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="Pending Acceptance OUT"
                total={branchDashboardData?.pending_acceptance_out}
                color="warning"
                icon={'ph:drop-half-bottom-fill'}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="Total Asset Net Book Value"
                total={assetsNetValue}
                color="error"
                icon={'ic:outline-watch-later'}
              />
            </Grid> */}

            <Grid item xs={12} md={6} lg={8}>
              <AssetChartData
                title="Asset Purchase Statistics"
                subheader="Purchase insights this year"
                chartLabels={(branchDashboardData?.graph_data && branchDashboardData?.graph_data?.chart_labels) || []}
                chartData={
                  (branchDashboardData?.graph_data &&
                    branchDashboardData?.graph_data?.chart_data?.map(({ category, count }) => {
                      const items = ['area', 'line'];
                      const type = items[Math.floor(Math.random() * items.length)];
                      return {
                        name: category,
                        type,
                        fill: (type === 'area' && 'gradient') || 'solid',
                        data: count.length === 0 ? [0] : count,
                      };
                    })) ||
                  []
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppCurrentVisits
                title="Cost Per Category"
                chartData={(branchDashboardData?.cost_per_class && branchDashboardData?.cost_per_class) || []}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.warning.main,
                  theme.palette.error.main,
                ]}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack>
                <RecentLoans recentLoans={recentLoans} />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
