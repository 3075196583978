import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  CardHeader,
  CardContent,
  Paper,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page, TableSkeleton } from '../../../components';
import { fCurrency, fNumber } from '../../../utils/formatNumber';
import { fDateTime } from '../../../utils/formatTime';
import Iconify from '../../../components/iconify';
import { getRepairDetail } from '../store/maintenanceActions';
import { EditRepair, NewRepair } from '.';
import call from '../../../core/services/http';
import maintenanceConstants from '../store/maintenanceConstants';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'part_name', label: 'Part/Service', alignRight: false },
  { id: 'serial_number', label: 'Serial Number', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: 'estimated_amount', label: 'Est. Amount', alignRight: false },
  { id: 'expensesInclusive', label: 'Expenses Inclusive', alignRight: false },
  { id: 'warranty', label: 'Warranty', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: '' },
];

const storedUser = JSON.parse(localStorage.getItem('profile'));

RepairDetails.propTypes = {
  recordId: PropTypes.string,
};

export default function RepairDetails({ recordId }) {
  const dispatch = useDispatch();
  const { isLoading, maintenanceRepairs, itemsCount } = useSelector((state) => state.maintenanceReducer);

  const [page, setPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [maintenanceRecord, setMaintenanceRecord] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [openEdit, setOpenEdit] = useState(false);

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      dispatch(getRepairDetail(recordId));
    }, 2000); // 2000 mil
  };

  const handleCloseEdit = () => {
    setOpenEdit(!openEdit);
    setTimeout(() => {
      dispatch(getRepairDetail(recordId));
    }, 2000); // 2000
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const fetchMaintenanceRcord = async (recordId) => {
    try {
      const response = await call('get', maintenanceConstants.GET_MAINTENANCE_RECORD(recordId));
      if (response.data.status) {
        setMaintenanceRecord(response.data.data);
      } else {
        console.log('Error occurred');
      }
    } catch (error) {
      console.log('ERROR:', error);
    }
  };

  useEffect(() => {
    dispatch(getRepairDetail(recordId));
  }, [dispatch, recordId]);

  useEffect(() => {
    fetchMaintenanceRcord(recordId);
  }, [dispatch, recordId]);

  return (
    <>
      <Page title="Repairs">
        {open && <NewRepair open={open} handleClose={handleClose} maintenanceId={recordId} />}
        {openEdit && <EditRepair open={openEdit} handleClose={handleCloseEdit} repairRecord={selectedRow} />}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {storedUser?.role === 'SERVICE_PROVIDER' &&
                maintenanceRecord?.admin_approval_status === 'APPROVED' &&
                maintenanceRecord?.rsp_approval_status === 'ACCEPTED' && (
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => setOpen(true)}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        Add Repair Item
                      </Button>
                    </Stack>
                  </Box>
                )}
              <CardHeader title={'Parts'} subheader={'& Expenses'} />
              <CardContent>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                    {isLoading ? (
                      <TableSkeleton />
                    ) : (
                      <Table size="small">
                        <UserListHead headLabel={TABLE_HEAD} rowCount={maintenanceRepairs?.length} />
                        <TableBody>
                          {maintenanceRepairs?.length > 0 ? (
                            maintenanceRepairs?.map((record) => (
                              <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                <TableCell align="left">{record?.part_name}</TableCell>
                                <TableCell align="left">
                                  {(record?.part_serial_number && record?.part_serial_number) || 'N/A'}
                                </TableCell>
                                <TableCell align="left">
                                  {(record?.item_type === 'PART' && fNumber(record?.quantity)) || 'N/A'}
                                </TableCell>
                                <TableCell align="left">{fCurrency(record?.estimated_cost)}</TableCell>
                                <TableCell align="left">{(record?.includes_expenses && 'Yes') || 'No'}</TableCell>
                                <TableCell align="left">
                                  {(record?.warranty && record?.warranty) || 'N/A'} Years
                                </TableCell>
                                <TableCell align="left">{fDateTime(record?.date_created)}</TableCell>
                                <TableCell>
                                  <Stack spacing={2} direction="row">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        setSelectedRow(record);
                                        setOpenEdit(true);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <>No repair details found</>
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={fNumber(itemsCount)}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
