import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { Stack, TextField, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

import { fDateYMD } from '../../../utils/formatTime';
import { getServiceProviders } from '../../users/store/userActions';
import { filterAssets } from '../store/assetActions';
import { getAuditBranches } from '../../branches/store/branchActions';
import { getAuditCategories } from '../../categories/store/categoryActions';

const storedUser = JSON.parse(localStorage.getItem('profile'));

const branchRoles = ['MANAGER', 'BRANCH_MANAGER'];

const isManager = storedUser?.role && branchRoles.includes(storedUser.role);

AssetFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function AssetFilter({ open, handleClose }) {
  const dispatch = useDispatch();
  //   const { serviceProviders } = useSelector((state) => state.userReducer);
  const { auditBranches } = useSelector((state) => state.branchReducer);
  const { auditCategories } = useSelector((state) => state.categoryReducer);

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const NewMaintenanceRecordSchema = Yup.object().shape({
    // repairtType: Yup.string().required('Asset is required'),
    // serviceProviderId: Yup.number().required('Service Provider is required'),
    // // duration: Yup.number().positive().required('Duration is required'),
    // repairDate: Yup.string().required('Repair date is required'),
    // description: Yup.string(),
  });

  const defaultValues = {
    locationId: '',
    categoryId: '',
    assetStatus: '',
    startDate: '',
    endDate: '',
    barcodeNumber: '',
  };

  const handleStartDateChange = (newValue) => {
    setValue('startDate', fDateYMD(newValue));
    setSelectedStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setValue('endDate', fDateYMD(newValue));
    setSelectedEndDate(newValue);
  };

  const methods = useForm({
    resolver: yupResolver(NewMaintenanceRecordSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  useEffect(() => {
    dispatch(getServiceProviders({}));
  }, [dispatch]);

  const onSubmit = async (data) => {
    const payload = {
      locationId: data.locationId,
      barcodeNumber: data.barcodeNumber,
      categoryId: data.categoryId,
      assetStatus: data.assetStatus,
      startDate: data.startDate,
      endDate: data.endDate,
    };
    dispatch(filterAssets(payload));
    handleClose();
  };
  useEffect(() => {
    dispatch(getAuditBranches({}));
    dispatch(getAuditCategories({}));
  }, [dispatch]);
  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Filter Assets</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            {!isManager && (
              <RHFSelect
                fullWidth
                name="locationId"
                label="Branch"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {auditBranches?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
            <RHFSelect
              fullWidth
              name="categoryId"
              label="Category"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {auditCategories?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFTextField name="barcodeNumber" label="Barcode Number" />
            <DatePicker
              label="Start Date"
              value={selectedStartDate}
              format="yyyy-MM-dd"
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={selectedEndDate}
              format="yyyy-MM-dd"
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Filter
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
