import { toast } from 'react-toastify';
import reportConstants from '../store/reportConstants';
import call from '../../../core/services/http';

const toastrError = (msg) => {
  toast.error(msg);
};

export const generateAssetMasterReport = async () => {
  try {
    const response = await call('get', reportConstants.GENERATE_MASTER_ASSET_REPORTS);
    if (response.status) {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      // link.href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // eslint-disable-next-line no-useless-concat
      link.download = 'AssetsMasterReport' + '.csv';
      link.click();

      // Clean up the temporary objects
      window.URL.revokeObjectURL(link);
      link.remove();
    } else {
      toastrError(response.data.message);
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred while generating report';
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred while generating report';
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('An error occurred while generating report');
    }
  }
};
