import { toast } from 'react-toastify';

import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_AUDIT_USERS_REQUEST,
  GET_AUDIT_USERS_SUCCESS,
  GET_AUDIT_USERS_FAILURE,
  GET_SERVICE_PROVIDERS_REQUEST,
  GET_SERVICE_PROVIDERS_SUCCESS,
  GET_SERVICE_PROVIDERS_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  FILTER_USERS_REQUEST,
  FILTER_USERS_SUCCESS,
  FILTER_USERS_FAILURE,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAILURE,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
} from './userActionTypes';
import userConstants from './userConstants';
import call from '../../../core/services/http';

const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};

export const getUsers = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USERS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', userConstants.GET_STAFF(payload));
    if (response.data.status) {
      dispatch({
        type: GET_USERS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_USERS_FAILURE,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getAuditUsers = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AUDIT_USERS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', userConstants.GET_USERS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_AUDIT_USERS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_AUDIT_USERS_FAILURE,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getBranchManagers = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AUDIT_USERS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', userConstants.GET_BRANCH_MANAGERS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_AUDIT_USERS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_AUDIT_USERS_FAILURE,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getServiceProviders = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SERVICE_PROVIDERS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', userConstants.GET_SERVICE_PROVIDERS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_SERVICE_PROVIDERS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_SERVICE_PROVIDERS_FAILURE,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_SERVICE_PROVIDERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_SERVICE_PROVIDERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const filterUsers = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: FILTER_USERS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', userConstants.FILTER_USERS(payload));
    if (response.data.status) {
      dispatch({
        type: FILTER_USERS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: FILTER_USERS_FAILURE,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: FILTER_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: FILTER_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const createStaff = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_USER_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', userConstants.CREATE_USER, payload);
    if (response.data.status) {
      dispatch({
        type: CREATE_USER_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: CREATE_USER_FAILURE,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_USER_FAILURE,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_USER_FAILURE,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const updateStaff =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USERS_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('put', userConstants.UPDATE_STAFF(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_USERS_SUCCESS,
          isLoading: false,
          isSuccess: true,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_USERS_FAILURE,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_USERS_FAILURE,
          isLoading: false,
          isSuccess: false,
          isRegistered: false,
          message: errorMessage,
        });
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Sorry, something went wrong';
        dispatch({
          type: UPDATE_USERS_FAILURE,
          isLoading: false,
          isSuccess: false,
          // message: e.response,
        });
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        toastrError('Sorry, something went wrong');
      }
    }
  };
export const deleteStaff = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USERS_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('delete', userConstants.DELETE_STAFF(id));
    if (response.data.status) {
      dispatch({
        type: DELETE_USERS_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: DELETE_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: DELETE_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: DELETE_USERS_FAILURE,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
