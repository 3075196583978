import { useCallback, useEffect } from 'react';
// @mui
import { Grid, Container, Typography, Stack } from '@mui/material';
import useIsMountedRef from 'use-is-mounted-ref';
import { useDispatch, useSelector } from 'react-redux';
// sections
import { AppWidgetSummary } from '../../../sections/@dashboard/app';
import { Page } from '../../../components';
import { getUserDashboardData } from '../store/dashboardActions';
import { RecentLoans } from '../components';

// ----------------------------------------------------------------------

export default function UserDashboard() {
  const dispatch = useDispatch();
  const { userDashboardData } = useSelector((state) => state.dashboardReducer);

  const isMountedRef = useIsMountedRef();

  const getStatistics = useCallback(async () => {
    if (isMountedRef.current) {
      dispatch(getUserDashboardData());
    }
  }, [isMountedRef, dispatch]);

  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Dashboard
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total"
                total={userDashboardData?.records_count}
                color="success"
                icon={'material-symbols:person-book'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="In Progress"
                total={userDashboardData?.in_progress}
                color="info"
                icon={'uil:money-insert'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="Pending Acceptance IN"
                total={userDashboardData?.pending_acceptance}
                color="warning"
                icon={'ph:drop-half-bottom-fill'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ textDecoration: 'none' }}>
              <AppWidgetSummary
                title="Accepted OUT"
                total={userDashboardData?.accepted_records}
                color="error"
                icon={'ic:outline-watch-later'}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Stack>
                <RecentLoans recentRecords={userDashboardData?.recent_records} />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
