export const GET_ASSETS_REQUEST = 'ASSETS/GET_ASSETS_REQUEST';
export const GET_ASSETS_SUCCESS = 'ASSETS/GET_ASSETS_SUCCESS';
export const GET_ASSETS_ERROR = 'ASSETS/GET_ASSETS_ERROR';
export const GET_AUDIT_ASSETS_REQUEST = 'ASSETS/GET_AUDIT_ASSETS_REQUEST';
export const GET_AUDIT_ASSETS_SUCCESS = 'ASSETS/GET_AUDIT_ASSETS_SUCCESS';
export const GET_AUDIT_ASSETS_ERROR = 'ASSETS/GET_AUDIT_ASSETS_ERROR';
export const GET_ASSET_TRANSFERS_REQUEST = 'ASSETS/GET_ASSET_TRANSFERS_REQUEST';
export const GET_ASSET_TRANSFERS_SUCCESS = 'ASSETS/GET_ASSET_TRANSFERS_SUCCESS';
export const GET_ASSET_TRANSFERS_ERROR = 'ASSETS/GET_ASSET_TRANSFERS_ERROR';
export const GET_ASSET_TRANSFER_ITEMS_REQUEST = 'ASSETS/GET_ASSET_TRANSFER_ITEMS_REQUEST';
export const GET_ASSET_TRANSFER_ITEMS_SUCCESS = 'ASSETS/GET_ASSET_TRANSFER_ITEMS_SUCCESS';
export const GET_ASSET_TRANSFER_ITEMS_ERROR = 'ASSETS/GET_ASSET_TRANSFER_ITEMS_ERROR';
export const GET_ASSET_REQUEST = 'ASSETS/GET_ASSET_REQUEST';
export const GET_ASSET_SUCCESS = 'ASSETS/GET_ASSET_SUCCESS';
export const GET_ASSET_ERROR = 'ASSETS/GET_ASSET_ERROR';
export const TRANSFER_ASSET_REQUEST = 'ASSETS/TRANSFER_ASSET_REQUEST';
export const TRANSFER_ASSET_SUCCESS = 'ASSETS/TRANSFER_ASSET_SUCCESS';
export const TRANSFER_ASSET_ERROR = 'ASSETS/TRANSFER_ASSET_ERROR';
export const CREATE_ASSET_REQUEST = 'ASSETS/CREATE_ASSET_REQUEST';
export const CREATE_ASSET_SUCCESS = 'ASSETS/CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_ERROR = 'ASSETS/CREATE_ASSET_ERROR';
export const UPLOAD_ASSET_REQUEST = 'ASSETS/UPLOAD_ASSET_REQUEST';
export const UPLOAD_ASSET_SUCCESS = 'ASSETS/UPLOAD_ASSET_SUCCESS';
export const UPLOAD_ASSET_ERROR = 'ASSETS/UPLOAD_ASSET_ERROR';
export const UPDATE_TRANSFER_STATUS_REQUEST = 'ASSETS/UPDATE_TRANSFER_STATUS_REQUEST';
export const UPDATE_TRANSFER_STATUS_SUCCESS = 'ASSETS/UPDATE_TRANSFER_STATUS_SUCCESS';
export const UPDATE_TRANSFER_STATUS_ERROR = 'ASSETS/UPDATE_TRANSFER_STATUS_ERROR';
export const UPDATE_ASSET_REQUEST = 'ASSETS/UPDATE_ASSET_REQUEST';
export const UPDATE_ASSET_SUCCESS = 'ASSETS/UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_ERROR = 'ASSETS/UPDATE_ASSET_ERROR';
export const DELETE_ASSET_REQUEST = 'ASSETS/DELETE_ASSET_REQUEST';
export const DELETE_ASSET_SUCCESS = 'ASSETS/DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_ERROR = 'ASSETS/DELETE_ASSET_ERROR';
