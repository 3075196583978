import { Outlet, Navigate } from 'react-router-dom';
import T from 'prop-types';
import authService from './authService';


AuthGuard.propTypes = {
  children:T.any
}



export default function AuthGuard({ children }) {
  if (!authService.isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children || <Outlet />;
}
