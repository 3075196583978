import { useState, useEffect } from 'react';
// @mui
import { Button, Container, Grid, Box, Dialog, DialogActions } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Page } from '../../../components';

import { MaintenanceTabs } from '.';
import { getMaintenanceRecord } from '../store/maintenanceActions';

export default function MaintenanceDetail() {
  //
  const { id } = useParams();
  const dispatch = useDispatch();

  const [openInvoicePdf, setOpenInvoicePdf] = useState(false);
  const [openJobCardPdf, setOpenJobCardPdf] = useState(false);

  useEffect(() => {
    dispatch(getMaintenanceRecord(id));
  }, [dispatch, id]);

  return (
    <>
      <Page title="Maintenance Detail">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MaintenanceTabs />
            </Grid>
          </Grid>
        </Container>
      </Page>
      <Dialog fullScreen open={openJobCardPdf}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Button size="small" variant="contained" color="primary" onClick={() => setOpenJobCardPdf(false)}>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog fullScreen open={openInvoicePdf}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Button size="small" variant="contained" color="primary" onClick={() => setOpenInvoicePdf(false)}>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
