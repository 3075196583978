import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
  CardHeader,
  CardContent,
  Paper,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { getHandovers } from '../store/handoverActions';
import { fDate, fDateTime } from '../../../utils/formatTime';
import { getProfile } from '../../../shared/auth/authService';
import { Accept, Decline, NewHandover } from '../components';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'branch', label: 'Branch', alignRight: false },
  { id: 'requestedBy', label: 'Requested By', alignRight: false },
  { id: 'handover Date', label: 'Expected Handover Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------
const storedUser = JSON.parse(localStorage.getItem('profile'));

const branchRoles = ['MANAGER', 'BRANCH_MANAGER'];

const isManager = storedUser?.role && branchRoles.includes(storedUser.role);

export default function Handovers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, handovers, pagination } = useSelector((state) => state.handoverReducer);
  //

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [openHandover, setOpenHandover] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getHandovers(payload));
    }, 2000); // 2000 mil
  };
  const handleOpenHandover = () => {
    setOpenHandover(!openHandover);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getHandovers(payload));
    }, 2000); // 2000 mil
  };

  const handleCloseAccept = () => {
    setOpenAccept(!openAccept);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getHandovers(payload));
    }, 2000); // 2000 mil
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const payload = {
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getHandovers(payload));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    const profile = getProfile();
    if (profile) {
      setUser(profile);
    }
  }, []);

  return (
    <>
      <Page title="Handovers">
        {openHandover && <NewHandover open={openHandover} handleClose={handleOpenHandover} />}
        {openAccept && <Accept open={openAccept} handleClose={handleCloseAccept} handoverId={selectedRow?.id} />}
        {open && <Decline open={open} handleClose={handleClose} handoverId={selectedRow?.id} />}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                {isManager && (
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" sx={{ float: 'right' }} spacing={0.5}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => setOpenHandover(true)}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        New Handover
                      </Button>
                    </Stack>
                  </Box>
                )}
                <CardHeader title={'Handovers'} subheader={''} />
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center' }}>
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#2065d1"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible
                          />
                        </Grid>
                      ) : (
                        <Table size="small">
                          <UserListHead headLabel={TABLE_HEAD} rowCount={handovers?.length} />
                          <TableBody>
                            {handovers?.length > 0 ? (
                              handovers.map((record) => (
                                <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                  <TableCell align="left">{fDateTime(record?.date_created)}</TableCell>
                                  <TableCell align="left">{record?.branch?.name}</TableCell>
                                  <TableCell align="left">{record?.staff?.user?.display_name}</TableCell>
                                  <TableCell align="left">{fDate(record?.expected_handover_date)}</TableCell>

                                  <TableCell align="left">
                                    <Label
                                      color={
                                        (record?.status === 'PENDING' && 'warning') ||
                                        (record?.status === 'REJECTED' && 'error') ||
                                        'success'
                                      }
                                    >
                                      {sentenceCase(record?.status)}
                                    </Label>
                                  </TableCell>
                                  <TableCell>
                                    <Stack spacing={2} direction="row">
                                      {user?.role === 'ADMIN' && record?.status === 'PENDING' && (
                                        <>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                              setSelectedRow(record);
                                              setOpenAccept(true);
                                            }}
                                          >
                                            Approve
                                          </Button>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={() => {
                                              setSelectedRow(record);
                                              setOpen(true);
                                            }}
                                          >
                                            Reject
                                          </Button>
                                        </>
                                      )}

                                      {/* <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => navigate(`/dashboard/handovers/${record?.id}`)}
                                      >
                                        View
                                      </Button> */}
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <>Handover requests will appear here</>
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={pagination?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
