export const GET_MAINTENANCE_RECORDS_REQUEST = 'MAINTENANCE/GET_MAINTENANCE_RECORDS_REQUEST';
export const GET_MAINTENANCE_RECORDS_SUCCESS = 'MAINTENANCE/GET_MAINTENANCE_RECORDS_SUCCESS';
export const GET_MAINTENANCE_RECORDS_ERROR = 'MAINTENANCE/GET_MAINTENANCE_RECORDS_ERROR';
export const GET_MAINTENANCE_RECORD_REQUEST = 'MAINTENANCE/GET_MAINTENANCE_RECORD_REQUEST';
export const GET_MAINTENANCE_RECORD_SUCCESS = 'MAINTENANCE/GET_MAINTENANCE_RECORD_SUCCESS';
export const GET_MAINTENANCE_RECORD_ERROR = 'MAINTENANCE/GET_MAINTENANCE_RECORD_ERROR';
export const GET_REPAIR_DETAILS_REQUEST = 'MAINTENANCE/GET_REPAIR_DETAILS_REQUEST';
export const GET_REPAIR_DETAILS_SUCCESS = 'MAINTENANCE/GET_REPAIR_DETAILS_SUCCESS';
export const GET_REPAIR_DETAILS_ERROR = 'MAINTENANCE/GET_REPAIR_DETAILS_ERROR';
export const CREATE_REPAIR_RECORD_REQUEST = 'MAINTENANCE/CREATE_REPAIR_RECORD_REQUEST';
export const CREATE_REPAIR_RECORD_SUCCESS = 'MAINTENANCE/CREATE_REPAIR_RECORD_SUCCESS';
export const CREATE_REPAIR_RECORD_ERROR = 'MAINTENANCE/CREATE_REPAIR_RECORD_ERROR';
export const CREATE_MAINTENANCE_RECORD_REQUEST = 'MAINTENANCE/CREATE_MAINTENANCE_RECORD_REQUEST';
export const CREATE_MAINTENANCE_RECORD_SUCCESS = 'MAINTENANCE/CREATE_MAINTENANCE_RECORD_SUCCESS';
export const CREATE_MAINTENANCE_RECORD_ERROR = 'MAINTENANCE/CREATE_MAINTENANCE_RECORD_ERROR';
export const UPLOAD_ASSET_REQUEST = 'MAINTENANCE/UPLOAD_ASSET_REQUEST';
export const UPLOAD_ASSET_SUCCESS = 'MAINTENANCE/UPLOAD_ASSET_SUCCESS';
export const UPLOAD_ASSET_ERROR = 'MAINTENANCE/UPLOAD_ASSET_ERROR';
export const UPDATE_MAINTENANCE_RECORD_REQUEST = 'MAINTENANCE/UPDATE_MAINTENANCE_RECORD_REQUEST';
export const UPDATE_MAINTENANCE_RECORD_SUCCESS = 'MAINTENANCE/UPDATE_MAINTENANCE_RECORD_SUCCESS';
export const UPDATE_MAINTENANCE_RECORD_ERROR = 'MAINTENANCE/UPDATE_MAINTENANCE_RECORD_ERROR';
export const DELETE_ASSET_REQUEST = 'MAINTENANCE/DELETE_ASSET_REQUEST';
export const DELETE_ASSET_SUCCESS = 'MAINTENANCE/DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_ERROR = 'MAINTENANCE/DELETE_ASSET_ERROR';
