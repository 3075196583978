// component
import SettingsIcon from '@mui/icons-material/Settings';
import MonitorIcon from '@mui/icons-material/Monitor';
import CategoryIcon from '@mui/icons-material/Category';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import HandymanIcon from '@mui/icons-material/Handyman';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SellIcon from '@mui/icons-material/Sell';
import BadgeIcon from '@mui/icons-material/Badge';
import PlaceIcon from '@mui/icons-material/Place';
import ReceiptIcon from '@mui/icons-material/Receipt';

const storedUser = JSON.parse(localStorage.getItem('profile'));
const branchRoles = ['MANAGER', 'BRANCH_MANAGER'];

const isManager = storedUser?.role && branchRoles.includes(storedUser.role);

const navConfig = (isManager && [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />,
  },
  {
    title: 'Assets',
    path: '/dashboard/assets',
    icon: <MonitorIcon />,
  },
  {
    title: 'Transfers',
    path: '/dashboard/transfers',
    icon: <UnfoldMoreIcon />,
  },
  {
    title: 'Maintenance',
    path: '/maintenance/app',
    icon: <HandymanIcon />,
  },
  {
    title: 'Handovers',
    path: '/dashboard/handovers',
    icon: <HandshakeIcon />,
  },
  {
    title: 'Disposals',
    path: '/dashboard/disposals',
    icon: <SellIcon />,
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: <SettingsIcon />,
  },
]) ||
  (storedUser?.role === 'SERVICE_PROVIDER' && [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: <DashboardIcon />,
    },
    {
      title: 'Maintenance',
      path: '/dashboard/maintenance',
      icon: <HandymanIcon />,
    },
    {
      title: 'Settings',
      path: '/dashboard/settings',
      icon: <SettingsIcon />,
    },
  ]) || [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: <DashboardIcon />,
    },
    {
      title: 'Assets',
      path: '/dashboard/assets',
      icon: <MonitorIcon />,
    },
    {
      title: 'Transfers',
      path: '/dashboard/transfers',
      icon: <UnfoldMoreIcon />,
    },
    {
      title: 'Categories',
      path: '/dashboard/categories',
      icon: <CategoryIcon />,
    },
    {
      title: 'Branches',
      path: '/dashboard/branches',
      icon: <PlaceIcon />,
    },
    {
      title: 'Maintenance',
      path: '/maintenance/app',
      icon: <HandymanIcon />,
    },
    {
      title: 'Invoices',
      path: '/dashboard/invoices',
      icon: <ReceiptIcon />,
    },
    {
      title: 'Audit',
      path: '/dashboard/audit',
      icon: <NoteAltIcon />,
    },
    {
      title: 'Reconcile Audit',
      path: '/dashboard/reconcileaudit',
      icon: <VerifiedIcon />,
    },
    {
      title: 'Handovers',
      path: '/dashboard/handovers',
      icon: <HandshakeIcon />,
    },
    {
      title: 'Disposals',
      path: '/dashboard/disposals',
      icon: <SellIcon />,
    },
    {
      title: 'Reports',
      path: '/dashboard/reports',
      icon: <AnalyticsIcon />,
    },
    {
      title: 'Users',
      path: '/dashboard/users',
      icon: <BadgeIcon />,
    },
    {
      title: 'Settings',
      path: '/dashboard/settings',
      icon: <SettingsIcon />,
    },
  ];

export default navConfig;
