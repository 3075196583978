import { Card, CardHeader, CardContent, Stack, TextField, CardActions, Typography, Modal } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { customerSchema } from '../schemas/customerSchemas';
// import { updateCustomer } from '../store/branchActions';

CustomerEditForm.propTypes = {
  showCustomerEditModal: PropTypes.bool,
  showCustomerEditModalHandler: PropTypes.func,
  customer: PropTypes.object,
};

export default function CustomerEditForm({ customer, showCustomerEditModal, showCustomerEditModalHandler }) {
  const dispatch = useDispatch();

  const { isLoading, isSuccess } = useSelector((state) => state.customerReducer);

  useEffect(() => {
    if (isSuccess) {
      showCustomerEditModalHandler();
    }
  }, [isSuccess, showCustomerEditModalHandler]);

  const onSubmit = async (values) => {
    const payload = {
      id: customer.id,
      payload: values,
    };
    // dispatch(updateCustomer(payload));
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      first_name: customer.first_name,
      last_name: customer.last_name,
      mobile_number: customer.mobile_number,
      email: customer.email,
    },
    validationSchema: customerSchema,
    onSubmit,
    isSubmitting: isLoading,
  });

  return (
    <Modal
      open={showCustomerEditModal}
      onClose={showCustomerEditModalHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '340px', md: '600px' },
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          p: { xs: '0px', md: '2px' },
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleSubmit}>
          <CardHeader title="Update Customer" />
          <CardContent>
            <Stack spacing={2}>
              <TextField
                name="first_name"
                type="text"
                label="First Name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.first_name && Boolean(errors.first_name)}
              />
              {touched.first_name && Boolean(errors.first_name) && (
                <Typography color="error" variant="body2">
                  {errors.first_name}
                </Typography>
              )}
              <TextField
                name="last_name"
                type="text"
                label="Last Name"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.last_name && Boolean(errors.last_name)}
              />
              {touched.last_name && Boolean(errors.last_name) && (
                <Typography color="error" variant="body2">
                  {errors.last_name}
                </Typography>
              )}
              <TextField
                name="mobile_number"
                type="text"
                label="Mobile Number"
                value={values.mobile_number}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.mobile_number && Boolean(errors.mobile_number)}
              />
              {touched.mobile_number && Boolean(errors.mobile_number) && (
                <Typography color="error" variant="body2">
                  {errors.mobile_number}
                </Typography>
              )}
              <TextField
                name="email"
                type="text"
                label="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
              />
              {touched.email && Boolean(errors.email) && (
                <Typography color="error" variant="body2">
                  {errors.email}
                </Typography>
              )}
            </Stack>
          </CardContent>
          <CardActions>
            <LoadingButton color="error" type="submit" variant="contained" onClick={showCustomerEditModalHandler}>
              Cancel
            </LoadingButton>
            <LoadingButton loading={isLoading} disabled={isSubmitting} type="submit" variant="contained">
              Submit
            </LoadingButton>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}
