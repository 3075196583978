
export const GET_AUDIT_ASSETS_REQUEST = 'AUDIT/GET_AUDIT_ASSETS_REQUEST';
export const GET_AUDIT_ASSETS_SUCCESS = 'AUDIT/GET_AUDIT_ASSETS_SUCCESS';
export const GET_AUDIT_ASSETS_ERROR = 'AUDIT/GET_AUDIT_ASSETS_ERROR';
export const GET_ASSET_AUDITS_REQUEST = 'AUDIT/GET_ASSET_AUDITS_REQUEST';
export const GET_ASSET_AUDITS_SUCCESS = 'AUDIT/GET_ASSET_AUDITS_SUCCESS';
export const GET_ASSET_AUDITS_ERROR = 'AUDIT/GET_ASSET_AUDITS_ERROR';
export const CREATE_AUDIT_REQUEST = 'AUDITS/CREATE_AUDIT_REQUEST';
export const CREATE_AUDIT_SUCCESS = 'AUDITS/CREATE_AUDIT_SUCCESS';
export const CREATE_AUDIT_ERROR = 'AUDITS/CREATE_AUDIT_ERROR';
export const RECONCILE_AUDIT_REQUEST = 'AUDITS/RECONCILE_AUDIT_REQUEST';
export const RECONCILE_AUDIT_SUCCESS = 'AUDITS/RECONCILE_AUDIT_SUCCESS';
export const RECONCILE_AUDIT_ERROR = 'AUDITS/RECONCILE_AUDIT_ERROR';
