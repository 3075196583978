// import { useState } from 'react';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
// components
import { Page } from '../../../components';
import Iconify from '../../../components/iconify/Iconify';
import useTabs from '../../../hooks/useTabs';
import { AccountBilling, AccountChangePassword, AccountGeneral } from '../components';
import { getProfile } from '../../../shared/auth/authService';
// sections

// ----------------------------------------------------------------------

export default function UserAccount() {
  const [user, setUser] = useState({});
  const { currentTab, onChangeTab } = useTabs('profile');

  const ACCOUNT_TABS = [
    {
      value: 'profile',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <AccountGeneral />,
    },
    {
      value: 'change_password',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      component: <AccountChangePassword />,
    },
  ];

  if (user.role === 'ADMIN') {
    const subscriptionMenu = {
      value: 'subscription',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: <AccountBilling />,
    };
    if (!ACCOUNT_TABS.some((child) => child.value === subscriptionMenu.value)) {
      ACCOUNT_TABS.push(subscriptionMenu);
    }
  }

  useEffect(() => {
    const profile = getProfile();
    if (profile) {
      setUser(profile);
    }
  }, []);

  return (
    <Page title="Account Settings">
      <Container maxWidth="xl">
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
