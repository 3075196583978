import { toast } from 'react-toastify';

import {
  GET_BRANCHES_REQUEST,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_ERROR,
  GET_AUDIT_BRANCHES_REQUEST,
  GET_AUDIT_BRANCHES_SUCCESS,
  GET_AUDIT_BRANCHES_ERROR,
  // CREATE_CATEGORY_REQUEST,
  // CREATE_CATEGORY_SUCCESS,
  // CREATE_CATEGORY_ERROR,
  // UPDATE_CATEGORY_REQUEST,
  // UPDATE_CATEGORY_SUCCESS,
  // UPDATE_CATEGORY_ERROR,
  // DELETE_CATEGORY_REQUEST,
  // DELETE_CATEGORY_SUCCESS,
  // DELETE_CATEGORY_ERROR,
} from './branchActionTypes';
import call from '../../../core/services/http';
import branchConstants from './branchConstants';

// const toastrSuccess = (msg) => {
//   toast.success(msg);
// };
const toastrError = (msg) => {
  toast.error(msg);
};

export const getBranches = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BRANCHES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', branchConstants.GET_BRANCHES(payload));
    if (response.data.status) {
      dispatch({
        type: GET_BRANCHES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_BRANCHES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_BRANCHES_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_BRANCHES_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getAuditBranches = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_AUDIT_BRANCHES_REQUEST,
      isLoading: true,
    });
    const response = await call('get', branchConstants.GET_AUDIT_BRANCHES);
    if (response.data.status) {
      dispatch({
        type: GET_AUDIT_BRANCHES_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_AUDIT_BRANCHES_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_BRANCHES_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_BRANCHES_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

// export const createCustomer = (payload) => async (dispatch) => {
//   try {
//     dispatch({
//       type: CREATE_CATEGORY_REQUEST,
//       isLoading: true,
//       isSuccess: false,
//     });
//     const response = await call(categoryConstants.CREATE_CUSTOMER, payload, 'POST');
//     if (response.data.status) {
//       dispatch({
//         type: CREATE_CATEGORY_SUCCESS,
//         isLoading: false,
//         isSuccess: true,
//       });
//       toastrSuccess(response.data.message);
//     } else {
//       toastrError(response.data.message);
//       dispatch({
//         type: CREATE_CATEGORY_ERROR,
//         isLoading: false,
//         isSuccess: false,
//         message: response.data.message,
//       });
//     }
//   } catch (e) {
//     toastrError('Sorry, something went wrong');
//   }
// };

// export const updateCustomer =
//   ({ id, payload }) =>
//   async (dispatch) => {
//     try {
//       dispatch({
//         type: UPDATE_CATEGORY_REQUEST,
//         isLoading: true,
//         isSuccess: false,
//       });
//       const response = await call(categoryConstants.UPDATE_CUSTOMER(id), payload, 'PATCH');
//       if (response.data.status) {
//         dispatch({
//           type: UPDATE_CATEGORY_SUCCESS,
//           isLoading: false,
//           isSuccess: true,
//           message: response.data.message,
//         });
//         toastrSuccess(response.data.message);
//       } else {
//         toastrError(response.data.message);
//         dispatch({
//           type: UPDATE_CATEGORY_ERROR,
//           isLoading: false,
//           isSuccess: false,
//           message: response.data.message,
//         });
//       }
//     } catch (e) {
//       toastrError('Sorry, something went wrong');
//     }
//   };

// export const deleteCustomer = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: DELETE_CATEGORY_REQUEST,
//       isLoading: true,
//       isSuccess: false,
//     });
//     const response = await call(categoryConstants.DELETE_CUSTOMER(id), {}, 'DELETE');
//     if (response.data.status) {
//       dispatch({
//         type: DELETE_CATEGORY_SUCCESS,
//         isLoading: false,
//         isSuccess: true,
//       });
//       toastrSuccess(response.data.message);
//     } else {
//       toastrError(response.data.message);
//       dispatch({
//         type: DELETE_CATEGORY_ERROR,
//         isLoading: false,
//         isSuccess: false,
//         message: response.data.message,
//       });
//     }
//   } catch (e) {
//     toastrError('Sorry, something went wrong');
//   }
// };
