import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { Stack, TextField, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

import { fDateYMD } from '../../../utils/formatTime';
import { getBranchManagers } from '../../users/store/userActions';
import { createHandoverRecord } from '../store/handoverActions';

NewHandover.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function NewHandover({ open, handleClose }) {
  const dispatch = useDispatch();
  const { auditUsers } = useSelector((state) => state.userReducer);
  const { isSuccess } = useSelector((state) => state.handoverReducer);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate();

  const NewMaintenanceRecordSchema = Yup.object().shape({
    newManager: Yup.string().required('New Manager is required'),
    expectedHandoverDate: Yup.date().required('Date is required'),
    currentIssues: Yup.string(),
    recommendations: Yup.string(),
  });

  const defaultValues = {
    newManager: '',
    expectedHandoverDate: selectedDate || '',
    currentIssues: '',
    recommendations: '',
  };

  const handleDateChange = (newValue) => {
    setValue('expectedHandoverDate', fDateYMD(newValue));
    setSelectedDate(newValue);
  };

  const methods = useForm({
    resolver: yupResolver(NewMaintenanceRecordSchema),
    defaultValues,
  });

  const {
    reset,
    // watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  const onSubmit = async (data) => {
    const payload = {
      new_manager_id: data.newManager,
      expected_handover_date: fDateYMD(selectedDate),
      current_issues: data.currentIssues,
      recommendations: data.recommendations,
    };
    dispatch(createHandoverRecord(payload));
    handleClose();
  };
  useEffect(() => {
    dispatch(getBranchManagers({}));
  }, [dispatch]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     reset();
  //     handleClose();
  //     navigate('/dashboard/handovers');
  //   }
  // }, [reset, isSuccess, navigate, handleClose]);
  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Apply Handover</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <RHFSelect
              fullWidth
              name="newManager"
              label="Handover To"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {auditUsers?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.user.display_name}
                </MenuItem>
              ))}
            </RHFSelect>
            <DatePicker
              label="Expected Handover Date"
              value={selectedDate}
              minDate={new Date()}
              format="yyyy-MM-dd"
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <RHFTextField name="currentIssues" label="Current Issues" />
            <RHFTextField name="recommendations" label="Recommendations" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
