import {
  // GET_CATEGORIES_REQUEST,
  // GET_CATEGORIES_SUCCESS,
  // GET_CATEGORIES_ERROR,
  GET_AUDIT_SUPPLIERS_REQUEST,
  GET_AUDIT_SUPPLIERS_SUCCESS,
  GET_AUDIT_SUPPLIERS_ERROR,
  // CREATE_CATEGORY_REQUEST,
  // CREATE_CATEGORY_SUCCESS,
  // CREATE_CATEGORY_ERROR,
  // UPDATE_CATEGORY_REQUEST,
  // UPDATE_CATEGORY_SUCCESS,
  // UPDATE_CATEGORY_ERROR,
  // DELETE_CATEGORY_REQUEST,
  // DELETE_CATEGORY_SUCCESS,
  // DELETE_CATEGORY_ERROR,
} from './supplierActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  payload: {},
  message: '',
  suppliers: [],
  auditSuppliers: [],
  pagination: {},
};

export default function categoryReducer(state = initialState, action) {
  const { type, payload, isLoading, message } = action;

  switch (type) {
    // case GET_CATEGORIES_REQUEST:
    //   return {
    //     isLoading,
    //   };
    // case GET_CATEGORIES_SUCCESS:
    //   return {
    //     isLoading,
    //     categories: payload.data,
    //     pagination: payload,
    //   };
    // case GET_CATEGORIES_ERROR:
    //   return {
    //     isLoading,
    //     message,
    //   };
    case GET_AUDIT_SUPPLIERS_REQUEST:
      return {
        isLoading,
      };
    case GET_AUDIT_SUPPLIERS_SUCCESS:
      return {
        isLoading,
        auditSuppliers: payload.data,
      };
    case GET_AUDIT_SUPPLIERS_ERROR:
      return {
        isLoading,
        message,
      };
    // case CREATE_CATEGORY_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case CREATE_CATEGORY_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case CREATE_CATEGORY_ERROR:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case UPDATE_CATEGORY_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case UPDATE_CATEGORY_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case UPDATE_CATEGORY_ERROR:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case DELETE_CATEGORY_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case DELETE_CATEGORY_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case DELETE_CATEGORY_ERROR:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    default: {
      return state;
    }
  }
}
