// /* eslint-disable */
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import PeopleIcon from '@mui/icons-material/People';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  CardHeader,
  CardContent,
  Paper,
} from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { fNumber } from '../../../utils/formatNumber';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { getUsers } from '../store/userActions';
import { ConfirmCompleteDialog, EditUser, UsersFilter } from '../components';
import call from '../../../core/services/http';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'display_name', label: 'Name', alignRight: false },
  { id: 'mobile_number', label: 'Mobile#', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'location', label: 'Branch', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};

const storedUser = JSON.parse(localStorage.getItem('profile'));
const adminRoles = ['ADMIN'];

const isAdmin = storedUser?.role && adminRoles.includes(storedUser.role);
export default function Users() {
  const dispatch = useDispatch();
  const { isLoading, users, pagination } = useSelector((state) => state.userReducer);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isExporting, setIsExporting] = useState(false);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(!open);
  };

  const handleCloseEdit = () => {
    setOpenEdit(!openEdit);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getUsers(payload));
    }, 2000);
  };
  const handleCloseDelete = () => {
    setOpenDelete(!openDelete);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getUsers(payload));
    }, 2000);
  };
  const downloadPDF = async () => {
    try {
      setIsExporting(true);
      const response = await call('get', `users/staff/export/`);
      if (response.status) {
        setIsExporting(false);

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const name = `${new Date()}users_list.pdf`;
        link.download = name;
        link.click();

        // Clean up the temporary objects
        window.URL.revokeObjectURL(link);
        link.remove();
      } else {
        toastrError(response.data.message);
        setIsExporting(false);
      }
    } catch (error) {
      console.log('====================================');
      console.log(error.message);
      console.log('====================================');
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred while generating report';
        setIsExporting(false);
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred while generating report';
        setIsExporting(false);
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        setIsExporting(false);
        toastrError('An error occurred while generating report');
      }
    }

    setIsExporting(false);
  };
  useEffect(() => {
    const payload = {
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getUsers(payload));
  }, [dispatch, page, rowsPerPage]);

  return (
    <>
      <Page title="Users">
        {open && <UsersFilter open={setOpen} handleClose={handleClose} />}
        {openEdit && <EditUser open={openEdit} handleClose={handleCloseEdit} currentStaff={selectedRow} />}
        {openDelete && (
          <ConfirmCompleteDialog open={openDelete} handleClose={handleCloseDelete} staffId={selectedRow?.id} />
        )}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Paper elevation={3} sx={{ py: 2, textAlign: 'center' }}>
                <Box sx={{ mb: 0.5 }}>
                  <PeopleIcon />
                </Box>
                <Typography variant="h6">{fNumber(pagination?.count)}</Typography>
                <Typography variant="body2">Users</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" sx={{ float: 'right' }} spacing={0.5}>
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={() => downloadPDF()}
                      // startIcon={<FilterListOutlinedIcon />}
                    >
                      Download PDF
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      color="warning"
                      onClick={handleClose}
                      startIcon={<FilterListOutlinedIcon />}
                    >
                      Filter Users
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      component={RouterLink}
                      to={'/dashboard/users/new'}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      New User
                    </Button>
                  </Stack>
                </Box>
                <CardHeader title={'Users'} subheader={'Quickmart users'} />
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center' }}>
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#2065d1"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                          />
                        </Grid>
                      ) : (
                        <Table size="medium">
                          <UserListHead headLabel={TABLE_HEAD} rowCount={users?.length} />
                          <TableBody>
                            {users?.length > 0 ? (
                              users?.map((user) => (
                                <TableRow
                                  hover
                                  key={user?.id}
                                  tabIndex={-1}
                                  role="checkbox"
                                  // selected={users}
                                >
                                  <TableCell component="th" scope="row" padding="checkbox" sx={{ pl: 2 }}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Typography variant="subtitle2" noWrap>
                                        {user?.user?.display_name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>

                                  <TableCell align="left">{user?.mobile_number}</TableCell>
                                  <TableCell align="left">{user?.email}</TableCell>
                                  <TableCell align="left">{sentenceCase(user?.branch?.name)}</TableCell>
                                  <TableCell align="left">{user?.user?.role_display}</TableCell>
                                  <TableCell align="left">
                                    <Label color={(user?.user?.status === 'INACTIVE' && 'error') || 'success'}>
                                      {sentenceCase(user?.user?.status)}
                                    </Label>
                                  </TableCell>
                                  <TableCell>
                                    <Stack spacing={2} direction="row">
                                      {isAdmin && (
                                        <>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                              setSelectedRow(user);
                                              setOpenEdit(true);
                                            }}
                                          >
                                            Edit
                                          </Button>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={() => {
                                              setSelectedRow(user);
                                              setOpenDelete(true);
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        </>
                                      )}
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <>No users found</>
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={pagination?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
