import * as Yup from 'yup';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Container, CardContent, CardHeader, MenuItem } from '@mui/material';

import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { Page } from '../../../components';
import { getAuditBranches } from '../../branches/store/branchActions';
import { createStaff } from '../store/userActions';

// ----------------------------------------------------------------------

const ROLES = [
  {
    id: 1,
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    id: 1,
    label: 'Assistant Manager',
    value: 'ASST_MANAGER',
  },
  {
    id: 2,
    label: 'Auditor',
    value: 'AUDITOR',
  },
  { id: 8, label: 'Report Viewer', value: 'REPORT_VIEWER' },
  {
    id: 5,
    label: 'Data Entry',
    value: 'DATA_ENTRY',
  },
  {
    id: 2,
    label: 'Manager',
    value: 'MANAGER',
  },
  {
    id: 3,
    label: 'Regional Manager',
    value: 'REGIONAL_MANAGER',
  },

  {
    id: 6,
    label: 'Service Provider',
    value: 'SERVICE_PROVIDER',
  },  
  {
    id: 6,
    label: 'Lose Control',
    value: 'LOSE_CONTROL',
  },
];

export default function NewUserForm() {
  const dispatch = useDispatch();
  const { auditBranches } = useSelector((state) => state.branchReducer);
  const { isSuccess } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const NewUserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    idNumber: Yup.string().required('ID Number is required'),
    staffNumber: Yup.string().required('Staff Number is required'),
    department: Yup.string().required('Department is required'),
    branch: Yup.string().required('Branch is required'),
    role: Yup.string().required('Role Number is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    idNumber: '',
    staffNumber: '',
    department: '',
    branch: '',
    role: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    // watch,
    // control,
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  useEffect(() => {
    dispatch(getAuditBranches({}));
  }, [dispatch]);

  const onSubmit = async (data) => {
    try {
      const payload = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        id_number: data.idNumber,
        mobile_number: data.phoneNumber,
        staff_number: data.staffNumber,
        department: data.department,
        role: data.role,
        location_id: data.branch,
      };
      dispatch(createStaff(payload));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      reset();
      navigate('/dashboard/users');
    }
  }, [reset, isSuccess, navigate]);
  return (
    <Page title="Users">
      <Container maxWidth="xl">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 3 }}>
                <CardHeader title={'Create user'} subheader={''} />
                <CardContent>
                  <Box
                    sx={{
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField name="firstName" label="First Name" />
                    <RHFTextField name="lastName" label="Last Name" />
                    <RHFTextField type="email" name="email" label="Email Address" />
                    <RHFTextField name="phoneNumber" label="Phone Number" />
                    <RHFTextField name="idNumber" label="ID Number" />
                    <RHFTextField name="staffNumber" label="Staff Number" />
                    <RHFTextField name="department" label="Department" />
                    <RHFSelect
                      name="branch"
                      label="Branch"
                      placeholder="Branch"
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                    >
                      {auditBranches?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                    <RHFSelect
                      fullWidth
                      name="role"
                      label="Role"
                      placeholder="Role"
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                    >
                      {ROLES.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Box>

                  <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      Create User
                    </LoadingButton>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
    </Page>
  );
}
