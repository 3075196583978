import { useState, useEffect } from 'react';
import MonitorIcon from '@mui/icons-material/Monitor';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableBody,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  CardHeader,
  CardContent,
  Paper,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { TableNoData, UserTableHead, UserTableRow } from '../../../sections/@dashboard/user';

import { Page, TableSkeleton } from '../../../components';
import { fNumber } from '../../../utils/formatNumber';
import { getAssets, uploadAssets } from '../store/assetActions';
import { fSize } from '../../../utils/formatFileSize';
import { AssetFilter, NewTransfer } from '../components';
import { getComparator } from '../../../hooks/useTable';
import { applyFilter } from '../../../sections/@dashboard/user/utils';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const storedUser = JSON.parse(localStorage.getItem('profile'));
const branchRoles = ['MANAGER', 'ASST_MANAGER'];

const isManager = storedUser?.role && branchRoles.includes(storedUser.role);

export default function FixedAssets() {
  const dispatch = useDispatch();
  const { isLoading, isUploading, fixedAssets, pagination } = useSelector((state) => state.assetReducer);
  //

  const defaultValues = {
    uploadedFile: '',
  };

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [openTransfer, setOpeTransfer] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(defaultValues);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [filterName, setFilterName] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = fixedAssets?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleSelectClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const dataFiltered = applyFilter({
    inputData: fixedAssets,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const handleFileUpload = async () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append('assets_file', uploadedFile);
      dispatch(uploadAssets(formData));
      setOpen(false);
      setUploadedFile(null);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(!openFilter);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleTransfer = () => {
    setOpeTransfer(false);
    setSelected([]);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getAssets(payload));
    }, 2000);
  };

  const fetchData = async (dispatch, page, rowsPerPage) => {
    try {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      await dispatch(getAssets(payload));
    } catch (error) {
      // Handle errors
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(dispatch, page, rowsPerPage);
  }, [dispatch, page, rowsPerPage]);

  const denseHeight = 72;
  const isNotFound = !dataFiltered?.length && !!filterName;
  // (!dataFiltered.length && !!filterRole) ||
  // (!dataFiltered.length && !!filterStatus);

  return (
    <>
      <Page title="Assets">
        {openFilter && <AssetFilter open={openFilter} handleClose={handleCloseFilter} />}
        {openTransfer && <NewTransfer assetIds={selected} open={openTransfer} handleClose={handleTransfer} />}
        {/* Import modal starts */}
        <Dialog
          maxWidth="md"
          open={open}
          // fullWidth
          sx={{ width: 'auto' }}
          // TransitionComponent={Transition}
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          {/* <DialogTitle>Filter Loans</DialogTitle> */}
          <DialogContent dividers>
            <Box sx={{ p: 2, width: { xs: 'auto', md: 300, lg: 400 }, height: { xs: 'auto' } }}>
              <Stack alignItems="center" justifyContent="center" sx={{ color: 'rgb(145, 158, 171)' }} spacing={2}>
                <CloudUploadIcon />

                <TextField
                  name="assetFile"
                  hidden
                  type="file"
                  onChange={(event) => setUploadedFile(event.target.files[0])}
                />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="caption">File Size: {(uploadedFile && fSize(uploadedFile.size)) || ''}</Typography>
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={handleFileUpload}>
              Import
            </Button>
          </DialogActions>
        </Dialog>

        {/* Import modal ends */}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Paper elevation={3} sx={{ py: 2, textAlign: 'center' }}>
                <Box sx={{ mb: 0.5 }}>
                  <MonitorIcon />
                </Box>
                <Typography variant="h6">{fNumber(pagination?.count)}</Typography>
                <Typography variant="body2">Assets</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                    {selected?.length > 0 && (
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpeTransfer(true)}
                        endIcon={<SendOutlinedIcon />}
                      >
                        Transfer
                      </Button>
                    )}
                    <Button
                      size="small"
                      variant="contained"
                      color="success"
                      onClick={() => setOpenFilter(true)}
                      startIcon={<FilterListOutlinedIcon />}
                    >
                      Filter Assets
                    </Button>
                    {!isManager && (
                      <>
                        <Button
                          size="small"
                          variant="contained"
                          color="warning"
                          onClick={handleOpen}
                          startIcon={<CloudUploadIcon />}
                        >
                          Import
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          component={RouterLink}
                          to={'/dashboard/assets/new'}
                          startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                          New Asset
                        </Button>
                      </>
                    )}
                  </Stack>
                </Box>
                <CardHeader title={'Assets'} subheader={'Quickmart assets'} />
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center' }}>
                          {isUploading && (
                            <Stack>
                              <Typography variant="h5">Uploading assets...</Typography>
                            </Stack>
                          )}
                          <TableSkeleton sx={{ height: denseHeight }} />
                        </Grid>
                      ) : (
                        <Table size="small">
                          <UserTableHead
                            order={order}
                            orderBy={orderBy}
                            rowCount={fixedAssets?.length}
                            numSelected={selected?.length}
                            onRequestSort={handleSort}
                            onSelectAllClick={handleSelectAllClick}
                            headLabel={[
                              { id: 'name', label: 'Name', alignRight: false },
                              { id: 'asset_class', label: 'Category', alignRight: false },
                              { id: 'barcode_number', label: 'Barcode#', alignRight: false },
                              { id: 'serial_no', label: 'Serial#', alignRight: false },
                              { id: 'cost', label: 'Cost', alignRight: false },
                              { id: 'location', label: 'Branch', alignRight: false },
                              { id: 'assigned_to', label: 'AssignedTo', alignRight: false },
                              { id: 'condition', label: 'Status', alignRight: false },
                              { id: '' },
                            ]}
                          />
                          <TableBody>
                            {(dataFiltered?.length > 0 &&
                              dataFiltered?.map(
                                (row, index) =>
                                  (row && (
                                    <UserTableRow
                                      key={row.id}
                                      asset={row}
                                      selected={selected.indexOf(row.id) !== -1}
                                      handleSelectClick={(event) => handleSelectClick(event, row.id)}
                                    />
                                  )) || <TableSkeleton key={index} sx={{ height: denseHeight }} />
                              )) || <TableNoData isNotFound={isNotFound} />}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={pagination?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
