import { toast } from 'react-toastify';

import {
  GET_ASSETS_REQUEST,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_ERROR,
  GET_AUDIT_ASSETS_REQUEST,
  GET_AUDIT_ASSETS_SUCCESS,
  GET_AUDIT_ASSETS_ERROR,
  GET_ASSET_REQUEST,
  GET_ASSET_SUCCESS,
  GET_ASSET_ERROR,
  GET_ASSET_TRANSFERS_REQUEST,
  GET_ASSET_TRANSFERS_SUCCESS,
  GET_ASSET_TRANSFERS_ERROR,
  GET_ASSET_TRANSFER_ITEMS_REQUEST,
  GET_ASSET_TRANSFER_ITEMS_SUCCESS,
  GET_ASSET_TRANSFER_ITEMS_ERROR,
  TRANSFER_ASSET_REQUEST,
  TRANSFER_ASSET_SUCCESS,
  TRANSFER_ASSET_ERROR,
  CREATE_ASSET_REQUEST,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_ERROR,
  UPLOAD_ASSET_REQUEST,
  UPLOAD_ASSET_SUCCESS,
  UPLOAD_ASSET_ERROR,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_ERROR,
  UPDATE_TRANSFER_STATUS_REQUEST,
  UPDATE_TRANSFER_STATUS_SUCCESS,
  UPDATE_TRANSFER_STATUS_ERROR,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
} from './assetActionTypes';
import call from '../../../core/services/http';
import assetConstants from './assetConstants';

const toastrSuccess = (msg) => {
  toast.success(msg);
};
const toastrError = (msg) => {
  toast.error(msg);
};

export const getAssets = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSETS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', assetConstants.GET_ASSETS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_ASSETS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_ASSETS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSETS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSETS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getAuditAssets = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_AUDIT_ASSETS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', assetConstants.GET_AUDIT_ASSETS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_AUDIT_ASSETS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_AUDIT_ASSETS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_ASSETS_ERROR,
        isLoading: false,
        isSuccess: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_AUDIT_ASSETS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getAssetTransfers = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSET_TRANSFERS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', assetConstants.GET_ASSET_TRANSFERS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_ASSET_TRANSFERS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_ASSET_TRANSFERS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSET_TRANSFERS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSET_TRANSFERS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const filterAssets = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSETS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', assetConstants.FILTER_ASSETS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_ASSETS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_ASSETS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSETS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSETS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getAssetDetail = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSET_REQUEST,
      isLoading: true,
    });
    const response = await call('get', assetConstants.GET_ASSET(id));
    if (response.data.status) {
      dispatch({
        type: GET_ASSET_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_ASSET_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const getAssetTransferItems = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSET_TRANSFER_ITEMS_REQUEST,
      isLoading: true,
    });
    const response = await call('get', assetConstants.GET_ASSET_TRANSFER_ITEMS(payload));
    if (response.data.status) {
      dispatch({
        type: GET_ASSET_TRANSFER_ITEMS_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
    } else {
      toastrError(response.data.message);
      dispatch({
        type: GET_ASSET_TRANSFER_ITEMS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSET_TRANSFER_ITEMS_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: GET_ASSET_TRANSFER_ITEMS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const transferAsset = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: TRANSFER_ASSET_REQUEST,
      isLoading: true,
    });
    const response = await call('post', assetConstants.TRANSFER_ASSET, payload);
    if (response.data.status) {
      dispatch({
        type: TRANSFER_ASSET_SUCCESS,
        isLoading: false,
        payload: response.data,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: TRANSFER_ASSET_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: TRANSFER_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: TRANSFER_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const createAsset = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_ASSET_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('post', assetConstants.CREATE_ASSET, payload);
    if (response.data.status) {
      dispatch({
        type: CREATE_ASSET_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: CREATE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: CREATE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const uploadAssets = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPLOAD_ASSET_REQUEST,
      isLoading: true,
      isUploading: true,
    });
    const response = await call('post', assetConstants.UPLOAD_ASSET, payload);
    if (response.data.status) {
      dispatch({
        type: UPLOAD_ASSET_SUCCESS,
        isLoading: false,
        isUploading: false,
        payload: response.data,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPLOAD_ASSET_ERROR,
        isLoading: false,
        isUploading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPLOAD_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        isRegistered: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPLOAD_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const approveTransfer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_TRANSFER_STATUS_REQUEST,
      isLoading: true,
    });
    const response = await call('post', assetConstants.APPROVE_ASSET_TRANSFER(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_TRANSFER_STATUS_SUCCESS,
        isLoading: false,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const declineTransfer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_TRANSFER_STATUS_REQUEST,
      isLoading: true,
    });
    const response = await call('post', assetConstants.DECLINE_ASSET_TRANSFER(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_TRANSFER_STATUS_SUCCESS,
        isLoading: false,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};
export const acceptTransfer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_TRANSFER_STATUS_REQUEST,
      isLoading: true,
    });
    const response = await call('post', assetConstants.ACCEPT_ASSET_TRANSFER(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_TRANSFER_STATUS_SUCCESS,
        isLoading: false,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        // message: e.response,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const rejectTransfer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_TRANSFER_STATUS_REQUEST,
      isLoading: true,
    });
    const response = await call('post', assetConstants.REJECT_ASSET_TRANSFER(id));
    if (response.data.status) {
      dispatch({
        type: UPDATE_TRANSFER_STATUS_SUCCESS,
        isLoading: false,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        message: response.data.message,
      });
    }
  } catch (error) {
    console.log('ERROR:', error);
    if (error.response && error.response.status === 400) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
        message: errorMessage,
      });
      toastrError(errorMessage);
    } else if (error.response && error.response.status === 404) {
      // Handle 400 Bad Request error
      const errorMessage =
        error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Sorry, something went wrong';
      dispatch({
        type: UPDATE_TRANSFER_STATUS_ERROR,
        isLoading: false,
        isSuccess: false,
      });
      toastrError(errorMessage);
    } else {
      // Handle other errors (e.g., network issues, server errors)
      console.error('An error occurred:', error);
      toastrError('Sorry, something went wrong');
    }
  }
};

export const updateCustomer =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_ASSET_REQUEST,
        isLoading: true,
        isSuccess: false,
      });
      const response = await call('patch', assetConstants.UPDATE_ASSET(id), payload);
      if (response.data.status) {
        dispatch({
          type: UPDATE_ASSET_SUCCESS,
          isLoading: false,
          isSuccess: true,
          message: response.data.message,
        });
        toastrSuccess(response.data.message);
      } else {
        toastrError(response.data.message);
        dispatch({
          type: UPDATE_ASSET_ERROR,
          isLoading: false,
          isSuccess: false,
          message: response.data.message,
        });
      }
    } catch (e) {
      dispatch({
        type: UPDATE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
      });
      toastrError('Sorry, something went wrong');
    }
  };

export const deleteCustomer = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ASSET_REQUEST,
      isLoading: true,
      isSuccess: false,
    });
    const response = await call('delete', assetConstants.DELETE_ASSET(id));
    if (response.data.status) {
      dispatch({
        type: DELETE_ASSET_SUCCESS,
        isLoading: false,
        isSuccess: true,
      });
      toastrSuccess(response.data.message);
    } else {
      toastrError(response.data.message);
      dispatch({
        type: DELETE_ASSET_ERROR,
        isLoading: false,
        isSuccess: false,
        message: response.data.message,
      });
    }
  } catch (e) {
    dispatch({
      type: DELETE_ASSET_ERROR,
      isLoading: false,
      isSuccess: false,
    });
    toastrError('Sorry, something went wrong');
  }
};
