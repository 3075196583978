import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Stack, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button, Box } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { getAuditBranches } from '../../branches/store/branchActions';
import { updateStaff } from '../store/userActions';

const STATUS = [
  {
    id: 1,
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    id: 2,
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

const ROLES = [
  {
    id: 1,
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    id: 1,
    label: 'Assistant Manager',
    value: 'ASST_MANAGER',
  },
  {
    id: 2,
    label: 'Auditor',
    value: 'AUDITOR',
  },
  { id: 8, label: 'Report Viewer', value: 'REPORT_VIEWER' },
  {
    id: 5,
    label: 'Data Entry',
    value: 'DATA_ENTRY',
  },
  {
    id: 2,
    label: 'Manager',
    value: 'MANAGER',
  },
  {
    id: 3,
    label: 'Regional Manager',
    value: 'REGIONAL_MANAGER',
  },

  {
    id: 6,
    label: 'Service Provider',
    value: 'SERVICE_PROVIDER',
  },
];
EditUser.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  currentStaff: PropTypes.object,
};

export default function EditUser({ open, handleClose, currentStaff }) {
  const dispatch = useDispatch();
  const { auditBranches } = useSelector((state) => state.branchReducer);

  const EditUserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    idNumber: Yup.string().required('ID Number is required'),
    staffNumber: Yup.string().required('Staff Number is required'),
    department: Yup.string().required('Department is required'),
    branch: Yup.string().required('Branch is required'),
    role: Yup.string().required('Role Number is required'),
    accountStatus: Yup.string().required('Account status is required'),
  });
  const defaultValues = {
    firstName: currentStaff?.user?.first_name || '',
    lastName: currentStaff?.user?.last_name || '',
    email: currentStaff?.user?.email || '',
    phoneNumber: currentStaff?.user?.mobile_number || '',
    idNumber: currentStaff?.id_number || '',
    staffNumber: currentStaff?.staff_number || '',
    department: currentStaff?.department || '',
    branch: currentStaff?.branch?.id || '',
    role: currentStaff?.user?.role || '',
    accountStatus: currentStaff?.user?.status || '',
  };

  const methods = useForm({
    resolver: yupResolver(EditUserSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  useEffect(() => {
    dispatch(getAuditBranches({}));
  }, [dispatch]);

  const onSubmit = async (data) => {
    const requestData = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      id_number: data.idNumber,
      mobile_number: data.phoneNumber,
      staff_number: data.staffNumber,
      department: data.department,
      role: data.role,
      location_id: data.branch,
      account_status: data.accountStatus,
    };
    const payload = {
      id: currentStaff?.id,
      payload: requestData,
    };
    dispatch(updateStaff(payload));
    handleClose();
  };
  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Edit User</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="firstName" label="First Name" />
              <RHFTextField name="lastName" label="Last Name" />
              <RHFTextField type="email" name="email" label="Email Address" />
              <RHFTextField name="phoneNumber" label="Phone Number" />
              <RHFTextField name="idNumber" label="ID Number" />
              <RHFTextField name="staffNumber" label="Staff Number" />
              <RHFTextField name="department" label="Department" />
              <RHFSelect
                name="branch"
                label="Branch"
                placeholder="Branch"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {auditBranches?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFSelect
                fullWidth
                name="role"
                label="Role"
                placeholder="Role"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {ROLES.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFSelect
                fullWidth
                name="accountStatus"
                label="Status"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {STATUS?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
