import { sentenceCase } from 'change-case';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import Label from '../../../components/label';
import { fDateTime } from '../../../utils/formatTime';
import { getAssetTransfers } from '../../../packages/fixedassets/store/assetActions';
import { Accept, CustodianAccept, CustodianDecline, Decline } from '../../../packages/fixedassets/components';
import { fNumber } from '../../../utils/formatNumber';
import { getProfile } from '../../../shared/auth/authService';

// ----------------------------------------------------------------------
const storedUser = JSON.parse(localStorage.getItem('profile'));
const branchRoles = ['MANAGER', 'BRANCH_MANAGER'];

const isManager = storedUser?.role && branchRoles.includes(storedUser.role);
export default function AssetTransfersTableRow({ selected, record, handleSelectClick }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [openCustodian, setOpenCustodian] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openDeclineCustodian, setOpenDeclineCustodian] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      dispatch(getAssetTransfers({}));
    }, 2000); // 2000 mil
  };
  const handleCloseAcceptCustodian = () => {
    setOpenCustodian(!openCustodian);
    setTimeout(() => {
      dispatch(getAssetTransfers({}));
    }, 2000); // 2000 mil
  };
  const handleCloseDecline = () => {
    setOpenDecline(!openDecline);
    setTimeout(() => {
      dispatch(getAssetTransfers({}));
    }, 2000); // 2000 mil
  };
  const handleCloseDeclineCustodian = () => {
    setOpenDeclineCustodian(!openDeclineCustodian);
    setTimeout(() => {
      dispatch(getAssetTransfers({}));
    }, 2000); // 2000 mil
  };
  useEffect(() => {
    const profile = getProfile();
    if (profile) {
      setUser(profile);
    }
  }, []);
  return (
    <>
      {open && <Accept open={open} handleClose={handleClose} transferId={selectedRow?.id} />}
      {openDecline && <Decline open={openDecline} handleClose={handleCloseDecline} transferId={selectedRow?.id} />}
      {openCustodian && (
        <CustodianAccept open={openCustodian} handleClose={handleCloseAcceptCustodian} transferId={selectedRow?.id} />
      )}
      {openDeclineCustodian && (
        <CustodianDecline
          open={openDeclineCustodian}
          handleClose={handleCloseDeclineCustodian}
          transferId={selectedRow?.id}
        />
      )}
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox disableRipple checked={selected} onChange={handleSelectClick} />
        </TableCell>

        <TableCell align="left">{fDateTime(record?.transfered_on)}</TableCell>
        <TableCell align="left">{record?.checkedout_by?.branch?.name}</TableCell>
        <TableCell align="left">{record?.custodian?.branch?.name}</TableCell>
        <TableCell align="left">{record?.checkedout_by?.user?.display_name}</TableCell>
        <TableCell align="left">{record?.custodian?.user?.display_name}</TableCell>
        <TableCell align="left">{fNumber(record?.total_records)}</TableCell>
        <TableCell align="left">
          <Label color={(record?.admin_approved && 'success') || 'error'}>
            {(record?.admin_approved && 'Yes') || 'No'}
          </Label>
        </TableCell>
        <TableCell align="left">
          <Label color={(record?.custodian_accepted && 'success') || 'error'}>
            {(record?.custodian_accepted && 'Yes') || 'No'}
          </Label>
        </TableCell>
        <TableCell align="left">
          <Label
            color={
              (record?.status === 'PENDING' && 'warning') || (record?.status === 'REJECTED' && 'error') || 'success'
            }
          >
            {sentenceCase(record?.status)}
          </Label>
        </TableCell>
        <TableCell>
          <Stack spacing={2} direction="row">
            {user?.role === 'ADMIN' && record?.status === 'PENDING' && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setSelectedRow(record);
                    setOpen(true);
                  }}
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setSelectedRow(record);
                    setOpenDecline(true);
                  }}
                >
                  Reject
                </Button>
              </>
            )}
            {isManager && record?.custodian_status === 'PENDING' && record?.admin_approved && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setSelectedRow(record);
                    setOpenCustodian(true);
                  }}
                >
                  Accept
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setSelectedRow(record);
                    setOpenDeclineCustodian(true);
                  }}
                >
                  Reject
                </Button>
              </>
            )}
            <Button size="small" variant="contained" onClick={() => navigate(`/dashboard/transfers/${record?.id}`)}>
              View Assets
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}

AssetTransfersTableRow.propTypes = {
  record: PropTypes.object,
  handleSelectClick: PropTypes.func,
  selected: PropTypes.any,
};
