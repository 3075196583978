import { Box, TextField, Stack } from '@mui/material';
import { sentenceCase } from 'change-case';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { fDate } from '../../../utils/formatTime';
import { fCurrency } from '../../../utils/formatNumber';
import { getAssetDetail } from '../store/assetActions';

AssetDetailCard.propTypes = {
  recordId: PropTypes.string,
};

export default function AssetDetailCard({ recordId }) {
  const dispatch = useDispatch();
  const { asset } = useSelector((state) => state.assetReducer);

  useEffect(() => {
    dispatch(getAssetDetail(recordId));
  }, [dispatch, recordId]);

  return (
    <>
      {(asset && (
        <>
          <Stack spacing={1}>
            <Box
              sx={{
                display: 'grid',
                p: 3,
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <TextField name="asset" label="Name" value={asset?.name} disabled />
              <TextField name="branch" label="Branch" value={asset?.branch?.name} disabled />
              <TextField name="section" label="Section" value={asset?.section} disabled />
              <TextField name="category" label="Category" value={asset?.asset_class} disabled />
              <TextField name="serialNumber" label="Serial Number" value={asset?.serial_number} disabled />
              <TextField name="barcodeNumber" label="Barcode Number" value={asset?.barcode_number} disabled />
              <TextField name="jobCardNumber" label="Job Card Number" value={asset?.job_card_number} disabled />
              <TextField name="purchaseDate" label="Purchase Date" value={fDate(asset?.purchase_date)} disabled />
              <TextField name="purchaseCost" label="Purchase Cost" value={fCurrency(asset?.cost)} disabled />
              <TextField
                name="condition"
                label="Condition"
                value={asset?.condition && sentenceCase(asset?.condition)}
                disabled
              />
              <TextField name="status" label="Status" value={asset?.status && sentenceCase(asset?.status)} disabled />
              <TextField name="custodian" label="Custodian" value={asset?.assigned_to?.user?.display_name} disabled />
              <TextField name="description" label="Description" value={asset?.description} disabled />
              {asset?.has_warranty && (
                <TextField name="warranty" label="Warranty(Year)" value={asset?.warranty} disabled />
              )}
            </Box>
          </Stack>
        </>
      )) ||
        'Asset details will appear here'}
    </>
  );
}
