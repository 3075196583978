import {
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_ERROR,
  GET_AUDIT_SUB_CATEGORIES_REQUEST,
  GET_AUDIT_SUB_CATEGORIES_SUCCESS,
  GET_AUDIT_SUB_CATEGORIES_ERROR,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
} from './subCategoryActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  payload: {},
  message: '',
  subCategories: [],
  auditSubCategories: [],
  pagination: {},
};

export default function subCategoryReducer(state = initialState, action) {
  const { type, payload, isLoading, isSuccess, message } = action;

  switch (type) {
    case GET_SUB_CATEGORIES_REQUEST:
      return {
        isLoading,
      };
    case GET_SUB_CATEGORIES_SUCCESS:
      return {
        isLoading,
        categories: payload.data,
        pagination: payload,
      };
    case GET_SUB_CATEGORIES_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_AUDIT_SUB_CATEGORIES_REQUEST:
      return {
        isLoading,
      };
    case GET_AUDIT_SUB_CATEGORIES_SUCCESS:
      return {
        isLoading,
        auditSubCategories: payload.data,
      };
    case GET_AUDIT_SUB_CATEGORIES_ERROR:
      return {
        isLoading,
        message,
      };
    case CREATE_CATEGORY_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_CATEGORY_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_CATEGORY_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_CATEGORY_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_CATEGORY_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_CATEGORY_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
