// @mui
import { Container, Grid } from '@mui/material';

import { Page } from '../../../components';

import { AssetTabs } from '.';

export default function MaintenanceDetail() {
  return (
    <>
      <Page title="Maintenance Detail">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <AssetTabs />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
