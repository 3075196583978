import {
  GET_BRANCHES_REQUEST,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_ERROR,
  GET_AUDIT_BRANCHES_REQUEST,
  GET_AUDIT_BRANCHES_SUCCESS,
  GET_AUDIT_BRANCHES_ERROR,
  // CREATE_CATEGORY_REQUEST,
  // CREATE_CATEGORY_SUCCESS,
  // CREATE_CATEGORY_ERROR,
  // UPDATE_CATEGORY_REQUEST,
  // UPDATE_CATEGORY_SUCCESS,
  // UPDATE_CATEGORY_ERROR,
  // DELETE_CATEGORY_REQUEST,
  // DELETE_CATEGORY_SUCCESS,
  // DELETE_CATEGORY_ERROR,
} from './branchActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  payload: {},
  message: '',
  branches: [],
  auditBranches: [],
  pagination: {},
};

export default function branchReducer(state = initialState, action) {
  const { type, payload, isLoading, message } = action;

  switch (type) {
    case GET_BRANCHES_REQUEST:
      return {
        isLoading,
      };
    case GET_BRANCHES_SUCCESS:
      return {
        isLoading,
        branches: payload.data,
        pagination: payload,
      };
    case GET_BRANCHES_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_AUDIT_BRANCHES_REQUEST:
      return {
        isLoading,
      };
    case GET_AUDIT_BRANCHES_SUCCESS:
      return {
        isLoading,
        auditBranches: payload.data,
      };
    case GET_AUDIT_BRANCHES_ERROR:
      return {
        isLoading,
        message,
      };
    // case CREATE_CATEGORY_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case CREATE_CATEGORY_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case CREATE_CATEGORY_ERROR:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case UPDATE_CATEGORY_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case UPDATE_CATEGORY_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case UPDATE_CATEGORY_ERROR:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case DELETE_CATEGORY_REQUEST:
    //   return {
    //     isLoading,
    //     isSuccess,
    //   };
    // case DELETE_CATEGORY_SUCCESS:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    // case DELETE_CATEGORY_ERROR:
    //   return {
    //     isLoading,
    //     isSuccess,
    //     message,
    //   };
    default: {
      return state;
    }
  }
}
