import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Stack, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

import { getServiceProviders } from '../../users/store/userActions';
import { createDisposalRecord } from '../store/disposalActions';

NewDisposal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  assetId: PropTypes.string,
};

export default function NewDisposal({ open, handleClose, assetId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess } = useSelector((state) => state.disposalReducer);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);

  const NewDisposalSchema = Yup.object().shape({
    name: Yup.string(),
    mobileNumber: Yup.string(),
    email: Yup.string(),
    estimatedAmount: Yup.number(),
    description: Yup.string(),
  });

  const defaultValues = {
    assetId: assetId || '',
    name: '',
    mobileNumber: '',
    email: '',
    estimatedAmount: '',
    description: '',
    attachment: '',
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue('attachment', file);
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  };

  const methods = useForm({
    resolver: yupResolver(NewDisposalSchema),
    defaultValues,
  });

  const {
    reset,
    // watch,
    // control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  useEffect(() => {
    dispatch(getServiceProviders({}));
  }, [dispatch]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('asset_id', assetId);
    formData.append('customer_name', data.name);
    formData.append('mobile_number', data.mobileNumber);
    formData.append('email', data.email);
    formData.append('estimate_amount', data.estimatedAmount);
    formData.append('description', data.description);
    formData.append('attachment', data.attachment);
    try {
      dispatch(createDisposalRecord(formData));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      reset();
      handleClose();
      navigate('/dashboard/disposals');
    }
  }, [reset, isSuccess, navigate, handleClose]);
  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={() => handleClose(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>New Disposal</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            {/* <RHFSelect
              fullWidth
              name="serviceProviderId"
              label="Select Service Provider"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {serviceProviders?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.user.display_name}
                </MenuItem>
              ))}
            </RHFSelect> */}
            <RHFTextField name="name" label="Customer Names" />
            <RHFTextField name="mobileNumber" label="Customer Phone" />
            <RHFTextField name="email" label="Customer Email" placeholder="Optional" />
            <RHFTextField type="number" name="estimatedAmount" label="Estimated Amount" />
            <RHFTextField name="description" label="Description" placeholder="Optional" />
            <RHFTextField type="file" name="attachment" onChange={handleFileUpload} label="Attachment" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
