import { useState, useEffect } from 'react';
import * as Yup from 'yup';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  CardHeader,
  CardContent,
  Paper,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { getAuditBranches } from '../../branches/store/branchActions';
import { FormProvider, RHFSelect } from '../../../components/hook-form';
import { fDate, fDateYMD } from '../../../utils/formatTime';
import { getScannedAsset, reconcileAuditRecord } from '../store/auditActions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Description', alignRight: false },
  { id: 'asset_class', label: 'Category', alignRight: false },
  { id: 'barcode_number', label: 'Barcode#', alignRight: false },
  { id: 'location', label: 'Current Location', alignRight: false },
  { id: 'expected_location', label: 'Expected Location', alignRight: false },
  { id: 'audit_date', label: 'Audited On', alignRight: false },
  { id: 'assigned_to', label: 'AssignedTo', alignRight: false },
];
// ----------------------------------------------------------------------

export default function ReconcileAudit() {
  const toastrError = (msg) => {
    toast.error(msg);
  };
  const dispatch = useDispatch();
  const { auditBranches } = useSelector((state) => state.branchReducer);
  const { scannedAssets } = useSelector((state) => state.auditReducer);

  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const defaultValues = {
    branchId: '',
    startDate,
    endDate,
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const AuditReconcileSchema = Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
    branchId: Yup.number().required('Select branch'),
  });

  const methods = useForm({
    resolver: yupResolver(AuditReconcileSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    dispatch(getAuditBranches({}));
  }, [dispatch]);

  const onSubmit = async (data) => {
    if (data.branchId !== '') {
      const payload = {
        page: page + 1,
        rowsPerPage: 1000,
        startDate: fDateYMD(startDate),
        endDate: fDateYMD(endDate),
        branchId: data.branchId,
      };
      dispatch(getScannedAsset(payload));
    } else {
      toastrError('Select Branch');
    }
  };

  const handleReconciliation = () => {
    if (scannedAssets.length > 0) {
      const scannedAssetIds = scannedAssets.map((record) => record.id);
      const payload = {
        audit_records_ids: scannedAssetIds,
      };
      dispatch(reconcileAuditRecord(payload));
    }
  };

  return (
    <>
      <Page title="Assets">
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Card>
                  <CardHeader title={'Select Branch'} subheader={''} />
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box sx={{ p: 2 }}>
                        <RHFSelect name="branchId" label="Branch">
                          <option value="" />
                          {auditBranches?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </RHFSelect>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box sx={{ p: 2 }}>
                        <Stack spacing={2}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <DatePicker
                            sx={{ width: '100%' }}
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ p: 2 }}>
                    <Stack direction="row" sx={{ p: 2, float: 'right' }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Fetch Assets
                      </LoadingButton>
                    </Stack>
                  </Box>
                </Card>
              </FormProvider>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                    <Button color="warning" size="small" variant="contained" onClick={handleReconciliation}>
                      Reconcile
                    </Button>
                  </Stack>
                </Box>
                <CardHeader title={'Asset Reconciliation'} subheader={'Quickmart stock take'} />
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      <Table size="small">
                        <UserListHead headLabel={TABLE_HEAD} rowCount={scannedAssets?.length} />
                        <TableBody>
                          {scannedAssets?.length > 0 ? (
                            scannedAssets.map((record) => (
                              <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                <TableCell align="left">{record?.asset?.name}</TableCell>
                                <TableCell align="left">{record?.asset?.asset_class}</TableCell>
                                <TableCell align="left">{record?.asset?.barcode_number}</TableCell>
                                <TableCell align="left">{record?.audit_branch?.name}</TableCell>
                                <TableCell align="left">{record?.expected_branch?.name}</TableCell>
                                <TableCell align="left">{fDate(record?.audit_date)}</TableCell>
                                <TableCell align="left">{record?.assets?.assigned_to?.user?.display_name}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <>No scanned assets yet</>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={scannedAssets?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
