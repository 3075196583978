import { useState } from 'react';
import { capitalCase } from 'change-case';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
import { Tab, Box, Tabs, Card, Divider, Button, Stack, Typography, Paper } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
// components
import useTabs from '../../../hooks/useTabs';
import Iconify from '../../../components/iconify/Iconify';
import { AssetDetailCard, AssetMaintenance } from '.';
import { NewMaintenance } from '../../maintenance/components';
import { fCurrency } from '../../../utils/formatNumber';
import { AssetAudit } from '../../audit/components';
import { NewDisposal } from '../../disposals/components';

const storedUser = JSON.parse(localStorage.getItem('profile'));
const branchRoles = ['MANAGER', 'ASST_MANAGER'];

const isManager = storedUser?.role && branchRoles.includes(storedUser.role);

export default function AssetTabs() {
  const { depreciationData } = useSelector((state) => state.assetReducer);
  const { id } = useParams();
  const { currentTab, onChangeTab } = useTabs('details');
  const [openRepair, setOpenRepair] = useState(false);
  const [openDispose, setOpenDispose] = useState(false);

  const ACCOUNT_TABS = [
    {
      value: 'details',
      icon: <InfoOutlinedIcon />,
      component: <AssetDetailCard recordId={id} />,
    },
    {
      value: 'repair history',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: <AssetMaintenance assetId={id} />,
    },
    {
      value: 'audit history',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: <AssetAudit assetId={id} />,
    },
  ];

  const handleRepair = () => {
    setOpenRepair(!openRepair);
  };
  const handleDisposal = () => {
    setOpenDispose(!openDispose);
  };

  return (
    <>
      {openRepair && <NewMaintenance open={openRepair} handleClose={handleRepair} assetId={id} />}
      {openDispose && <NewDisposal open={openDispose} handleClose={handleDisposal} assetId={id} />}
      <Paper sx={{ py: 3 }} elevation="1">
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
          <Stack width={1} textAlign="center">
            <Typography variant="subtitle">DEPRECIATION(as of Today)</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              KES {fCurrency(depreciationData?.todays_depreciation)}
            </Typography>
          </Stack>

          <Stack width={1} textAlign="center">
            <Typography variant="subtitle">NETBOOK VALUE(as of Today)</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              KES {fCurrency(depreciationData?.todays_net_book_value)}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
      <Card sx={{ mt: 5 }}>
        <Box sx={{ p: 2 }}>
          <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
            {isManager && (
              <>
                <Button variant="contained" size="small" startIcon={<BuildCircleOutlinedIcon />} onClick={handleRepair}>
                  New Repair
                </Button>

                <Button variant="contained" size="small" startIcon={<SellOutlinedIcon />} onClick={handleDisposal}>
                  Dispose
                </Button>
              </>
            )}
            {!isManager && (
              <>
                <Button variant="contained" size="small" startIcon={<EditOutlinedIcon />}>
                  Edit
                </Button>
                <Button variant="contained" size="small" color="error" startIcon={<DeleteOutlineOutlinedIcon />}>
                  Delete
                </Button>
              </>
            )}
          </Stack>
        </Box>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              // disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              iconPosition="start"
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Divider />

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Card>
    </>
  );
}
