import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_AUDIT_USERS_REQUEST,
  GET_AUDIT_USERS_SUCCESS,
  GET_AUDIT_USERS_FAILURE,
  GET_SERVICE_PROVIDERS_REQUEST,
  GET_SERVICE_PROVIDERS_SUCCESS,
  GET_SERVICE_PROVIDERS_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  FILTER_USERS_REQUEST,
  FILTER_USERS_SUCCESS,
  FILTER_USERS_FAILURE,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAILURE,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
} from './userActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  payload: {},
  message: '',
  users: [],
  auditUsers: [],
  serviceProviders: [],
  pagination: {},
};

export default function userReducer(state = initialState, action) {
  const { type, payload, isLoading, isSuccess, message } = action;

  switch (type) {
    case GET_USERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_USERS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        users: payload.data,
        pagination: payload,
      };
    case GET_USERS_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case FILTER_USERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case FILTER_USERS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        users: payload.data,
        pagination: payload,
      };
    case FILTER_USERS_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_AUDIT_USERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_AUDIT_USERS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        auditUsers: payload.data,
      };
    case GET_AUDIT_USERS_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_SERVICE_PROVIDERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_SERVICE_PROVIDERS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        serviceProviders: payload.data,
      };
    case GET_SERVICE_PROVIDERS_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_USER_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_USER_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };

    case CREATE_USER_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_USERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_USERS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };

    case UPDATE_USERS_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_USERS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case DELETE_USERS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };

    case DELETE_USERS_FAILURE:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
