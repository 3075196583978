export const GET_SUPPLIERS_REQUEST = 'SUPPLIERS/GET_SUPPLIERS_REQUEST';
export const GET_SUPPLIERS_SUCCESS = 'SUPPLIERS/GET_SUPPLIERS_SUCCESS';
export const GET_SUPPLIERS_ERROR = 'SUPPLIERS/GET_SUPPLIERS_ERROR';
export const GET_AUDIT_SUPPLIERS_REQUEST = 'SUPPLIERS/GET_AUDIT_SUPPLIERS_REQUEST';
export const GET_AUDIT_SUPPLIERS_SUCCESS = 'SUPPLIERS/GET_AUDIT_SUPPLIERS_SUCCESS';
export const GET_AUDIT_SUPPLIERS_ERROR = 'SUPPLIERS/GET_AUDIT_SUPPLIERS_ERROR';
export const CREATE_CATEGORY_REQUEST = 'SUPPLIERS/CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'SUPPLIERS/CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'SUPPLIERS/CREATE_CATEGORY_ERROR';
export const UPDATE_CATEGORY_REQUEST = 'SUPPLIERS/UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'SUPPLIERS/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'SUPPLIERS/UPDATE_CATEGORY_ERROR';
export const DELETE_CATEGORY_REQUEST = 'SUPPLIERS/DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'SUPPLIERS/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'SUPPLIERS/DELETE_CATEGORY_ERROR';
