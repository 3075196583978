import { useRoutes } from 'react-router-dom';
import { AuthRoutes } from '../packages/auth/routes/authRoutes';
import { dashboardRoutes } from '../packages/dashboard/routes/dashboardRoutes';
import { maintenanceRoutes } from '../packages/maintenance/routes/maintenanceRoutes';

let systemRoutes = [];

systemRoutes = AuthRoutes;
systemRoutes.push(...dashboardRoutes);
systemRoutes.push(...maintenanceRoutes);
export default function Router() {
  const routes = useRoutes(systemRoutes);

  return routes;
}
