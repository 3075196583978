import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Stack, Dialog, DialogTitle, DialogContent, MenuItem, DialogActions, Button } from '@mui/material';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';

import { getAuditBranches } from '../../branches/store/branchActions';
import { filterUsers } from '../store/userActions';

const ROLES = [
  {
    id: 1,
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    id: 1,
    label: 'Assistant Manager',
    value: 'ASST_MANAGER',
  },
  {
    id: 2,
    label: 'Auditor',
    value: 'AUDITOR',
  },
  { id: 8, label: 'Director', value: 'DIRECTOR' },
  {
    id: 5,
    label: 'Data Entry',
    value: 'DATA_ENTRY',
  },
  {
    id: 2,
    label: 'Manager',
    value: 'MANAGER',
  },
  {
    id: 3,
    label: 'Regional Manager',
    value: 'REGIONAL_MANAGER',
  },

  {
    id: 6,
    label: 'Service Provider',
    value: 'SERVICE_PROVIDER',
  },
];

UsersFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function UsersFilter({ open, handleClose }) {
  const dispatch = useDispatch();
  const { auditBranches } = useSelector((state) => state.branchReducer);

  const filterSchema = Yup.object().shape({});

  const defaultValues = {
    locationId: '',
    role: '',
    name: '',
  };

  const methods = useForm({
    resolver: yupResolver(filterSchema),
    defaultValues,
  });

  const {
    // reset,
    // watch,
    // control,
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  const onSubmit = async (data) => {
    const payload = {
      locationId: data.locationId,
      role: data.role,
      name: data.name,
    };
    dispatch(filterUsers(payload));
    handleClose();
  };
  useEffect(() => {
    dispatch(getAuditBranches({}));
  }, [dispatch]);
  return (
    <Dialog
      maxWidth="md"
      open={open}
      fullWidth
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Filter Users</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Stack spacing={2}>
            <RHFSelect
              fullWidth
              name="locationId"
              label="Branch"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {auditBranches?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect
              fullWidth
              name="role"
              label="Role"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {ROLES?.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFTextField name="name" label="Name" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" size="small" variant="contained" loading={isSubmitting}>
            Filter
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
