import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  APPROVE_INVOICE_REQUEST,
  APPROVE_INVOICE_SUCCESS,
  APPROVE_INVOICE_ERROR,
  GET_MAINTENANCE_RECORD_REQUEST,
  GET_MAINTENANCE_RECORD_SUCCESS,
  GET_MAINTENANCE_RECORD_ERROR,
  GET_REPAIR_DETAILS_REQUEST,
  GET_REPAIR_DETAILS_SUCCESS,
  GET_REPAIR_DETAILS_ERROR,
  CREATE_REPAIR_RECORD_REQUEST,
  CREATE_REPAIR_RECORD_SUCCESS,
  CREATE_REPAIR_RECORD_ERROR,
  CREATE_MAINTENANCE_RECORD_REQUEST,
  CREATE_MAINTENANCE_RECORD_SUCCESS,
  CREATE_MAINTENANCE_RECORD_ERROR,
  UPLOAD_ASSET_REQUEST,
  UPLOAD_ASSET_SUCCESS,
  UPLOAD_ASSET_ERROR,
  UPDATE_MAINTENANCE_RECORD_REQUEST,
  UPDATE_MAINTENANCE_RECORD_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_ERROR,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
} from './paymentActionTypes';

const initialState = {
  isLoading: false,
  isUploading: false,
  isSuccess: false,
  payload: {},
  message: '',
  maintenanceRecords: [],
  invoices: [],
  maintenanceRecord: {},
  maintenanceRepairs: [],
  maintenanceStatistics: {},
  asset: {},
  depreciationData: {},
  pagination: {},
  itemsCount: 0,
};

export default function paymentReducer(state = initialState, action) {
  const { type, payload, isLoading, isUploading, isSuccess, message } = action;

  switch (type) {
    case GET_INVOICES_REQUEST:
      return {
        isLoading,
      };
    case GET_INVOICES_SUCCESS:
      return {
        isLoading,
        invoices: payload.data,
        // maintenanceStatistics: payload.statistics,
        pagination: payload,
      };
    case GET_INVOICES_ERROR:
      return {
        isLoading,
        message,
      };
    case APPROVE_INVOICE_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case APPROVE_INVOICE_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case APPROVE_INVOICE_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case GET_MAINTENANCE_RECORD_REQUEST:
      return {
        isLoading,
      };
    case GET_MAINTENANCE_RECORD_SUCCESS:
      return {
        isLoading,
        maintenanceRecord: payload.data,
      };
    case GET_MAINTENANCE_RECORD_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_REPAIR_DETAILS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case GET_REPAIR_DETAILS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        maintenanceRepairs: payload.data,
        itemsCount: payload.count,
      };
    case GET_REPAIR_DETAILS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_MAINTENANCE_RECORD_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_MAINTENANCE_RECORD_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_MAINTENANCE_RECORD_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_REPAIR_RECORD_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_REPAIR_RECORD_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_REPAIR_RECORD_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPLOAD_ASSET_REQUEST:
      return {
        isLoading,
        isUploading,
        isSuccess,
      };
    case UPLOAD_ASSET_SUCCESS:
      return {
        isLoading,
        isUploading,
        isSuccess,
        message,
      };
    case UPLOAD_ASSET_ERROR:
      return {
        isLoading,
        isUploading,
        message,
      };
    case UPDATE_MAINTENANCE_RECORD_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_MAINTENANCE_RECORD_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_MAINTENANCE_RECORD_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_ASSET_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case DELETE_ASSET_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_ASSET_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
