import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ChangePassWordSchema } from '../../../schemas';
import { userLogout } from '../../../shared/auth/authService';
import { changePassword } from '../store/authActions';

export default function AccountChangePassword() {
  const dispatch = useDispatch();
  const { isLoading, isSuccess } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const payload = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
    };
    dispatch(changePassword(payload));
  };

  useEffect(() => {
    if (isSuccess) {
      userLogout();
    }
  }, [isSuccess, navigate]);

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <RHFTextField name="oldPassword" type="password" label="Old Password" />

          <RHFTextField name="newPassword" type="password" label="New Password" />

          <RHFTextField name="confirmNewPassword" type="password" label="Confirm New Password" />

          <LoadingButton type="submit" variant="contained" size="small" disabled={isSubmitting} loading={isLoading}>
            Change Password
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
