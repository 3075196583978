import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  CardHeader,
  CardContent,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { getMaintenanceRecords } from '../store/maintenanceActions';
import { fDateTime } from '../../../utils/formatTime';
import { getProfile } from '../../../shared/auth/authService';
import { ConfirmCompleteDialog, SPAcceptance, SPDecline } from '../components';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'location', label: 'Branch', alignRight: false },
  { id: 'manager', label: 'Manager', alignRight: false },
  { id: 'repair_type', label: 'Repair Type', alignRight: false },
  { id: 'date_created', label: 'Created', alignRight: false },
  { id: 'condition', label: 'Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

export default function ServiceProviderMaintenance() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isUploading, maintenanceRecords, pagination } = useSelector((state) => state.maintenanceReducer);
  //

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [markComplete, setMarkComplete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getMaintenanceRecords(payload));
    }, 2000); // 2000 mil
  };
  const handleDecline = () => {
    setOpenDecline(!openDecline);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getMaintenanceRecords(payload));
    }, 2000); // 2000 mil
  };
  const handleComplete = () => {
    setMarkComplete(!markComplete);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getMaintenanceRecords(payload));
    }, 2000); // 2000 mil
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const payload = {
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getMaintenanceRecords(payload));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    const profile = getProfile();
    if (profile) {
      setUser(profile);
    }
  }, []);

  return (
    <>
      <Page title="Maintenance">
        {open && <SPAcceptance open={open} handleClose={handleClose} maintenanceId={selectedRow?.id} />}
        {openDecline && <SPDecline open={openDecline} handleClose={handleDecline} maintenanceId={selectedRow?.id} />}
        {markComplete && (
          <ConfirmCompleteDialog open={markComplete} handleClose={handleComplete} maintenanceId={selectedRow?.id} />
        )}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                {/* <Box sx={{ p: 2 }}>
                  <Stack direction="row" sx={{ float: 'right' }} spacing={1}>
                    <Button
                      size="small"
                      variant="contained"
                      component={RouterLink}
                      to={'/dashboard/maintenance/new'}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      New Repair
                    </Button>
                  </Stack>
                </Box> */}
                <CardHeader title={'Maintenance'} subheader={'Maintenance records'} />
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center' }}>
                          {isUploading && (
                            <Stack>
                              <Typography variant="h5">Uploading assets...</Typography>
                            </Stack>
                          )}
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#2065d1"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible
                          />
                        </Grid>
                      ) : (
                        <Table size="small">
                          <UserListHead headLabel={TABLE_HEAD} rowCount={maintenanceRecords?.length} />
                          <TableBody>
                            {maintenanceRecords?.length > 0 ? (
                              maintenanceRecords.map((record) => (
                                <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                  {/* <TableCell component="th" scope="row" padding="checkbox" sx={{ pl: 2 }}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                          <Avatar src={asset?.asset_photo} alt={asset?.name}/>
                                          <Typography variant="subtitle2" noWrap>
                                            {asset?.name}
                                          </Typography>
                                        </Stack>
                                      </TableCell> */}

                                  {/* <TableCell align="left">{asset?.pretag}</TableCell> */}
                                  <TableCell align="left">{record.asset?.name}</TableCell>
                                  <TableCell align="left">{record?.asset?.location?.name}</TableCell>
                                  <TableCell align="left">{record?.staff?.user?.display_name}</TableCell>
                                  <TableCell align="left">
                                    {record?.repair_type && sentenceCase(record?.repair_type)}
                                  </TableCell>
                                  <TableCell align="left">{fDateTime(record?.created_at)}</TableCell>

                                  <TableCell align="left">
                                    <Label
                                      color={
                                        (record?.status === 'PENDING' && 'warning') ||
                                        (record?.status === 'IN_PROGRESS' && 'warning') ||
                                        (record?.status === 'REJECTED' && 'error') ||
                                        'success'
                                      }
                                    >
                                      {sentenceCase(record?.status)}
                                    </Label>
                                  </TableCell>
                                  <TableCell>
                                    <Stack spacing={2} direction="row">
                                      {user?.role === 'SERVICE_PROVIDER' &&
                                        record?.rsp_approval_status === 'PENDING' &&
                                        record?.admin_approval_status === 'APPROVED' && (
                                          <>
                                            <Button
                                              size="small"
                                              variant="outlined"
                                              onClick={() => {
                                                setSelectedRow(record);
                                                setOpen(true);
                                              }}
                                            >
                                              Accept
                                            </Button>
                                            <Button
                                              size="small"
                                              color="error"
                                              variant="outlined"
                                              onClick={() => {
                                                setSelectedRow(record);
                                                setOpenDecline(true);
                                              }}
                                            >
                                              Reject
                                            </Button>
                                          </>
                                        )}
                                      {record?.can_be_returned && !record?.returned && (
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => {
                                            setSelectedRow(record);
                                            setMarkComplete(true);
                                          }}
                                        >
                                          Return
                                        </Button>
                                      )}
                                      <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => navigate(`/dashboard/maintenance/${record?.id}`)}
                                      >
                                        View
                                      </Button>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <>No maintenance records found</>
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={pagination?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
