import { combineReducers } from '@reduxjs/toolkit';
import assetReducer from '../../packages/fixedassets/store/assetReducer';
import userReducer from '../../packages/users/store/userReducer';
import branchReducer from '../../packages/branches/store/branchReducer';
import notificationReducer from '../../packages/notifications/store/notificationReducer';
import dashboardReducer from '../../packages/dashboard/store/dashboardReducer';
import authReducer from '../../packages/auth/store/authReducer';
import reminderReducer from '../../packages/reminders/store/reminderReducer';
import categoryReducer from '../../packages/categories/store/categoryReducer';
import subCategoryReducer from '../../packages/subcategories/store/subCategoryReducer';
import supplierReducer from '../../packages/suppliers/store/supplierReducer';
import auditReducer from '../../packages/audit/store/auditReducer';
import reportReducer from '../../packages/reports/store/reportReducer';
import maintenanceReducer from '../../packages/maintenance/store/maintenanceReducer';
import handoverReducer from '../../packages/handovers/store/handoverReducer';
import disposalReducer from '../../packages/disposals/store/disposalReducer';
import paymentReducer from '../../packages/payments/store/paymentReducer';
//
export default combineReducers({
  authReducer,
  dashboardReducer,
  assetReducer,
  userReducer,
  categoryReducer,
  subCategoryReducer,
  supplierReducer,
  branchReducer,
  notificationReducer,
  reminderReducer,
  auditReducer,
  reportReducer,
  maintenanceReducer,
  handoverReducer,
  disposalReducer,
  paymentReducer,
});
