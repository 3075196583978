import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
  CardHeader,
  CardContent,
  Paper,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead } from '../../../sections/@dashboard/user';

import { Page } from '../../../components';
import { getInvoices } from '../store/paymentActions';
import { fDateTime } from '../../../utils/formatTime';
import { getProfile } from '../../../shared/auth/authService';
import { Accept, Decline } from '../components';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'invoiceNumber', label: 'Invoice Number', alignRight: false },
  { id: 'jobCardNumber', label: 'Job Number', alignRight: false },
  { id: 'location', label: 'Branch', alignRight: false },
  { id: 'manager', label: 'Manager', alignRight: false },
  { id: 'service_provider', label: 'Service Provider', alignRight: false },
  { id: 'repair_type', label: 'Repair Type', alignRight: false },
  { id: 'date_created', label: 'Created', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'approval', label: 'Approval Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

export default function Invoices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, invoices, pagination } = useSelector((state) => state.paymentReducer);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getInvoices(payload));
    }, 2000); // 2000 mil
  };

  const handleCloseAccept = () => {
    setOpenAccept(!openAccept);
    setTimeout(() => {
      const payload = {
        page: page + 1,
        rowsPerPage,
      };
      dispatch(getInvoices(payload));
    }, 2000); // 2000 mil
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const payload = {
      page: page + 1,
      rowsPerPage,
    };
    dispatch(getInvoices(payload));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    const profile = getProfile();
    if (profile) {
      setUser(profile);
    }
  }, []);

  return (
    <>
      <Page title="Invoices">
        {openAccept && <Accept open={openAccept} handleClose={handleCloseAccept} invoiceId={selectedRow?.id} />}
        {open && <Decline open={open} handleClose={handleClose} invoiceId={selectedRow?.id} />}
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <CardHeader title={'Invoices'} subheader={'Repair Invoices'} />
                <CardContent>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }} component={Paper}>
                      {isLoading ? (
                        <Grid container sx={{ justifyContent: 'center' }}>
                          <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#2065d1"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible
                          />
                        </Grid>
                      ) : (
                        <Table size="small">
                          <UserListHead headLabel={TABLE_HEAD} rowCount={invoices?.length} />
                          <TableBody>
                            {invoices?.length > 0 ? (
                              invoices.map((record) => (
                                <TableRow hover key={record?.id} tabIndex={-1} role="checkbox">
                                  <TableCell align="left">{record?.job_card_number}</TableCell>
                                  <TableCell align="left">{record.job_card_number}</TableCell>
                                  <TableCell align="left">{record?.branch?.name}</TableCell>
                                  <TableCell align="left">{record?.manager?.display_name}</TableCell>
                                  <TableCell align="left">{record?.service_provider?.display_name}</TableCell>
                                  <TableCell align="left">
                                    {record?.maintenance_record?.repair_type &&
                                      sentenceCase(record?.maintenance_record?.repair_type)}
                                  </TableCell>
                                  <TableCell align="left">{fDateTime(record?.date_created)}</TableCell>

                                  <TableCell align="left">
                                    <Label
                                      color={
                                        (record?.status === 'UNPAID' && 'warning') ||
                                        (record?.status === 'OVERDUE' && 'error') ||
                                        'success'
                                      }
                                    >
                                      {sentenceCase(record?.status)}
                                    </Label>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Label
                                      color={
                                        (record?.approval_status === 'PENDING' && 'warning') ||
                                        (record?.approval_status === 'REJECTED' && 'error') ||
                                        'success'
                                      }
                                    >
                                      {sentenceCase(record?.approval_status)}
                                    </Label>
                                  </TableCell>
                                  <TableCell>
                                    <Stack spacing={2} direction="row">
                                      {user?.role === 'LOSE_CONTROL' && record?.approval_status === 'PENDING' && (
                                        <>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                              setSelectedRow(record);
                                              setOpenAccept(true);
                                            }}
                                          >
                                            Approve
                                          </Button>
                                          <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            onClick={() => {
                                              setSelectedRow(record);
                                              setOpen(true);
                                            }}
                                          >
                                            Reject
                                          </Button>
                                        </>
                                      )}

                                      {/* <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => navigate(`/maintenance/app/${record?.id}`)}
                                      >
                                        View
                                      </Button> */}
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <>No repair invoices found</>
                            )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={pagination?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
