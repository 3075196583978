import { useEffect } from 'react';
import { capitalCase } from 'change-case';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { Tab, Box, Tabs, Card, Divider } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// components
import useTabs from '../../../hooks/useTabs';
import Iconify from '../../../components/iconify/Iconify';
import { MaintenanceDetailCard, RepairAttachment, RepairDetails } from '.';
import { getMaintenanceRecord } from '../store/maintenanceActions';

export default function MaintenanceTabs() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentTab, onChangeTab } = useTabs('details');

  const ACCOUNT_TABS = [
    {
      value: 'details',
      icon: <InfoOutlinedIcon />,
      component: <MaintenanceDetailCard recordId={id} />,
    },
    {
      value: 'repairs',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: <RepairDetails recordId={id} />,
    },
    {
      value: 'attachments',
      icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
      component: <RepairAttachment recordId={id} />,
    },
  ];
  useEffect(() => {
    dispatch(getMaintenanceRecord(id));
  }, [dispatch, id]);
  return (
    <Card sx={{ mt: 5 }}>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {ACCOUNT_TABS.map((tab) => (
          <Tab
            // disableRipple
            key={tab.value}
            label={capitalCase(tab.value)}
            iconPosition="start"
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />

      <Box sx={{ mb: 5 }} />

      {ACCOUNT_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </Card>
  );
}
