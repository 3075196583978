import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Stack, TextField } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { getAuditBranches } from '../../branches/store/branchActions';
import { branchReportSchema } from '../schemas';
import { FormProvider, RHFSelect } from '../../../components/hook-form';
import reportConstants from '../store/reportConstants';
import { fDateYMD } from '../../../utils/formatTime';
import call from '../../../core/services/http';

const toastrError = (msg) => {
  toast.error(msg);
};

export default function BarcodeReportForm() {
  const dispatch = useDispatch();
  const { auditBranches } = useSelector((state) => state.branchReducer);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const handleStartDateChange = (newValue) => {
    setValue('startDate', fDateYMD(newValue));
    setSelectedStartDate(newValue);
  };
  const handleEndDateChange = (newValue) => {
    setValue('endDate', fDateYMD(newValue));
    setSelectedEndDate(newValue);
  };

  const generateBranchBarcodeReport = async (payload) => {
    try {
      setIsLoading(true);
      const response = await call('get', reportConstants.GENERATE_BARCODE_NUMBER_REPORTS(payload));
      if (response.status) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        // link.href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // eslint-disable-next-line no-useless-concat
        link.download = 'BranchBarcodeReport' + '.csv';
        link.click();

        // Clean up the temporary objects
        window.URL.revokeObjectURL(link);
        link.remove();
        setIsLoading(false);
      } else {
        toastrError(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('ERROR:', error);
      if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred while generating report';
        setIsLoading(false);
        toastrError(errorMessage);
      } else if (error.response && error.response.status === 404) {
        // Handle 400 Bad Request error
        const errorMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred while generating report';
        setIsLoading(false);
        toastrError(errorMessage);
      } else {
        // Handle other errors (e.g., network issues, server errors)
        console.error('An error occurred:', error);
        setIsLoading(false);
        toastrError('An error occurred while generating report');
      }
    }
  };

  const defaultValues = {
    locationId: '',
    startDate: '',
    endDate: '',
  };

  const methods = useForm({
    resolver: yupResolver(branchReportSchema),
    defaultValues,
  });

  const {
    // reset,
    setValue,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const payload = {
      // location_id: data.locationId,
      locationId: data.locationId,
      startDate: data.startDate,
      endDate: data.endDate,
    };
    generateBranchBarcodeReport(payload);
  };

  useEffect(() => {
    dispatch(getAuditBranches({}));
  }, [dispatch]);
  return (
    <Box
      sx={{
        padding: 3,
        marginTop: 3,
        borderRadius: 1,
      }}
    >
      <Stack spacing={3}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <RHFSelect
              fullWidth
              name="locationId"
              label="Branch"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            >
              {auditBranches?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </RHFSelect>
            <DatePicker
              label="Start Date"
              value={selectedStartDate}
              format="yyyy-MM-dd"
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={selectedEndDate}
              format="yyyy-MM-dd"
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>

          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
            <LoadingButton
              type="submit"
              variant="contained"
              startIcon={<FileDownloadOutlinedIcon />}
              disabled={isLoading}
              loading={isLoading}
            >
              Export
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>
    </Box>
  );
}
