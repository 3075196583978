import {
  GET_AUDIT_ASSETS_REQUEST,
  GET_AUDIT_ASSETS_SUCCESS,
  GET_AUDIT_ASSETS_ERROR,
  GET_ASSET_AUDITS_REQUEST,
  GET_ASSET_AUDITS_SUCCESS,
  GET_ASSET_AUDITS_ERROR,
  RECONCILE_AUDIT_REQUEST,
  RECONCILE_AUDIT_SUCCESS,
  RECONCILE_AUDIT_ERROR,
} from './auditActionTypes';

const initialState = {
  isLoading: false,
  isSuccess: false,
  payload: {},
  message: '',
  count: 0,
  scannedAssets: [],
};

export default function auditReducer(state = initialState, action) {
  const { type, payload, isLoading, message } = action;

  switch (type) {
    case GET_AUDIT_ASSETS_REQUEST:
      return {
        isLoading,
      };
    case GET_AUDIT_ASSETS_SUCCESS:
      return {
        isLoading,
        scannedAssets: payload.data,
        count: payload.data.count,
      };
    case GET_AUDIT_ASSETS_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_ASSET_AUDITS_REQUEST:
      return {
        isLoading,
      };
    case GET_ASSET_AUDITS_SUCCESS:
      return {
        isLoading,
        scannedAssets: payload.data,
        count: payload.data.count,
      };
    case GET_ASSET_AUDITS_ERROR:
      return {
        isLoading,
        message,
      };
    case RECONCILE_AUDIT_REQUEST:
      return {
        isLoading,
      };
    case RECONCILE_AUDIT_SUCCESS:
      return {
        isLoading,
        message,
      };
    case RECONCILE_AUDIT_ERROR:
      return {
        isLoading,
        message,
      };
    default: {
      return state;
    }
  }
}
