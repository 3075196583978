import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStaff } from '../store/userActions';

ConfirmCompleteDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  staffId: PropTypes.string,
};

export default function ConfirmCompleteDialog({ open, handleClose, staffId }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.userReducer);

  const handleReturn = async () => {
    dispatch(deleteStaff(staffId));
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure you want to delete this user?'}</DialogTitle>
        <DialogActions>
          <LoadingButton color="secondary" type="submit" variant="contained" onClick={handleClose}>
            Cancel
          </LoadingButton>
          <LoadingButton color="error" loading={isLoading} onClick={handleReturn} type="submit" variant="contained">
            Proceed
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
