import PropTypes from 'prop-types';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { invoiceApproval } from '../store/paymentActions';

Accept.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  invoiceId: PropTypes.string,
};

export default function Accept({ open, handleClose, invoiceId }) {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.maintenanceReducer);

  const handleApproveInvoice = async () => {
    const values = { approval_status: 'APPROVED', description: 'Ready for payment' };
    const payload = {
      id: invoiceId,
      payload: values,
    };
    dispatch(invoiceApproval(payload));
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'You are about to approve Repair Invoice.'}</DialogTitle>
      <DialogActions>
        <Button size="small" variant="contained" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          size="small"
          variant="contained"
          loading={isLoading}
          onClick={handleApproveInvoice}
        >
          Proceed
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
