import {
  GET_ASSETS_REQUEST,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_ERROR,
  GET_AUDIT_ASSETS_REQUEST,
  GET_AUDIT_ASSETS_SUCCESS,
  GET_AUDIT_ASSETS_ERROR,
  GET_ASSET_REQUEST,
  GET_ASSET_SUCCESS,
  GET_ASSET_ERROR,
  GET_ASSET_TRANSFERS_REQUEST,
  GET_ASSET_TRANSFERS_SUCCESS,
  GET_ASSET_TRANSFERS_ERROR,
  GET_ASSET_TRANSFER_ITEMS_REQUEST,
  GET_ASSET_TRANSFER_ITEMS_SUCCESS,
  GET_ASSET_TRANSFER_ITEMS_ERROR,
  TRANSFER_ASSET_REQUEST,
  TRANSFER_ASSET_SUCCESS,
  TRANSFER_ASSET_ERROR,
  CREATE_ASSET_REQUEST,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_ERROR,
  UPLOAD_ASSET_REQUEST,
  UPLOAD_ASSET_SUCCESS,
  UPLOAD_ASSET_ERROR,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_ERROR,
  UPDATE_TRANSFER_STATUS_REQUEST,
  UPDATE_TRANSFER_STATUS_SUCCESS,
  UPDATE_TRANSFER_STATUS_ERROR,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_ERROR,
} from './assetActionTypes';

const initialState = {
  isLoading: false,
  isUploading: false,
  isSuccess: false,
  payload: {},
  message: '',
  fixedAssets: [],
  auditFixedAssets: [],
  assetTransfers: [],
  assetTransferItems: [],
  asset: {},
  depreciationData: {},
  pagination: {},
};

export default function assetReducer(state = initialState, action) {
  const { type, payload, isLoading, isUploading, isSuccess, message } = action;

  switch (type) {
    case GET_ASSETS_REQUEST:
      return {
        isLoading,
      };
    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        isLoading,
        fixedAssets: payload.data,
        pagination: payload,
      };
    case GET_ASSETS_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_AUDIT_ASSETS_REQUEST:
      return {
        isLoading,
      };
    case GET_AUDIT_ASSETS_SUCCESS:
      return {
        isLoading,
        auditFixedAssets: payload.data,
        pagination: payload,
      };
    case GET_AUDIT_ASSETS_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_ASSET_TRANSFERS_REQUEST:
      return {
        isLoading,
      };
    case GET_ASSET_TRANSFERS_SUCCESS:
      return {
        isLoading,
        assetTransfers: payload.data,
        pagination: payload,
      };
    case GET_ASSET_TRANSFERS_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_ASSET_TRANSFER_ITEMS_REQUEST:
      return {
        isLoading,
      };
    case GET_ASSET_TRANSFER_ITEMS_SUCCESS:
      return {
        isLoading,
        assetTransferItems: payload.data,
        pagination: payload,
      };
    case GET_ASSET_TRANSFER_ITEMS_ERROR:
      return {
        isLoading,
        message,
      };
    case GET_ASSET_REQUEST:
      return {
        isLoading,
      };
    case GET_ASSET_SUCCESS:
      return {
        isLoading,
        asset: payload.data,
        depreciationData: payload.depreciation_data,
      };
    case GET_ASSET_ERROR:
      return {
        isLoading,
        message,
      };
    case TRANSFER_ASSET_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case TRANSFER_ASSET_SUCCESS:
      return {
        isLoading,
        isSuccess,
      };
    case TRANSFER_ASSET_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_ASSET_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case CREATE_ASSET_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case CREATE_ASSET_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPLOAD_ASSET_REQUEST:
      return {
        isLoading,
        isUploading,
        isSuccess,
      };
    case UPLOAD_ASSET_SUCCESS:
      return {
        isLoading,
        isUploading,
        isSuccess,
        message,
      };
    case UPLOAD_ASSET_ERROR:
      return {
        isLoading,
        isUploading,
        message,
      };
    case UPDATE_ASSET_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_ASSET_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_ASSET_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_TRANSFER_STATUS_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case UPDATE_TRANSFER_STATUS_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case UPDATE_TRANSFER_STATUS_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_ASSET_REQUEST:
      return {
        isLoading,
        isSuccess,
      };
    case DELETE_ASSET_SUCCESS:
      return {
        isLoading,
        isSuccess,
        message,
      };
    case DELETE_ASSET_ERROR:
      return {
        isLoading,
        isSuccess,
        message,
      };
    default: {
      return state;
    }
  }
}
